import React, { useEffect, useRef } from "react";

import { useAudioVideo, useContentShareState, useRemoteVideoTileState, useRosterState } from "amazon-chime-sdk-component-library-react";

import { MeetingJoinType } from "../../types";


interface SelectedVideoTileProps {
    userName: string;
    type: MeetingJoinType;
    userId: string;
    showVideo: boolean;
    onCanShowVideo?: (canShow: boolean) => void;
}

const SelectedVideoTile: React.FC<SelectedVideoTileProps> = ({
    userName,
    type,
    userId,
    showVideo,
    onCanShowVideo,
}) => {

    const subType = `${type}`.slice(0, 3);
    const selectedUserName = `${userName}#${subType}`
    const selectedUser = `${userId}#${selectedUserName}`;

    const [attendeeId, setAttendeeId] = React.useState<number>(-1);

    const { attendeeIdToTileId } = useRemoteVideoTileState();

    const { roster } = useRosterState();
    const { isLocalUserSharing } = useContentShareState();

    const show = showVideo && !isLocalUserSharing;
    const validAttendeeId = attendeeId > -1;

    useEffect(() => {
        for (const attendee in roster) {

            const currentName = roster[attendee]?.externalUserId;

            if (!currentName) {
                continue;
            }

            if (currentName === selectedUser) {
                setAttendeeId(attendeeIdToTileId[attendee]);

                if (onCanShowVideo) {
                    onCanShowVideo(true);
                }

                return;
            }
        }

        if (onCanShowVideo) {
            onCanShowVideo(false);
        }
        setAttendeeId(-1);

    }, [roster, attendeeIdToTileId, selectedUser]);

    const audioVideo = useAudioVideo();
    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!audioVideo || attendeeId < 0 || !videoEl.current) {
            return;
        }

        audioVideo.bindVideoElement(attendeeId, videoEl.current);

        return () => {
            const tile = audioVideo.getVideoTile(attendeeId);
            if (tile) {
                audioVideo.unbindVideoElement(attendeeId);
            }
        };
    }, [audioVideo, attendeeId]);

    return (
        <>
            {validAttendeeId &&
                <video
                    ref={videoEl}
                    style={{
                        display: show ? "flex" : "none",
                        width: "20vw",
                        maxHeight: "30vh",
                        height: "auto",
                        zIndex: 100,
                    }}
                />}
        </>
    );
}

export default SelectedVideoTile;