import React from "react"

import { FormControl, InputLabel, MenuItem, Select, Stack, SxProps, Theme, Typography } from "@mui/material"

interface DictionarySelectProps {
    name: string
    label: string
    multiple?: boolean
    showLabel?: boolean
    showNames?: boolean
    minWidth?: number,
    selectSX?: SxProps<Theme>,
    menuProps?: any,
    dictionary: { [key: string]: string }
    selected: any
    onSelect: (event: any) => void
    createSelectIcon?: (key: string, value: string) => JSX.Element
}

const DictionarySelect: React.FC<DictionarySelectProps> = ({
    name,
    label,
    multiple,
    showLabel = true,
    showNames = true,
    dictionary,
    selected,
    selectSX,
    menuProps,
    minWidth = 160,
    onSelect,
    createSelectIcon,
}) => {

    return (
        <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"left"}
            alignItems={"center"}
            width={"100%"}>
            <FormControl
                fullWidth={showLabel}
                aria-labelledby={!showLabel ? undefined : `${name}-select-label`}>
                {showLabel && <InputLabel
                    id={`${name}-select-label`}
                    color="primary">
                    {label}
                </InputLabel>}
                <Select
                    fullWidth={showLabel}
                    multiple={multiple}
                    name={name}
                    label={!showLabel ? undefined : name}
                    id={`${name}-select`}
                    aria-labelledby={`${name}-select-label`}
                    labelId={`${name}-select-label`}
                    value={selected}
                    onChange={onSelect}
                    MenuProps={{
                        ...menuProps,
                    }}
                    sx={{
                        minWidth: showNames ? `${minWidth}px` : undefined,
                        height: "50px",
                        ...selectSX,
                    }}>
                    {Object.entries(dictionary).map(([key, value]) => {
                        return (
                            <MenuItem key={key} value={key}>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    paddingX={showNames ? 1 : 0}
                                    spacing={showNames ? 2 : 0}>
                                    {createSelectIcon && createSelectIcon(key, value)}
                                    {showNames && <Typography>
                                        {value}
                                    </Typography>}
                                </Stack >
                            </MenuItem >
                        )
                    })}
                </Select >
            </FormControl>
        </Stack >
    )
}

export default DictionarySelect;