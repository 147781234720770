import { SelectChangeEvent } from "@mui/material"

import { Organisation } from "../../types"

import DictionarySelect from "../dictionary-select"

interface SingleOrganisationSelectProps {
    name: string
    label: string
    selected: string
    organisationList: Organisation[]
    onSelect: (selected: string) => void
}

const SingleOrganisationSelect: React.FC<SingleOrganisationSelectProps> = ({
    name,
    label,
    selected,
    organisationList,
    onSelect,
}) => {
    const organisationDictionary = organisationList.reduce((acc, org) => {
        acc[org.organisationId] = org.name
        return acc
    }, {} as { [key: string]: string })

    const handleSelect = (event: SelectChangeEvent<string>) => {
        onSelect(event.target.value)
    }

    return (
        <DictionarySelect
            name={name}
            label={label}
            dictionary={organisationDictionary}
            selected={selected}
            onSelect={handleSelect} />
    )
}

export default SingleOrganisationSelect;