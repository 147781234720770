import React from "react"

import { Stack, Typography } from "@mui/material"

import { Meeting, User } from "../../types"
import { MeetingJoinType } from "../../types"

import JoinMeetingButton from "./join-meeting-button"

interface DialogueJoinMeetingButtonProps {
    canJoinNow: boolean,
    meeting: Meeting,
    currentUser?: User | null,
    canJoinMessage: string,
    buttonType: MeetingJoinType,
}

const DialogueJoinMeetingButton: React.FC<DialogueJoinMeetingButtonProps> = ({
    canJoinNow,
    meeting,
    currentUser,
    canJoinMessage,
    buttonType
}) => {

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}>
            <Typography variant={"h5"}>
                {canJoinMessage}
            </Typography>
            {canJoinNow && currentUser &&
                <JoinMeetingButton
                    meeting={meeting}
                    joinedUsers={meeting.confirmedAttendeesId}
                    userId={currentUser?.userId || ""}
                    meetingType={buttonType} />}
        </Stack>
    )
}

export default DialogueJoinMeetingButton