import React from "react";

import { Stack, SxProps, Theme, useTheme } from "@mui/material";

import toast from "react-hot-toast";

import { Meeting } from "../../types";
import { useDeleteMeeting } from "../../store";

import TelehealthStyledButton from "../telehealth-styled-button";
import ConfirmationModal from "../confirmation-modal";

interface CancelMeetingButtonProps {
    meeting: Meeting,
    canEdit: boolean
    sx?: SxProps<Theme>;
    tooltipStackSx?: SxProps<Theme>;
    coloredBackground?: boolean
}

const CancelMeetingButton: React.FC<CancelMeetingButtonProps> = ({
    meeting,
    canEdit,
    sx,
    tooltipStackSx,
    coloredBackground = false
}: CancelMeetingButtonProps) => {

    const theme = useTheme()

    const successToast = () => {
        toast.success("Meeting cancelled successfully")
    }

    const failureToast = () => {
        toast.error("Meeting cancellation failed")
    }

    const { mutate: cancelMeeting } = useDeleteMeeting(successToast, failureToast)

    const [confirmationOpen, setConfirmationOpen] = React.useState(false)

    const onDeleteButtonClick = () => {
        setConfirmationOpen(true)
    }

    const cancelDelete = () => {
        setConfirmationOpen(false)
    }

    const deleteMeeting = () => {
        cancelMeeting(meeting)
        setConfirmationOpen(false)
    }

    return (
        <>
            <TelehealthStyledButton
                variant={"outlined"}
                color={"error"}
                showText={true}
                disabled={!canEdit}
                onClick={onDeleteButtonClick}
                label={"Cancel Meeting"}
                sx={{
                    ...sx,
                    borderRadius: coloredBackground ? "0.5rem" : undefined,
                }}
                tooltipStackSx={{
                    ...tooltipStackSx,
                    backgroundColor: coloredBackground ? theme.palette.grey[200] : undefined,
                    borderRadius: coloredBackground ? "0.5rem" : undefined,
                }}
            />
            <ConfirmationModal
                open={confirmationOpen}
                onClose={cancelDelete}
                accept={deleteMeeting}
                title={"Cancel Meeting"}
                messages={["Are you sure you want to cancel this meeting?"]}
            />
        </>
    )
}

export default CancelMeetingButton;