import React from "react";

import { Stack } from "@mui/material";

import SaveIcon from '@mui/icons-material/Save';

import toast from "react-hot-toast";
import { useFormik } from "formik";

import { Meeting } from "../../types";
import { useUpdateAttendees } from "../../store";

import SelectOrAddAttendee from "./select-or-add-attendee";
import TelehealthIconButton from "../telehealth-icon-button";

interface EditAttendeesProps {
    meeting: Meeting,
    organisationIds: string[],
    onFinish: () => void,
}

const EditAttendees: React.FC<EditAttendeesProps> = ({
    meeting,
    organisationIds,
    onFinish,
}) => {

    const onSuccess = () => {
        toast.success("Attendees updated")
        setWaiting(false)
        onFinish()
    }

    const onError = () => {
        toast.error("Failed to update attendees")
        setWaiting(false)
        onFinish()
    }

    const [waiting, setWaiting] = React.useState<boolean>(false)

    const { mutate: updateAttendees } = useUpdateAttendees(onSuccess, onError)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            attendeesId: meeting?.attendeesId || [],
        },
        validate: values => {
            const errors: Partial<Record<keyof typeof values, string>> = {};

            return errors;
        },
        onSubmit: values => {

            setWaiting(true)
            updateAttendees({
                meetingId: meeting.meetingId,
                attendeesId: values.attendeesId,
            })
        },
    });


    const onSubmit = () => {
        formik.handleSubmit()
    }

    const updateAttendeesList = async (attendees: string[]) => {
        await formik.setFieldValue("attendeesId", attendees)
        formik.setTouched({ attendeesId: true })
    }

    return (
        <Stack
            spacing={2}
            direction={"row"}
            justifyItems={"center"}
            alignItems={"center"}
            width={"100%"}>
            <SelectOrAddAttendee
                requiredIds={meeting.confirmedAttendeesId}
                disabled={waiting}
                organisationIds={organisationIds}
                selected={formik.values.attendeesId}
                onSelect={updateAttendeesList} />
            <TelehealthIconButton
                icon={<SaveIcon />}
                onClick={onSubmit}
                disabled={waiting || !formik.isValid || !formik.dirty}
                tooltip="Save Changes" />
        </Stack >
    )
}

export default EditAttendees;