import React, { useState } from "react";

import { Stack } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CancelIcon from '@mui/icons-material/Cancel';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { MeetingJoinType } from "../../types";

import ControlButton from "../control-button";
import SelectedVideoTile from "./selected-video-tile";
import SendMessageButton from "./send-message-button";
import { MeetingInfoContext } from "../../context/meeting-info-context";

interface RemoteHeadsetVideoProps {
    type: MeetingJoinType;
    showMicrophone?: boolean;
    showAudio?: boolean;
    showCamera?: boolean;
    showUpload?: boolean;
}

const RemoteHeadsetVideo: React.FC<RemoteHeadsetVideoProps> = ({
    type,
    showMicrophone = true,
    showAudio = true,
    showCamera = true,
    showUpload = true
}) => {

    const { userName, userId } = React.useContext(MeetingInfoContext);

    const [showVideo, setShowVideo] = React.useState<boolean>(true);
    const [canShowVideo, setCanShowVideo] = React.useState<boolean>(false);

    const [remoteVideo, setRemoteVideo] = React.useState<boolean>(true);
    const [remoteAudio, setRemoteAudio] = React.useState<boolean>(true);
    const [remoteMic, setRemoteMic] = React.useState<boolean>(true);
    const [canTakePicture, setCanTakePicture] = React.useState<boolean>(true);

    const toggleShowSelf = () => {
        setShowVideo(!showVideo);
    }

    const onCanShowVideo = (canShow: boolean) => {
        setCanShowVideo(canShow);
        console.log("~~~ Can show video: ", canShow);
    }

    return (
        <div
            style={{
                position: "relative",
                width: "fit-content",
                height: "fit-content",
            }}>
            <div
                style={{
                    display: canShowVideo ? "block" : "none",
                    position: "absolute",
                    bottom: "1rem",
                    left: "1rem",
                    width: "20vw",
                    maxHeight: "30vh",
                    height: "auto",
                    zIndex: 100,
                }}>
                <div>
                    <SelectedVideoTile
                        userName={userName}
                        type={type}
                        userId={userId}
                        showVideo={showVideo}
                        onCanShowVideo={onCanShowVideo} />
                    <div style={{
                        position: "absolute",
                        bottom: "0rem",
                        left: showUpload ? "-0.9rem" : "0rem",
                        zIndex: 100,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Stack
                            width={"20vw"}
                            direction={"row"}
                            spacing={1}
                            padding={1}>
                            <ControlButton
                                onClick={toggleShowSelf}
                                disabled={!canShowVideo}
                                activeIcon={<VisibilityIcon color='action' />}
                                inactiveIcon={<VisibilityOffIcon color='action' />}
                                showText={false}
                                active={showVideo && canShowVideo}
                                activeLabel={"Hide RealWare Video"}
                                inactiveLabel={"Show RealWare Video"} />
                            {showMicrophone &&
                                <SendMessageButton
                                    topic="microphone"
                                    id="remote-mic-button"
                                    isMaster={false}
                                    disabled={!canShowVideo}
                                    activeIcon={<MicIcon color='action' />}
                                    inactiveIcon={<MicOffIcon color='action' />}
                                    active={remoteMic && canShowVideo}
                                    onClick={setRemoteMic}
                                    activeLabel={"Disable RealWare Microphone"}
                                    inactiveLabel={"Enable RealWare Microphone"}
                                    showText={false} />}
                            {showAudio &&
                                < SendMessageButton
                                    topic="audio"
                                    id="remote-audio-button"
                                    isMaster={false}
                                    disabled={!canShowVideo}
                                    activeIcon={<VolumeUpIcon color='action' />}
                                    inactiveIcon={<VolumeOffIcon color='action' />}
                                    active={remoteAudio && canShowVideo}
                                    onClick={setRemoteAudio}
                                    activeLabel={"Disable RealWare Speakers"}
                                    inactiveLabel={"Enable RealWare Speakers"}
                                    showText={false} />}
                            {showCamera &&
                                <SendMessageButton
                                    topic="camera"
                                    id="remote-camera-button"
                                    isMaster={false}
                                    disabled={!canShowVideo}
                                    activeIcon={<VideocamIcon color='action' />}
                                    inactiveIcon={<VideocamOffIcon color='action' />}
                                    active={remoteVideo && canShowVideo}
                                    onClick={setRemoteVideo}
                                    activeLabel={"Disable RealWare Camera"}
                                    inactiveLabel={"Enable RealWare Camera"}
                                    showText={false} />}
                            {showUpload &&
                                <SendMessageButton
                                    topic="upload-image"
                                    id="remote-upload-image-button"
                                    isMaster={false}
                                    disabled={!canShowVideo || !canTakePicture}
                                    activeIcon={<UploadFileIcon color='action' />}
                                    inactiveIcon={<UploadFileIcon color='action' />}
                                    active={canTakePicture && canShowVideo}
                                    onClick={setCanTakePicture}
                                    activeLabel={"Open Camera to Upload Image"}
                                    inactiveLabel={"Cannot Upload Image"}
                                    showText={false} />}
                            <SendMessageButton
                                topic="leaveCall"
                                id="leave-call-button"
                                isMaster={false}
                                disabled={false}
                                active={true}
                                activeIcon={<CancelIcon color="action" />}
                                activeLabel={"Disconnect RealWare from call."}
                                inactiveLabel={"Disconnect RealWare from call"}
                                activeColor="#c62828"
                                onClick={() => { console.log("~~~ Force Disconnect RealWare") }}
                                showText={false} />
                        </Stack>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default RemoteHeadsetVideo;