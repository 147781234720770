import React, { useEffect } from "react";

import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useSearchParams } from "react-router-dom";

import { MeetingInfoProvider } from "../context/meeting-info-context";

import MobileUpload from "./mobile-upload";
import DeviceMeeting from "./device-meeting";
import MeetingStatusDisplay from "../components/meeting/meeting-status-display";
import MobileMeeting from "./mobile-meeting";
import NormalMeetingDrawer from "../components/meeting/normal-meeting-drawer";
import RemoteUserMeeting from "../components/meeting/remote-user-meeting";
import { Meeting, MeetingJoinType } from "../types";
import { useJoinMeeting } from "../store";
import TrainingUserMeeting from "../components/meeting/training-user-meeting";
import ChimeMessageProvider from "../context/chime-message-context";
import BackupMeetingNotification from "../components/upload/backup-meeting-notification";

interface MeetingPageProps {
  meetingType: MeetingJoinType;
}

const MeetingPage: React.FC<MeetingPageProps> = ({
  meetingType = MeetingJoinType.Metro,
}: MeetingPageProps) => {

  const [searchParams] = useSearchParams();
  const meetingId = searchParams.get("meetingId") || "";
  const userId = searchParams.get("userId") || "";
  const ignoreSize: Boolean = searchParams.get("ignoreSize") === "true" || false;

  const isMobile = navigator.userAgent.includes("Mobile");

  const checkPage = () => {

    if (ignoreSize || !isMobile) {
      return meetingType;
    }

    if (meetingType !== MeetingJoinType.Phone && meetingType !== MeetingJoinType.Headset) {
      return meetingType;
    }

    //exact dimensions of RealWare screen
    let widthMatches = Math.abs(window.screen.width - 853) < 10;
    widthMatches = widthMatches || (Math.abs(window.screen.width - 1003) < 10);

    let heightMatches = Math.abs(window.screen.height - 480) < 10;
    heightMatches = heightMatches || (Math.abs(window.screen.height - 564) < 10);

    const isRealWareDevice = widthMatches && heightMatches;

    if (meetingType === MeetingJoinType.Phone && isRealWareDevice) {
      return MeetingJoinType.Headset;
    }

    if (meetingType === MeetingJoinType.Headset && !isRealWareDevice) {
      return MeetingJoinType.Phone;
    }

    return meetingType;
  }

  meetingType = checkPage();

  const [userName, setUserName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [patientId, setPatientId] = React.useState("");
  const [meeting, setMeeting] = React.useState<Meeting | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const meetingManager = useMeetingManager();

  const onJoinSuccess = (data: any) => {

    if (data?.siteMeeting) {
      setMeeting(data?.siteMeeting);
    }

    if (data?.fullName) {
      setUserName(data?.fullName);
    }

    if (data?.jobTitle) {
      setJobTitle(data?.jobTitle);
    }

    if (data?.patientId) {
      setPatientId(data?.patientId);
    }

    console.log("~~~Join success");
  }

  const onJoinError = (error: any) => {
    console.log("Join error", error);
    setErrorMessage(error.message);
  }

  const { mutate: joinMeeting } = useJoinMeeting(meetingManager, onJoinSuccess, onJoinError);

  useEffect(() => {
    if (errorMessage != null) {
      console.log("~~~ Error message", errorMessage);
      return;
    }

    if (!meetingId || !userId) {
      setErrorMessage("Missing meeting or user id");
      console.log("~~~ Missing meeting or user id");
      return;
    }

    const values = {
      meetingId,
      userId,
      meetingType,
    }

    joinMeeting(values);
  }, [meetingId, userId]);

  return (
    <MeetingInfoProvider
      meetingId={meetingId}
      userId={userId}
      userName={userName}
      jobTitle={jobTitle}
      patientId={patientId}
      meetingType={meetingType}
      meeting={meeting}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}>
      <ChimeMessageProvider>
        <MeetingStatusDisplay />
        <BackupMeetingNotification />
        {meetingType === MeetingJoinType.Headset ? <DeviceMeeting /> : null}
        {meetingType === MeetingJoinType.Phone ? <MobileUpload /> : null}
        {meetingType === MeetingJoinType.Metro ? <NormalMeetingDrawer /> : null}
        {meetingType === MeetingJoinType.Mobile ? <MobileMeeting /> : null}
        {meetingType === MeetingJoinType.Remote ? <RemoteUserMeeting /> : null}
        {meetingType === MeetingJoinType.Training ? <TrainingUserMeeting /> : null}
      </ChimeMessageProvider>
    </MeetingInfoProvider>
  );
};

export default MeetingPage;
