import { Site } from "../types";

const uploadURL = import.meta.env.VITE_API_URL as string + "/site";

const listSites = async (
    authToken: string
): Promise<Site[]> => {
    try {
        const response = await fetch(uploadURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const sites: Site[] = data.map(
            (site: any) => {
                const currentSite: Site = {
                    siteId: site.siteId,
                    siteName: site.siteName,
                    siteType: site.siteType,
                    color: site.color,
                    linkedOrganisations: site?.linkedOrganisations || [],
                };

                return currentSite;
            });

        return sites;
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getSite = async (
    authToken: string,
    siteId: string
): Promise<Site | null> => {

    if (siteId.length == 0) {
        return null;
    }

    let response = null;
    try {
        response = await fetch(`${uploadURL}/${siteId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

    } catch (error: any) {
        console.log(error);
        return null;
    }

    if (response.status === 404) {
        console.warn("Site not found");
        return null;
    }

    if (!response.ok) {
        console.error("Network response was not ok", response.status);
        return null;
    }

    const data = await response.json();

    if (!data) {
        return null;
    }

    const site: Site = {
        siteId: data.siteId,
        siteName: data.siteName,
        siteType: data.siteType,
        color: data.color,
        linkedOrganisations: data?.linkedOrganisations || [],
    }

    return site;
}

const addSite = async (
    authToken: string,
    site: Site
): Promise<string | Error> => {
    try {
        const response = await fetch(uploadURL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(site),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const updateSite = async (
    authToken: string,
    site: Site
): Promise<string | Error> => {
    try {
        const response = await fetch(`${uploadURL}/${site.siteId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(site),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const deleteSite = async (
    authToken: string,
    site: Site
): Promise<string | Error> => {
    try {
        const response = await fetch(`${uploadURL}/${site.siteId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getSiteByIds = async (
    authToken: string,
    siteIds: string[]
): Promise<(Site | null)[]> => {

    const sites: (Site | null)[] = [];
    for (let i = 0; i < siteIds.length; i++) {
        const user = await getSite(authToken, siteIds[i]);
        sites.push(user);
    }

    return sites;
}

const SiteAPIService = {
    listSites,
    getSite,
    addSite,
    updateSite,
    deleteSite,
    getSiteByIds,
};

export default SiteAPIService;