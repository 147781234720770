import React, { createContext, useState } from 'react';
import { Meeting, MeetingJoinType } from '../types';

export type MeetingInfoContextProps = {
    meetingId: string;
    userId: string;
    patientId: string;
    userName: string;
    jobTitle: string;
    meetingType: MeetingJoinType;
    meeting: Meeting | null;
    errorMessage: string | null;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
    connected: boolean;
    setConnected: React.Dispatch<React.SetStateAction<boolean>>;
    showSelf: boolean;
    setShowSelf: React.Dispatch<React.SetStateAction<boolean>>;
    otherAttendees: string[];
    setOtherAttendees: React.Dispatch<React.SetStateAction<string[]>>;
    selectedAttendees: string[];
    setSelectedAttendees: React.Dispatch<React.SetStateAction<string[]>>;
    showContentShare: boolean;
    setShowContentShare: React.Dispatch<React.SetStateAction<boolean>>;
    canAnnotate: boolean;
    setCanAnnotate: React.Dispatch<React.SetStateAction<boolean>>;
    showAnnotation: boolean;
    setShowAnnotation: React.Dispatch<React.SetStateAction<boolean>>;
    annotationUrlIndex: number | null;
    setAnnotationUrlIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

export const MeetingInfoContext = createContext<MeetingInfoContextProps>({
    meetingId: '',
    userId: '',
    patientId: '',
    userName: '',
    jobTitle: '',
    meetingType: MeetingJoinType.Metro,
    meeting: null,
    errorMessage: null,
    setErrorMessage: () => { },
    connected: false,
    setConnected: () => { },
    showSelf: false,
    setShowSelf: () => { },
    otherAttendees: [],
    setOtherAttendees: () => { },
    selectedAttendees: [],
    setSelectedAttendees: () => { },
    showContentShare: false,
    setShowContentShare: () => { },
    canAnnotate: false,
    setCanAnnotate: () => { },
    showAnnotation: false,
    setShowAnnotation: () => { },
    annotationUrlIndex: null,
    setAnnotationUrlIndex: () => { },
});

interface MeetingInfoProviderProps {
    children: React.ReactNode;
    userId: string;
    meetingId: string;
    userName: string;
    jobTitle: string;
    patientId: string;
    meetingType: MeetingJoinType;
    meeting: Meeting | null;
    errorMessage: string | null;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

export const MeetingInfoProvider: React.FC<MeetingInfoProviderProps> = ({
    children,
    userId,
    meetingId,
    userName,
    jobTitle,
    patientId,
    meetingType,
    meeting,
    errorMessage,
    setErrorMessage,
}) => {

    const [showSelf, setShowSelf] = useState(true);
    const [connected, setConnected] = useState(false);
    const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
    const [showContentShare, setShowContentShare] = useState(false);
    const [otherAttendees, setOtherAttendees] = useState<string[]>([]);

    const [showAnnotation, setShowAnnotation] = useState(false);
    const [annotationUrlIndex, setAnnotationUrlIndex] = useState<number | null>(null);
    const [canAnnotate, setCanAnnotate] = useState(false);

    return (
        <MeetingInfoContext.Provider
            value={{
                meetingId,
                userId,
                patientId,
                userName,
                jobTitle,
                meetingType,
                meeting,
                errorMessage,
                setErrorMessage,
                connected,
                setConnected,
                showSelf,
                setShowSelf,
                selectedAttendees,
                otherAttendees,
                setOtherAttendees,
                setSelectedAttendees,
                showContentShare,
                setShowContentShare,
                canAnnotate,
                setCanAnnotate,
                showAnnotation,
                setShowAnnotation,
                annotationUrlIndex: annotationUrlIndex,
                setAnnotationUrlIndex: setAnnotationUrlIndex,
            }}
        >
            {children}
        </MeetingInfoContext.Provider>
    );
};