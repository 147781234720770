import { Typography } from "@mui/material";

import DictionaryAutocomplete from "../dictionary-autocomplete";
import { Organisation, Site } from "../../types";

interface SelectSiteProps {
    id: string;
    sites: (Site | null)[] | undefined;
    organisationDictionary?: Record<string, Organisation>;
    isLoading: boolean;
    selectedSiteId: string[];
    onChange: (value: string[], sites: Site[]) => void;
    onClose?: () => void;
    disabled?: boolean;
    error?: string | string[] | undefined
}

const SelectSite: React.FC<SelectSiteProps> = ({
    id,
    sites,
    organisationDictionary,
    isLoading,
    selectedSiteId,
    onChange,
    onClose,
    disabled,
    error
}: SelectSiteProps) => {

    const names: Record<string, string> = {};
    const siteDictionary: Record<string, Site> = {};
    const selected: string[] = [];

    const options = []

    const getOrganisationNames = (site: Site) => {

        if (!organisationDictionary) {
            return "";
        }

        let linkedOrganisationNames = "";
        for (let orgId of site.linkedOrganisations) {

            if (!organisationDictionary[orgId]) {
                continue;
            }

            if (linkedOrganisationNames.length > 0) {
                linkedOrganisationNames += ", ";
            }

            linkedOrganisationNames += organisationDictionary[orgId].name;
        }

        return linkedOrganisationNames;
    }

    if (sites) {
        sites.sort((first, second) => {
            if (!first || !second) {
                return 0;
            }

            if (first.linkedOrganisations < second.linkedOrganisations) {
                return -1;
            }

            if (first.linkedOrganisations > second.linkedOrganisations) {
                return 1;
            }

            return 0;
        });

        for (let site of sites) {

            if (!site) {
                continue;
            }

            const includes = selectedSiteId.includes(site.siteId);

            if (includes) {
                selected.push(site.siteId);
            }

            const linkedOrganisationNames = getOrganisationNames(site);

            names[site.siteId] = site.siteName;
            siteDictionary[site.siteId] = site;

            if (linkedOrganisationNames.length > 0) {
                names[site.siteId] += ` (${linkedOrganisationNames})`;
            }

            options.push(site.siteId);
        }
    }

    const createSelectIcon = (key: string) => {
        return (
            <div key={key} style={{
                backgroundColor: siteDictionary[key]?.color || "white",
                width: "20px",
                height: "20px",
                borderRadius: "0.1rem"
            }}></div>
        )
    }

    const onSiteSelectChange = (value: string[]) => {
        const selectedSites = value.map((siteId) => siteDictionary[siteId]);
        onChange(value, selectedSites);
    }

    return (
        <>
            {isLoading ?
                <Typography>Loading...</Typography>
                :
                <>
                    <DictionaryAutocomplete
                        id={id}
                        selectName="Sites"
                        selected={selected}
                        options={options}
                        names={names}
                        onChange={onSiteSelectChange}
                        createSelectIcon={createSelectIcon}
                        onClose={onClose}
                        disabled={disabled}
                        error={error}
                    />
                </>
            }
        </>
    )
}

export default SelectSite;