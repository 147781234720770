import React from "react";

import { Container, Skeleton, Stack, Typography } from "@mui/material";

import { useSearchParams } from "react-router-dom";

import { useDeleteMeeting, useGetCurrentUser, useGetOrganisation, useGetPatient, useMeetingList, useUpdateMeeting } from "../store";
import { canUserEditMeetings, Meeting } from "../types";

import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar";
import MeetingTable from "../components/meeting/meeting-table";
import CustomTag from "../components/custom-tag";
import ConfirmationModal from "../components/confirmation-modal";
import AddMeetingModal from "../components/calendar/add-meeting-modal";
import MeetingInfoModal from "../components/calendar/meeting-info-modal";

const PatientPage: React.FC = () => {

    const [searchParams] = useSearchParams();
    const patientId = searchParams.get("patientId") || "";

    const [selectedMeeting, setSelectedMeeting] = React.useState<Meeting | null>(null);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [cancelConfirmOpen, setCancelConfirmOpen] = React.useState(false);

    const { data: currentUser } = useGetCurrentUser();
    const { data: organisation } = useGetOrganisation(currentUser?.organisationId || "");
    const { data: patient, isLoading: patientLoading } = useGetPatient(patientId);
    const { data: meetings, isLoading: meetingLoading } = useMeetingList();
    const { mutate: updateMeeting } = useUpdateMeeting();
    const { mutate: deleteMeeting } = useDeleteMeeting();

    const isLoading = patientLoading || meetingLoading;
    const showPatientId = patient === null;

    const canEditResponse = canUserEditMeetings(currentUser, organisation);

    let toShow = meetings || [];
    if (patient && meetings) {
        toShow = meetings.filter((meeting: Meeting) => patient.meetingIds.includes(meeting.meetingId));
    }

    const openAdd = () => {
        setAddModalOpen(true);
    }

    const closeAdd = () => {
        setAddModalOpen(false);
    }

    const openEdit = (meeting: Meeting) => {
        setSelectedMeeting(meeting);
        setEditModalOpen(true);
    }

    const closeEdit = () => {
        setEditModalOpen(false);
    }

    const deleteButtonClick = (meeting: Meeting) => {
        setSelectedMeeting(meeting);
        setCancelConfirmOpen(true);
    }

    const confirmDelete = () => {
        if (selectedMeeting) {
            deleteMeeting(selectedMeeting);
        }
        setCancelConfirmOpen(false);
    }

    const cancelDelete = () => {
        setCancelConfirmOpen(false);
    }

    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={2}
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}
        >
            <NavigationTelehealthToolbar currentUser={currentUser} />
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Stack direction={"row"} spacing={2} alignItems="center">
                        <Typography variant="h4">
                            {patient ? "Patient" : "Patients"}
                        </Typography>
                        {patient &&
                            <CustomTag text={patient?.patientId || "All"} color="#e6e6e6" />}
                    </Stack>
                    <MeetingTable
                        isLoading={isLoading}
                        showPatientId={showPatientId}
                        meetings={toShow}
                        addMeetingClick={openAdd}
                        addButtonDisabled={!canEditResponse.canEdit}
                        deleteClick={deleteButtonClick}
                        canDelete={canEditResponse.canEdit}
                        updateClick={openEdit}
                        canEdit={canEditResponse.canEdit}
                    />
                </Stack>
            </Container>
            <ConfirmationModal
                open={cancelConfirmOpen}
                onClose={cancelDelete}
                accept={confirmDelete}
                title={"Delete Meeting"}
                messages={["Are you sure you want to delete this meeting?"]} />
            {selectedMeeting &&
                <MeetingInfoModal
                    open={editModalOpen}
                    handleClose={closeEdit}
                    meeting={selectedMeeting}
                    currentUser={currentUser} />}
            <AddMeetingModal
                open={addModalOpen}
                handleClose={closeAdd}
                patientId={patientId}
                useStartDateInForm={false}
                userOrganisation={organisation}
            />
        </Stack>
    )
}

export default PatientPage;