import { useState } from "react";

import { SelectChangeEvent, Stack } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

import OrganisationColorSelect from "./organisation-color-select";
import TelehealthIconButton from "../telehealth-icon-button";


interface OrganisationColorSelectWithConfirmProps {
    selectedColor: string;
    colorChanged: (color: string) => void;
    showLabel?: boolean
    showNames?: boolean
}

const OrganisationColorSelectWithConfirm: React.FC<OrganisationColorSelectWithConfirmProps> = ({
    selectedColor,
    colorChanged,
    showLabel = true,
    showNames = true,
}) => {

    const [color, setColor] = useState(selectedColor);

    const colorUpdated = (color: string) => {
        setColor(color);
    }

    const confirmColorChange = () => {
        colorChanged(color);
    }


    const updateForChange = (event: SelectChangeEvent<string>) => {
        colorUpdated(event.target.value);
    }

    const canSave = color !== selectedColor;

    return (
        <Stack
            direction={"row"}
            spacing={0.5}
            alignContent={"center"}
            justifyContent={"center"}>
            <OrganisationColorSelect
                selectedColor={color}
                onSelectColor={updateForChange}
                showLabel={showLabel}
                showNames={showNames}
            />
            <Stack width={"100%"} justifyContent={"end"} paddingY={0.5}>
                <TelehealthIconButton
                    onClick={confirmColorChange}
                    disabled={!canSave}
                    color="primary"
                    icon={<SaveIcon />}
                    tooltip={canSave ? "Save changes" : "No changes to save"}
                />
            </Stack>
        </Stack>
    )
}

export default OrganisationColorSelectWithConfirm;