import { canMeetingBeEdited, canUserEditMeetings, getEventStatus, Meeting, Organisation, OrganisationType, User } from "../../types"
import { canJoin, MeetingJoinType } from "../../types"

export const CreateButtonInfo = (meeting: Meeting | null | undefined, user: User | null | undefined, organisation: Organisation | undefined | null) => {

  const buttonType = organisation?.type === OrganisationType.Metro ? MeetingJoinType.Metro : MeetingJoinType.Remote;

  if (!meeting) {
    return {
      canJoinMeeting: false,
      canEdit: false,
      buttonType,
      canJoinMessage: "Can't join meeting",
    }
  }

  const canJoinMeeting = canJoin(meeting)

  const canJoinMessage = getEventStatus(meeting, meeting?.joinedIds || [], meeting?.startTime);

  const canUserEdit = canUserEditMeetings(user, organisation);
  const meetingCanBeEdited = canMeetingBeEdited(meeting, user?.userId || "", organisation?.organisationId || "");

  const canEdit = canUserEdit.canEdit && meetingCanBeEdited.canEdit;

  return {
    canJoinMeeting,
    canEdit,
    buttonType,
    canJoinMessage,
  }
}