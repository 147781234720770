import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import { useGetSiteByIds } from "../../store";
import { Organisation } from "../../types";
import DictionarySelect from "../dictionary-select";
import { lighten, MenuProps, Skeleton, useTheme } from "@mui/material";

interface SiteDropDownProps {
    organisation: Organisation | undefined | null;
    showLabel?: boolean;
    showNames?: boolean;
}

const SiteDropDown: React.FC<SiteDropDownProps> = ({
    organisation,
    showLabel = true,
    showNames = true
}: SiteDropDownProps) => {

    const { data: sites, isLoading } = useGetSiteByIds(organisation?.siteIds || []);

    const { lastSiteId, setLastSiteId } = useContext(AuthContext);

    const theme = useTheme();

    const allKey = "all";

    const siteDictionary: { [key: string]: string } = {}
    const colorDictionary: { [key: string]: string } = {}

    for (const site of sites || []) {
        if (site && site.siteId && site.siteName) {
            siteDictionary[site.siteId] = site.siteName;
            colorDictionary[site.siteId] = site.color;
        }
    }

    siteDictionary[allKey] = "All Sites";
    colorDictionary[allKey] = "#F5F5F5";

    const createSelectIcon = (key: string, value: string) => {
        return (
            <div key={key} style={{
                backgroundColor: colorDictionary[key] || "white",
                width: "20px",
                height: "20px",
                borderRadius: "0.1rem"
            }}></div>
        )
    }

    const onSiteSelect = (event: any): void => {
        setLastSiteId(event.target.value);
    }

    const selected = siteDictionary[lastSiteId] ? lastSiteId : allKey;

    const lighterBackgroundColor = lighten(theme.palette.primary.light, 0.75);

    const menuProps = {
        PaperProps: {
            sx: {
                backgroundColor: lighterBackgroundColor,
                boxShadow: theme.shadows[3],
                borderRadius: "0.1rem",
            },
        },
    };

    return (
        <>
            {isLoading &&
                <Skeleton
                    variant="rectangular"
                    width={200}
                    height={50}
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: "0.5rem",
                        boxShadow: theme.shadows[2],
                    }}
                />}
            {!isLoading &&
                <DictionarySelect
                    name={"siteSelect"}
                    label="Selected Site"
                    dictionary={siteDictionary}
                    showLabel={showLabel}
                    showNames={showNames}
                    selected={selected}
                    onSelect={onSiteSelect}
                    createSelectIcon={createSelectIcon}
                    minWidth={200}
                    menuProps={menuProps}
                    selectSX={{
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: "0.5rem",
                        boxShadow: theme.shadows[2],
                        "& .MuiOutlinedInput-notchedOutline": {
                            display: "none"
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.grey[300],
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.grey[300],
                        },
                        "& .MuiOutlinedInput-root": {
                            boxShadow: theme.shadows[2],
                        },
                    }}
                />}
        </>
    )
}

export default SiteDropDown;