import * as React from 'react';

import { Toolbar, Stack, AppBar } from '@mui/material';

import { MeetingJoinType } from '../../types';
import MeetingControlBar from './meeting-control-bar';

import NormalMeeting from './normal-meeting';
import ShowUploadQR from '../upload/show-upload-qr';
import RemoteHeadsetVideo from './remote-headset-video';

const RemoteUserMeeting: React.FC = () => {

    const viewHeight = 92;
    const viewWidth = 100;

    const stopEvents = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Stack
            width={"100vw"}
            height={"100vh"}
            onClick={stopEvents}
            onMouseDown={stopEvents}
            onMouseUp={stopEvents}
            onContextMenu={stopEvents}
            sx={{
                display: 'flex',
                backgroundColor: 'black',
            }}>
            <AppBar position="sticky"
                sx={{
                    backgroundColor: "#F5F5F5",
                }}>
                <Toolbar disableGutters>
                    <MeetingControlBar >
                        <ShowUploadQR showText={false} showIcon={true} />
                    </MeetingControlBar>
                </Toolbar>
            </AppBar>
            <NormalMeeting
                viewHeight={viewHeight}
                viewWidth={viewWidth}
                remote={true} />
            <RemoteHeadsetVideo
                type={MeetingJoinType.Headset}
                showUpload={false}
            />
        </Stack >
    );
}

export default RemoteUserMeeting;