import React from "react";

import { Stack, TableCell, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Organisation, Site, User, UserType } from "../../types";

import { useGetSiteByIds, useGetUsersWithIds } from "../../store";
import { useNavigate } from "react-router-dom";
import TelehealthIconButton from "../telehealth-icon-button";
import SingleTextFieldForm from "../login/single-text-field-form";

interface OrganisationTableRowProps {
    deleteOrganisation: (organisation: Organisation) => void;
    updateOrganisation: (organisation: Organisation) => void;
    organisation: Organisation;
    canEdit: boolean;
    canDelete: boolean;
}

const OrganisationTableRow: React.FC<OrganisationTableRowProps> = ({
    deleteOrganisation,
    updateOrganisation,
    organisation,
    canEdit,
    canDelete
}) => {

    const { data: users, isLoading: usersLoading } = useGetUsersWithIds(organisation.usersIds, false);
    const { data: sites, isLoading: sitesLoading } = useGetSiteByIds(organisation.siteIds);

    if (usersLoading || sitesLoading) {
        canDelete = false;
    }
    else {
        canDelete = Boolean(canDelete && (users && users.length === 0) && (sites && sites.length === 0));
    }

    let admins: string[] = [];

    if (!usersLoading && users) {

        for (let user of users) {

            if (!user) {
                continue;
            }

            if (user.userType === UserType.SysAdmin || user.userType === UserType.OrgAdmin) {
                admins.push(user.fullName);
            }
        }

        if (admins.length === 0) {
            admins = ["No Admins"];
        }
    }
    else {
        admins = ["Loading..."];
    }

    let siteNameList: string[] = [];

    if (!sitesLoading && sites) {
        for (let site of sites) {
            if (!site) {
                continue;
            }

            siteNameList.push(site.siteName);
        }

        if (siteNameList.length === 0) {
            siteNameList = ["No Sites"];
        }
    }
    else {
        siteNameList = ["Loading..."];
    }

    const navigate = useNavigate();

    const navigateToPath = (navigationPath: string) => {
        navigate(navigationPath)
    }

    const nameUpdated = (name: string) => {
        updateOrganisation({ ...organisation, name });
    }

    return (
        <>
            <TableCell align="left">
                {!canEdit &&
                    <Typography variant="body1" fontSize={16}>
                        {organisation.name}
                    </Typography>}
                {canEdit &&
                    <SingleTextFieldForm
                        fieldName="siteName"
                        label="Site Name"
                        defaultValue={organisation.name}
                        onSubmit={nameUpdated}
                        yPadding={1} />}
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {organisation.type}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {admins.join(", ")}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {siteNameList.join(", ")}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Stack
                    direction="row"
                    justifyContent={"right"}>
                    <TelehealthIconButton
                        icon={<EditIcon />}
                        color="info"
                        tooltip="Edit Organisation"
                        inactiveTooltip="You do not have permission to edit this organisation"
                        disabled={!canEdit}
                        onClick={() => navigateToPath(`/organisation?organisationId=${organisation.organisationId}`)} />
                    <TelehealthIconButton
                        icon={<DeleteForeverIcon />}
                        color="error"
                        tooltip="Delete Organisation"
                        inactiveTooltip="You do not have permission to delete this organisation"
                        disabled={!canDelete}
                        onClick={() => deleteOrganisation(organisation)} />
                </Stack>
            </TableCell>
        </>
    )
}

export default OrganisationTableRow;