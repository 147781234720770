import React, { PropsWithChildren, useContext } from "react";

import { Paper, Stack } from "@mui/material";

import { MeetingInfoContext } from "../../context/meeting-info-context";

import LabelledMuteButton from "./labelled-mute-button";
import LabelledAudioButton from "./labelled-audio-button";
import LabelledCameraButton from "./labelled-camera-button";
import LeaveCallButton from "./leave-call-button";
import LabelledContentShareButton from "./labelled-content-share-button";
import PopupMessage from "../popup-message";

interface ChimeControlBarProps {
    remote?: boolean;
    showText?: boolean;
    startWithVideo?: boolean;
    startWithAudio?: boolean;
    startMuted?: boolean;
    contentShare?: boolean;
}

const ChimeControlBar: React.FC<PropsWithChildren<ChimeControlBarProps>> = ({
    remote = false,
    showText,
    startWithVideo = false,
    startWithAudio = true,
    startMuted = false,
    contentShare = false,
    children
}) => {

    const preferredCamera = remote ? "poly studio" : undefined

    const { errorMessage, connected, meetingId, meetingType, showAnnotation } = useContext(MeetingInfoContext);
    const disabled = (errorMessage != null && errorMessage.length > 0) || !connected;

    const [deviceError, setDeviceError] = React.useState<string | null>(null);

    const onCameraFound = (found: string | undefined) => {
        if (!found) {
            if (remote) {
                setDeviceError("Could not find PolyCam, please check it is connected.");
            }
            else {
                console.log("Camera not found");
            }
        }
    }

    return (
        <Paper
            elevation={20}
            sx={{
                mt: 4,
                padding: 1,
                borderRadius: "10px",
                height: "fit-content",
                width: "fit-content",
                position: "fixed",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 100,
                display: disabled ? "none" : "flex"
            }}
        >
            <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-around">
                <LabelledMuteButton
                    disabled={disabled}
                    startMuted={startMuted}
                    showText={showText} />
                <LabelledAudioButton
                    disabled={disabled}
                    startWithAudio={startWithAudio}
                    showText={showText} />
                <LabelledCameraButton
                    disabled={disabled}
                    startWithVideo={startWithVideo}
                    showText={showText}
                    cameraType={preferredCamera}
                    subCameraType={"echo cancelling speakerphone"}
                    onCameraFound={onCameraFound} />
                {contentShare &&
                    <LabelledContentShareButton
                        disabled={disabled}
                        showText={showText} />}
                {children}
                <LeaveCallButton
                    meetingType={meetingType}
                    showText={showText}
                    meetingId={meetingId} />
            </Stack>
            <PopupMessage
                message={deviceError || ""}
                open={deviceError != null && deviceError.length > 0}
                severity={"error"}
                onClose={() => setDeviceError(null)} />
        </Paper>

    );
}

export default ChimeControlBar;