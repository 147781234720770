import React from 'react';

import { Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { ConnectIoTContext, ConnectionStatus } from '../../websocket/connect-iot';

interface CallQualityProps {
    showLabel?: boolean;
}

const ConnectedAnnotation: React.FC<CallQualityProps> = ({
    showLabel,
}) => {

    const { connectionStatus } = React.useContext(ConnectIoTContext);

    const getColorAndMessage = () => {

        if (connectionStatus == ConnectionStatus.NotSet || connectionStatus == ConnectionStatus.Reconnecting) {
            return { color: "orange", message: "Connecting to Annotation Server" };
        }

        if (connectionStatus == ConnectionStatus.Disconnected || connectionStatus == ConnectionStatus.Stopped) {
            return { color: "red", message: "Not connected to Annotation Server" };
        }

        if (connectionStatus == ConnectionStatus.Connected) {
            return { color: "green", message: "Connected to Annotation Server" };
        }

        return { color: "white", message: "Unknown status" };
    }

    const { color, message: iotMessage } = getColorAndMessage();

    return (
        <Stack direction="row" alignItems={"center"} spacing={1}>
            {color === "red" &&
                <ErrorOutlineIcon style={{ color: "red" }} />}
            {color !== "red" &&
                <CheckCircleOutlineIcon style={{ color: color }} />}
            {showLabel && <Typography variant="h6" sx={{
                color: "black"
            }}>
                {iotMessage}
            </Typography>}
        </Stack >
    );
};

export default ConnectedAnnotation;