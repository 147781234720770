import React, { PropsWithChildren } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';

import { User } from '../../types';

import OptionsDropdown from './options-dropdown';
import NavigationMenuItem from '../navigation-menu-item';
import LogoutButton from '../login/logout-button';
import { RouteType } from '../../router';
import { useLocation } from 'react-router-dom';

interface CalendarOptionsDropdownProps {
    currentUser?: User | null,
}

export const CalendarOptionsDropdown: React.FC<PropsWithChildren<CalendarOptionsDropdownProps>> = ({
    currentUser,
    children
}) => {

    const location = useLocation();

    const page = location.pathname

    return (
        <OptionsDropdown
            dropdownName="calendar"
            displayedName={currentUser?.fullName || ""}>
            {page !== RouteType.Account &&
                <NavigationMenuItem
                    label={"Account Settings"}
                    icon={<AccountCircleIcon />}
                    navigationPath={RouteType.Account}
                />}
            {page !== RouteType.Help &&
                <NavigationMenuItem
                    label={"Help"}
                    icon={<HelpIcon />}
                    navigationPath={RouteType.Help}
                />}
            {children}
            <LogoutButton />
        </OptionsDropdown>
    );
};

export default CalendarOptionsDropdown;