import { Card, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { Organisation } from "../../types";
import OrganisationTableRow from "./organisation-table-row";

interface OrganisationTableProps {
    isLoading: boolean;
    organisations: Organisation[] | undefined;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    updateOrganisation: (organisation: Organisation) => void;
    deleteOrganisation: (organisation: Organisation) => void;
}

const OrganisationTable: React.FC<OrganisationTableProps> = ({
    isLoading,
    organisations,
    showEditButton,
    showDeleteButton,
    updateOrganisation,
    deleteOrganisation,
}) => {

    return (
        <Card sx={{
            width: "95%",
            height: "95%",
            padding: 2,
        }}>
            <TableContainer component={Stack}>
                <Table
                    sx={{ width: "100%" }}
                    aria-label="organisation table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Name
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Type
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Admins
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Sites
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1" fontSize={16}>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Skeleton variant="rectangular" height={50} />
                                </TableCell>
                            </TableRow>}
                        {!isLoading && organisations && organisations.map((organisation) => {
                            return (
                                <TableRow
                                    key={organisation.organisationId}>
                                    <OrganisationTableRow
                                        canEdit={showEditButton || false}
                                        canDelete={showDeleteButton || false}
                                        deleteOrganisation={deleteOrganisation}
                                        updateOrganisation={updateOrganisation}
                                        organisation={organisation} />
                                </TableRow>
                            )
                        })}
                        {!isLoading && organisations && organisations.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={5} sx={{
                                    paddingY: 2
                                }}>
                                    <Typography variant="body1">
                                        No organisations found
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default OrganisationTable;