import React, { useEffect } from "react";

import { Stack } from "@mui/material";

import { useContentShareState } from "amazon-chime-sdk-component-library-react";

import ChimeControlBar from "./chime-control-bar";
import ContentShareTile from "./content-share-tile";
import FilteredVideoTileGrid from "./filtered-video-tile-grid";
import CornerLocalView from "./corner-local-view";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import SendAnnotationMessage from "../../annotation/send-annotation-message";
import { useGetSiteByIds, useGetUser } from "../../store";

interface NormalMeetingProps {
    viewHeight: number;
    viewWidth: number;
    remote: boolean;
    excludeSelf?: boolean;
}

const staticCss: React.CSSProperties =
{
    position: "absolute",
    display: "flex",
    bottom: "1rem",
    left: "1rem",
    width: "20vw",
    maxHeight: "30vh",
    height: "auto",
    zIndex: "100",
}

const NormalMeeting: React.FC<NormalMeetingProps> = ({
    viewHeight,
    viewWidth,
    remote,
    excludeSelf = true
}: NormalMeetingProps) => {

    const { sharingAttendeeId, isLocalUserSharing } = useContentShareState();
    const { userId, meeting } = React.useContext(MeetingInfoContext);
    const fullSizeScreenShare = sharingAttendeeId != undefined && !isLocalUserSharing;

    const { showAnnotation } = React.useContext(MeetingInfoContext);

    const [startColor, setStartColor] = React.useState<string | undefined>(undefined);

    const { data: currentUser, isLoading: loadingUser } = useGetUser(userId);

    const { data: sites, isLoading: loadingSites } = useGetSiteByIds(meeting?.siteIds || []);

    useEffect(() => {

        if (startColor) {
            return;
        }

        if (loadingUser || loadingSites) {
            return;
        }

        if (!sites || !currentUser) {
            return;
        }

        let color = undefined;
        if (sites && sites.length > 0 && currentUser) {
            for (const site of sites) {

                if (!site) {
                    continue;
                }

                if (site.linkedOrganisations.includes(currentUser?.organisationId)) {
                    color = site.color;
                    break;
                }
            }
        }

        if (color) {
            setStartColor(color)
        }

    }, [sites, currentUser]);

    return (
        <>
            <Stack
                id={"normal-meeting-stack"}
                justifyContent={"center"}
                justifyItems={"center"}
                alignContent={"center"}
                alignItems={"center"}
                minHeight={`${viewHeight}vh`}>
                {sharingAttendeeId &&
                    <ContentShareTile
                        tileCount={1}
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        customCss={isLocalUserSharing ? staticCss : undefined} />}
                {!fullSizeScreenShare && !showAnnotation &&
                    <FilteredVideoTileGrid
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        showNamePlate
                        excludeSelf={excludeSelf} />}
                {startColor &&
                    <SendAnnotationMessage
                        userId={userId}
                        startColor={startColor} />}
            </Stack>
            <ChimeControlBar
                showText={false}
                startWithVideo={remote}
                contentShare={true}
                remote={remote} />
            <CornerLocalView
                viewHeight={viewHeight}
                viewWidth={viewWidth}
            />
        </>
    );
};

export default NormalMeeting;