import React, { PropsWithChildren, useEffect } from "react";

import { MeetingInfoContext } from "../../context/meeting-info-context";
import NoRemoteView from "./no-remote-view";

const ErrorOrChildren: React.FC<PropsWithChildren> = ({
    children,
}) => {

    const { connected, errorMessage } = React.useContext(MeetingInfoContext);

    return (
        <>
            {connected && errorMessage === null && children}
            {errorMessage && <NoRemoteView message={errorMessage} backgroundColor='lightGrey' />}
        </>
    );
};

export default ErrorOrChildren;
