import React, { useContext } from "react";

import { Stack, Skeleton, } from "@mui/material";

import { useDeleteMeeting, useGetCurrentUser, useGetOrganisation, useListSites, useMeetingList, useUpdateMeeting } from "../store";
import { AuthContext } from "../context/auth-context";

import EventCalendar from "../components/calendar/EventCalendar";
import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar";

const CalendarPage: React.FC = () => {

  const { data: siteList, isLoading: loadingSiteList } = useListSites();
  const { data: meetingList, isLoading: loadingMeeting } = useMeetingList();
  const { data: currentUser, isLoading: loadingCurrentUser } = useGetCurrentUser()
  const { data: currentOrganisation, isLoading: loadingCurrentOrganisation } = useGetOrganisation(currentUser?.organisationId || "")

  const { userId } = useContext(AuthContext)

  const loadingData = loadingMeeting || loadingCurrentUser || loadingCurrentOrganisation || loadingSiteList

  return (
    <Stack
      justifyContent="top"
      alignItems="center"
      spacing={2}
      sx={{
        height: "100vh",
      }}
    >
      <NavigationTelehealthToolbar currentUser={currentUser} />
      {loadingData ?
        <Skeleton width={"95vw"} height={"95vh"}></Skeleton> :
        <EventCalendar
          userId={userId}
          meetingList={meetingList || []}
          siteList={siteList || []}
          currentUser={currentUser}
          currentOrganisation={currentOrganisation} />
      }
    </Stack >
  )
}

export default CalendarPage;
