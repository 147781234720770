import React, { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';

import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';

import ConnectedAnnotation from './connected-annotation';

interface CallQualityProps {
    showLabel?: boolean;
    showIotWarning?: boolean;
}

const CallQuality: React.FC<CallQualityProps> = ({
    showLabel,
    showIotWarning = true
}) => {

    const meetingManager = useMeetingManager();

    const [statString, setStatString] = useState<string>("Good");

    const [callColour, setCallColour] = useState<string>("green");

    const [observerSet, setObserverSet] = useState(false);

    const okQualityThreshold = 1000;
    const badQualityThreshold = 400;

    useEffect(() => {

        if (observerSet) {
            return;
        }

        if (!meetingManager.audioVideo) {
            return;
        }

        const observerMetrics = {
            metricsDidReceive: (clientMetricReport: { getObservableMetrics: () => any; }) => {
                const metricReport = clientMetricReport.getObservableMetrics();

                const {
                    videoPacketSentPerSecond,
                    videoUpstreamBitrate,
                    availableOutgoingBitrate,
                    availableIncomingBitrate,
                    audioSpeakerDelayMs,
                } = metricReport;

                const incomingBandwidth = availableIncomingBitrate / 1000;
                const outgoingBandwidth = availableOutgoingBitrate / 1000;

                let newStatString = "Good";

                if (outgoingBandwidth < badQualityThreshold) {
                    newStatString = "Bad";
                }
                else if (outgoingBandwidth < okQualityThreshold) {
                    newStatString = "Ok";
                }
                else {
                    newStatString = "Good"
                }

                if (newStatString === "Good") {
                    setCallColour("green");
                }
                else if (newStatString === "Ok") {
                    setCallColour("orange");
                }
                else {
                    setCallColour("red");
                }

                setStatString(newStatString);
            },
        };

        meetingManager.audioVideo.addObserver(observerMetrics);

        setObserverSet(true);

    }, [meetingManager.audioVideo]);

    return (
        <Stack direction="row" alignItems={"center"} spacing={1}>
            {showIotWarning && <ConnectedAnnotation showLabel={showLabel} />}
            <NetworkCheckIcon style={{ color: callColour }} />
            {showLabel &&
                <Typography variant="h6" sx={{
                    color: "black"
                }}>
                    Connection: {statString}
                </Typography>
            }

        </Stack >
    );
};

export default CallQuality;