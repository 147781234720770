import React from "react";
import { useContext } from "react";

import { Stack } from "@mui/material";

import { MeetingInfoContext } from "../../context/meeting-info-context";

import AttendeeSelect from "./attendee-select";

import DownloadPhotoUrlModal from "../upload/download-photo-url-modal";
import CallQuality from "./call-quality";

interface MeetingControlBarProps {
    children?: React.ReactNode;
}

const MeetingControlBar: React.FC<MeetingControlBarProps> = ({
    children,
}) => {

    const { connected } = useContext(MeetingInfoContext);

    return (
        <Stack
            direction={"row"}
            spacing={2}
            paddingX={2}
            justifyContent={"space-between"}
            width={"100%"}>
            <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}>
                <AttendeeSelect />
            </Stack>
            <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}>
                <CallQuality showLabel />
                {connected && <DownloadPhotoUrlModal />}
                {children}
            </Stack>
        </Stack>
    );
}

export default MeetingControlBar;