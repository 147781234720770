import React, { PropsWithChildren } from "react"

import { Stack } from "@mui/material"

import { canJoin, Meeting, Organisation, User } from "../../types"
import { CreateButtonInfo } from "./create-button-info"

import { useGetMeeting, useUpdateMeeting } from "../../store"

import toast from "react-hot-toast"

import DialogueJoinMeetingButton from "../upload/dialogue-join-meeting-button"
import AttendeeOrganisationGrid from "./attendee-organisation-grid"
import EditMeetingTime from "./edit-meeting-time"
import PatientTag from "../patients/patient-tag"
import EditSitesList from "./edit-sites-list"
import CancelMeetingButton from "./cancel-meeting-button"
import TelehealthStyledButton from "../telehealth-styled-button"

interface MeetingInfoProps {
  meeting: Meeting
  canEdit: boolean
  showEditButton: boolean
  editButtonClick: () => void
  currentUser?: User | null
  userOrganisation?: Organisation | null
}

const MeetingInfo: React.FC<PropsWithChildren<MeetingInfoProps>> = ({
  meeting,
  canEdit,
  currentUser,
  userOrganisation,
  showEditButton,
  editButtonClick,
  children,
}) => {

  const toastMeetingUpdateSuccess = () => {
    toast.success("Meeting updated successfully")
  }

  const toastMeetingUpdateFailure = () => {
    toast.error("Meeting update failed")
  }

  const meetingIdToGet = canJoin(meeting) ? meeting?.meetingId : ""
  const { data: updatedMeeting } = useGetMeeting(meetingIdToGet)
  const { mutate: updateMeeting } = useUpdateMeeting(toastMeetingUpdateSuccess, toastMeetingUpdateFailure)

  const finalMeeting: Meeting | null = updatedMeeting || meeting

  const updateSiteIds = (siteIds: string[]) => {
    if (!finalMeeting) {
      return
    }

    updateMeeting({
      ...finalMeeting,
      siteIds: siteIds,
    })
  }

  const buttonInfo = CreateButtonInfo(finalMeeting, currentUser, userOrganisation);

  return (
    <Stack
      direction={"column"}
      alignContent={"center"}
      justifyContent={"centre"}
      spacing={1}>
      <PatientTag patientId={finalMeeting?.patientId || ""} />
      <EditMeetingTime
        meeting={finalMeeting}
        updateMeeting={updateMeeting}
        canEdit={buttonInfo.canEdit && canEdit} />
      <Stack
        direction={"column"}
        spacing={1}
        justifyContent={"center"}
        sx={{
          backgroundColor: "#e6e6e6",
          borderRadius: "0.5rem",
          paddingY: "0.5rem",
        }}>
        <Stack
          direction={"column"}
          spacing={2}
          alignContent={"center"}
          justifyContent={"space-evenly"}
          sx={{
            paddingX: "0.5rem",
            minHeight: "75px"
          }}>
          <DialogueJoinMeetingButton
            canJoinNow={buttonInfo.canJoinMeeting}
            meeting={finalMeeting}
            currentUser={currentUser}
            canJoinMessage={buttonInfo.canJoinMessage}
            buttonType={buttonInfo.buttonType} />
        </Stack>
      </Stack>
      <EditSitesList
        canEdit={buttonInfo.canEdit && canEdit}
        siteIds={finalMeeting?.siteIds}
        organisationIds={finalMeeting?.organisationIds}
        updateMeeting={updateSiteIds} />
      <AttendeeOrganisationGrid
        direction={"column"}
        meeting={finalMeeting} />
      {showEditButton &&
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"left"}>
          <TelehealthStyledButton
            variant={"outlined"}
            showText={true}
            onClick={editButtonClick}
            label={"Edit Meeting"}
            tooltipStackSx={{
              width: "50%",
            }}
          />
          <CancelMeetingButton
            meeting={meeting}
            canEdit={buttonInfo.canEdit && !buttonInfo.canJoinMeeting}
            tooltipStackSx={{
              width: "50%",
            }}
          />
        </Stack>}
      {children}
    </Stack>
  )
}

export default MeetingInfo
