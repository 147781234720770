import { Stack, Typography } from "@mui/material";

import CustomTag from "../custom-tag";

interface PatientTagProps {
    patientId: string
}

const PatientTag: React.FC<PatientTagProps> = ({
    patientId,
}: PatientTagProps) => {
    return (
        <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"left"}
            alignItems={"center"}
            sx={{
                marginBottom: "0.5rem",
            }}>
            <Typography variant={"h4"}>
                Patient:
            </Typography>
            <CustomTag
                text={patientId}
                color="#e6e6e6"
                sx={{
                    height: "fit-content",
                    width: "fit-content",
                    paddingY: "0.2rem",
                    paddingX: "0.4rem",
                }} />
        </Stack>
    )
}

export default PatientTag