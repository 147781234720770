import React from "react";

import { Container, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

import { v4 as uuidv4 } from "uuid";

import { Organisation, OrganisationType, UserType } from "../types";

import { useAddOrganisation, useDeleteOrganisation, useGetCurrentUser, useGetOrganisation, useListOrganisations, useUpdateOrganisation } from "../store";

import EditOrganisationModal from "../components/organisation/edit-organisation-modal";

import OrganisationTable from "../components/organisation/organisation-table";
import ConfirmationModal from "../components/confirmation-modal";
import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar";
import TelehealthStyledButton from "../components/telehealth-styled-button";
import toast from "react-hot-toast";

const OrganisationAdminPage: React.FC = () => {

    const [selectedOrganisation, setSelectedOrganisation] = React.useState<Organisation | null>(null);

    const defaultOrganisation: Organisation = {
        organisationId: uuidv4(),
        name: "",
        type: OrganisationType.Metro,
        color: "#D37676",
        usersIds: [],
        siteIds: [],
    };

    const [newOrganisation, setNewOrganisation] = React.useState<Organisation>(defaultOrganisation);

    const [addOrganisationModalOpen, setAddOrganisationModalOpen] = React.useState<boolean>(false)
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    const { data: organisations, isLoading: orgLoading } = useListOrganisations();
    const { data: currentUser, isLoading: userLoading } = useGetCurrentUser()
    const { data: userOrganisation, isLoading: userOrgLoading } = useGetOrganisation(currentUser?.organisationId || "");

    const isLoading = orgLoading || userLoading || userOrgLoading;

    const onAddSuccess = () => {
        toast.success("Organisation added successfully");
    }

    const onAddError = (err: any) => {
        console.log(err);
        toast.error("Failed to add organisation");
    }

    const onUpdateSuccess = () => {
        toast.success("Organisation updated successfully");
    }

    const onUpdateError = (err: any) => {
        console.log(err);
        toast.error("Failed to update organisation");
    }

    const onDeleteSuccess = () => {
        toast.success("Organisation deleted successfully");
    }

    const onDeleteError = (err: any) => {
        console.log(err);
        toast.error("Failed to delete organisation");
    }

    const { mutate: addOrganisation } = useAddOrganisation(onAddSuccess, onAddError);
    const { mutate: updateOrganisation } = useUpdateOrganisation(onUpdateSuccess, onUpdateError);
    const { mutate: deleteOrganisation } = useDeleteOrganisation(onDeleteSuccess, onDeleteError);

    const isAdmin = Boolean(currentUser && currentUser?.userType === UserType.SysAdmin);
    const isOrgAdmin = Boolean(currentUser && currentUser?.userType === UserType.OrgAdmin);

    let organisationList: Organisation[] | undefined = organisations

    if (organisationList && !isAdmin && isOrgAdmin && userOrganisation) {
        organisationList = [userOrganisation]
    }

    const openModal = () => {
        setNewOrganisation(defaultOrganisation);

        setAddOrganisationModalOpen(true);
    }

    const closeModal = () => {
        setAddOrganisationModalOpen(false);
    }

    const deleteOrganisationClick = () => {
        deleteOrganisation(selectedOrganisation);
        closeDeleteConfirmation();
    }

    const openDeleteConfirmation = (organisation: Organisation) => {
        setSelectedOrganisation(organisation);
        setConfirmationOpen(true);
    }

    const closeDeleteConfirmation = () => {
        setConfirmationOpen(false);
    }

    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={2}
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}
        >
            <NavigationTelehealthToolbar currentUser={currentUser} />
            <Container maxWidth="xl">
                <Stack
                    justifyContent="top"
                    alignItems="center"
                    spacing={2}>
                    <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                        width={"96%"}>
                        <Typography variant="h4">
                            Organisations
                        </Typography>
                        <TelehealthStyledButton
                            onClick={openModal}
                            variant="contained"
                            label="Add Organisation"
                            showText={true}
                            icon={<AddIcon />}
                        />
                    </Stack>
                    <OrganisationTable
                        isLoading={isLoading}
                        organisations={organisationList}
                        showEditButton={isAdmin || isOrgAdmin}
                        showDeleteButton={isAdmin}
                        deleteOrganisation={openDeleteConfirmation}
                        updateOrganisation={updateOrganisation} />
                    <EditOrganisationModal
                        open={addOrganisationModalOpen}
                        onClose={closeModal}
                        organisation={newOrganisation}
                        updateOrganisation={addOrganisation} create={true} />
                    <ConfirmationModal
                        open={confirmationOpen}
                        accept={deleteOrganisationClick}
                        onClose={closeDeleteConfirmation}
                        title={"Delete Organisation"}
                        messages={["Are you sure you want to delete organisation?"]}
                        warningLine={"This action cannot be undone."} />
                </Stack>
            </Container>
        </Stack >
    );
}

export default OrganisationAdminPage;