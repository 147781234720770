import { Alert, Snackbar } from "@mui/material";


interface PopupMessageProps {
    message: string;
    open: boolean;
    severity: "error" | "warning" | "info" | "success";
    onClose: () => void;
}

const PopupMessage: React.FC<PopupMessageProps> = ({
    message,
    open,
    severity,
    onClose
}: PopupMessageProps) => {

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            sx={{
                minWidth: { xs: "100%", sm: "100%" },
                bottom: { xs: 100, sm: 100 }
            }}>
            <Alert
                onClose={onClose}
                severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default PopupMessage;