import React from "react";

import { Stack, Typography, useTheme } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

import { useAdminDeleteUser, useGetUsersWithIds, useUpdateOrganisation } from "../../store";
import { Organisation, User, UserType } from "../../types";

import UserTable from "./user-table";
import AddUserModal from "./add-user-modal";
import ConfirmationModal from "../confirmation-modal";
import EditOrganisationModal from "./edit-organisation-modal";
import TelehealthStyledButton from "../telehealth-styled-button";
import toast from "react-hot-toast";
import TransferUserOrganisationModal from "./transfer-user-organisation-modal";
import SiteTable from "../site/site-table";
import TemporaryUserTable from "./temporary-user-table";

interface EditOrganisationPageProps {
    finalOrganisation: Organisation;
}


const EditOrganisationPage: React.FC<EditOrganisationPageProps> = ({
    finalOrganisation,
}) => {

    const [addUserOpen, setAddUserOpen] = React.useState<boolean>(false);
    const [addNormalUser, setAddNormalUser] = React.useState<boolean>(false);
    const [temporaryUser, setTemporaryUser] = React.useState<User | undefined>(undefined);
    const [confirmationOpen, setConfirmationOpen] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [selectedTransferUser, setSelectedTransferUser] = React.useState<User | null>(null);

    const onDeleteSuccess = () => {
        toast.success("User deleted successfully");
    }

    const onDeleteError = (err: any) => {
        console.log(err);
        toast.error("Failed to delete user");
    }

    const { data: organisationUsers, isLoading } = useGetUsersWithIds(finalOrganisation?.usersIds || [], false);
    const { mutate: adminDeleteUser } = useAdminDeleteUser(onDeleteSuccess, onDeleteError);

    const temporaryUsers: User[] = []
    const permanentUsers: User[] = []

    for (const user of organisationUsers || []) {
        if (!user) {
            continue;
        }

        if (user.userType === UserType.Temporary) {
            temporaryUsers.push(user)
        } else {
            permanentUsers.push(user)
        }
    }

    const addButton = () => {
        setAddUserOpen(true);
        setAddNormalUser(true);
    }

    const addTemporaryUserButton = () => {
        setAddUserOpen(true);
        setAddNormalUser(false);
    }

    const closeAddUser = () => {
        setTemporaryUser(undefined);
        setAddUserOpen(false);
    }

    const deleteUser = (user: User) => {
        setSelectedUser(user);
        setConfirmationOpen(true);
    }

    const deleteUserClick = () => {
        if (!selectedUser) {
            console.error("No user selected to delete");
            return;
        }

        adminDeleteUser(selectedUser);
        setConfirmationOpen(false);
    }

    const closeUserConfirmation = () => {
        setConfirmationOpen(false);
    }

    const convertTemporaryUser = (user: User) => {
        setTemporaryUser(user);
        setAddUserOpen(true);
    }

    const openTransferUser = (user: User) => {
        setSelectedTransferUser(user);
    }

    const closeTransferUser = () => {
        setSelectedTransferUser(null);
    }

    const deleteMessage = [`Are you sure you want to delete the following user?`, `${selectedUser?.fullName || ""}`];

    // get normal color of text form mui theme

    const theme = useTheme();

    const normalTextColor = theme.palette.text.primary;

    return (
        <Stack
            direction={"column"}
            spacing={2}
            alignItems={"left"}>
            <Typography variant="h3" color={normalTextColor}>
                {finalOrganisation?.name || "Organisation"} Settings
            </Typography>
            <SiteTable organisation={finalOrganisation} />
            <UserTable
                users={permanentUsers}
                isLoading={isLoading}
                addButtonClick={addButton}
                convertTemporaryUser={convertTemporaryUser}
                deleteUser={deleteUser}
                transferUser={openTransferUser} />
            <TemporaryUserTable
                users={temporaryUsers}
                isLoading={isLoading}
                addButtonClick={addTemporaryUserButton}
                convertTemporaryUser={convertTemporaryUser}
                deleteUser={deleteUser}
                transferUser={openTransferUser} />
            <AddUserModal
                open={addUserOpen}
                user={temporaryUser}
                organisationId={finalOrganisation?.organisationId || ""}
                onClose={closeAddUser}
                showEmail={addNormalUser} />
            <ConfirmationModal
                open={confirmationOpen}
                accept={deleteUserClick}
                onClose={closeUserConfirmation}
                title={"Delete User"}
                messages={deleteMessage}
                warningLine={"This action cannot be undone."} />
            <TransferUserOrganisationModal
                open={selectedTransferUser !== null}
                onClose={closeTransferUser}
                user={selectedTransferUser || undefined} />
        </Stack>
    )
}

export default EditOrganisationPage;