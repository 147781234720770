import * as React from 'react';

import { Toolbar, Stack, AppBar, Typography } from '@mui/material';

import { MeetingJoinType } from '../../types';
import MeetingControlBar from './meeting-control-bar';

import NormalMeeting from './normal-meeting';
import ShowUploadQR from '../upload/show-upload-qr';
import RemoteHeadsetVideo from './remote-headset-video';

const TrainingUserMeeting: React.FC = () => {

    const viewHeight = 92;
    const viewWidth = 100;

    return (
        <Stack
            width={"100vw"}
            height={"100vh"}
            sx={{
                display: 'flex',
                backgroundColor: 'black',
            }}>
            <AppBar position="sticky"
                sx={{
                    backgroundColor: "#F5F5F5",
                }}>
                <Toolbar disableGutters>
                    <MeetingControlBar >
                        <ShowUploadQR showText={false} showIcon={true} />
                    </MeetingControlBar>
                </Toolbar>
            </AppBar>
            <NormalMeeting
                viewHeight={viewHeight}
                viewWidth={viewWidth}
                remote={true}
                excludeSelf={false} />
            <div style={{
                position: "absolute",
                backdropFilter: `blur(20px)`,
                backgroundColor: `rgba(46, 47, 52, 0.7)`,
                borderRadius: `0.25rem`,
                color: `white`,
                top: "5rem",
                left: "40vw",
                maxWidth: "20vw",
                width: "fit-content",
                padding: "0.5rem",
                zIndex: 200,
                justifySelf: "start",
            }}>
                <Stack spacing={1} alignItems={"center"}>
                    <Typography variant="h6">Training Meeting:</Typography>
                    <Typography variant="body1">Video from the RealWare is shown in place of Metro Video.</Typography>
                </Stack>
            </div>
            <RemoteHeadsetVideo
                type={MeetingJoinType.Headset}
                showUpload={false}
            />
        </Stack >
    );
}

export default TrainingUserMeeting;