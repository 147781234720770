import React from "react"

import { canJoin, Meeting, MeetingJoinType, MeetingStatus, Organisation, User } from "../../types"
import { useGetCurrentUser, useGetOrganisationByIds, useGetUsersWithIds } from "../../store"

import EditOrganisationTag from "./edit-organisation-tag"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { Skeleton, Typography } from "@mui/material"

interface AttendeeOrganisationGridProps {
    meeting: Meeting | undefined | null,
    title?: string,
    compact?: boolean,
    direction: "row" | "column",
}

const AttendeeOrganisationGrid: React.FC<AttendeeOrganisationGridProps> = ({
    meeting,
    title,
    compact = false,
    direction,
}) => {

    const { data: currentUser } = useGetCurrentUser()
    const { data: userList } = useGetUsersWithIds(meeting?.attendeesId || [], true)
    const { data: organisations } = useGetOrganisationByIds(meeting?.organisationIds || [])

    const userByOrganisationDict: { [key: string]: User[] } = {}

    const canJoinMeeting = meeting ? canJoin(meeting) : false
    const cancelled = meeting?.meetingType === MeetingStatus.Cancelled

    if (userList) {

        for (let i = 0; i < userList.length; i++) {
            const user = userList[i]

            if (!user) {
                continue;
            }

            if (!userByOrganisationDict[user.organisationId]) {
                userByOrganisationDict[user.organisationId] = []
            }
            userByOrganisationDict[user.organisationId].push(user)
        }
    }

    const createOrganisationUserDisplay = (organisation: Organisation | null, organisationId: string) => {

        if (!organisation) {
            return null
        }

        const users: User[] = userByOrganisationDict[organisationId] || []

        if (users.length === 0 && !organisation) {
            return null
        }

        const canEdit: boolean = Boolean(currentUser && organisation && organisation.organisationId === currentUser.organisationId)

        return (
            <Grid2
                key={organisationId + "-attendee-organisation-grid"}
                alignItems={"top"}
                justifyItems={"left"}>
                {meeting &&
                    <EditOrganisationTag
                        organisation={organisation || undefined}
                        meeting={meeting}
                        invitedUsers={users}
                        canEdit={canEdit && !cancelled}
                        canJoin={canJoinMeeting}
                        compact={compact}
                    />}
                {!meeting &&
                    <Skeleton variant="rectangular" width={300} height={100} />}
            </Grid2 >
        )
    }

    return (
        <Grid2
            container
            spacing={1}
            direction={direction}>
            {title &&
                <Grid2 sm={12}>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                </Grid2>}
            {organisations &&
                organisations.map((organisation: Organisation | null) => (
                    createOrganisationUserDisplay(organisation, organisation?.organisationId || "")
                ))
            }
        </Grid2>
    )
}

export default AttendeeOrganisationGrid