import { Button, ButtonGroup, SxProps, Theme, useTheme, Tooltip } from "@mui/material"

interface ButtonInfo {
    label: string,
    id: string,
    tooltip?: string
    disabled?: boolean
}

interface TelehealthButtonBarProps {
    labels: ButtonInfo[],
    buttonSx?: SxProps<Theme>;
    onClick: (button: string) => void
    variant?: "contained" | "outlined" | "text"
}

const TelehealthButtonBar: React.FC<TelehealthButtonBarProps> = ({
    labels,
    onClick,
    buttonSx,
    variant = "contained"
}) => {

    const theme = useTheme();

    return (
        <ButtonGroup sx={{
            backgroundColor: theme.palette.primary.light,
            boxShadow: theme.shadows[3],
            borderRadius: "0.5rem",
        }}>
            {labels.map((info) => (
                info && info.label &&
                <Tooltip
                    title={info.tooltip || info.label} key={info.label + "tooltip"}>
                    <div>
                        <Button
                            variant={variant}
                            onClick={() => onClick(info.id)}
                            disabled={info.disabled}
                            sx={{
                                ...buttonSx,
                                borderRadius: "0.5rem",
                                boxShadow: "none",
                            }}>
                            {info.label}
                        </Button>
                    </div>
                </Tooltip>
            ))}
        </ButtonGroup>
    )
}

export default TelehealthButtonBar