import React, { useEffect, useRef, useState } from 'react';

import { iot, mqtt, mqtt5 } from 'aws-iot-device-sdk-v2';

import { useGetCurrentUser } from '../store';
import { useQueryClient } from '@tanstack/react-query';
import { handleIotMessage } from './iot-event-handler';
import { PublishPacket, QoS, SubscribePacket } from 'aws-crt/dist/common/mqtt5_packet';
import { toUtf8 } from '@aws-sdk/util-utf8-browser';
import { ResourceType } from '../types';
import { telehealthStaticCredentialProvider } from './telehealth-static-credential-provider';
import { AuthContext } from '../context/auth-context';

const region = import.meta.env.VITE_AWS_REGION as string;
const websocketEndPoint = import.meta.env.VITE_WEBSOCKET_API_ENDPOINT as string

const channel = 'update/';

export const enum ConnectionStatus {
  NotSet = "NotSet",
  Connected = "Connected",
  Disconnected = "Disconnected",
  Reconnecting = "Reconnecting",
  Stopped = "Stopped"
}

const subPacket: mqtt5.SubscribePacket =
{
  subscriptions: [
    {
      topicFilter: channel + ResourceType.Meetings,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Uploads,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Patients,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Organisation,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Users,
      qos: mqtt.QoS.AtMostOnce,
    }
  ]
}

const unSubPacket: mqtt5.UnsubscribePacket =
{
  topicFilters: [channel + ResourceType.Meetings, channel + ResourceType.Uploads, channel + ResourceType.Patients, channel + ResourceType.Organisation, channel + ResourceType.Users]
}

interface ResultPair {
  statusCode: number;
  message: string;
}

export type ConnectIoTContextType = {
  iotConnected: boolean;
  connectionStatus: ConnectionStatus;
  subscribeToChannel: (topicName: string, handleMessage: (topicName: string, payload: string) => void) => Promise<ResultPair>;
  unsubscribeFromChannel: (topicName: string) => void;
  sendMessage: (topicName: string, payload: string) => void;
};

export const ConnectIoTContext = React.createContext<ConnectIoTContextType>({
  iotConnected: false,
  connectionStatus: ConnectionStatus.Disconnected,
  subscribeToChannel: async () => { return { statusCode: 500, message: 'No connection to subscribe to' } },
  unsubscribeFromChannel: () => { },
  sendMessage: () => { },
})

interface SubscriptionEntry {
  packet: SubscribePacket;
  handleMessage: (topicName: string, payload: string) => void;
}

const ConnectIoT: React.FC<React.PropsWithChildren> = ({ children }) => {

  const [clientConnection, setClientConnection] = useState<mqtt5.Mqtt5Client | null>(null);
  const [lastUserId, setLastUserId] = useState<string | null>(null);
  const [lastOrganisationId, setLastOrganisationId] = useState<string | undefined>(undefined);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.NotSet);

  const [subDict, setSubDict] = useState<{ [key: string]: SubscriptionEntry | undefined }>({});

  const { data: currentUser } = useGetCurrentUser();
  const queryClient = useQueryClient();

  const { assumeIdentity } = React.useContext(AuthContext);

  const iotConnected: boolean = clientConnection != null && connectionStatus === ConnectionStatus.Connected;

  const validConnectionReasonCodes = [mqtt5.SubackReasonCode.GrantedQoS0, mqtt5.SubackReasonCode.GrantedQoS1, mqtt5.SubackReasonCode.GrantedQoS2];

  const onEvent: mqtt5.MessageReceivedEventListener = (eventData: mqtt5.MessageReceivedEvent) => {

    if (!eventData.message.payload) {
      console.warn('No payload', eventData);
      return;
    }

    if (!currentUser) {
      console.log('~~No current user to handle IoT message');
      return;
    }

    const payload = toUtf8(eventData.message.payload as Buffer);

    const topicName = eventData.message.topicName;

    if (subDict[topicName]) {
      subDict[topicName]?.handleMessage(topicName, payload);
      return;
    }

    handleIotMessage(topicName, payload, currentUser, queryClient);
  }

  useEffect(() => {

    const waitToConnect = async (accessKeyId: string, secretKey: string, sessionToken: string, organisationId: string, listener: mqtt5.MessageReceivedEventListener) => {

      if (!accessKeyId || !secretKey || !sessionToken) {
        console.error('No access token or id token');
        return null;
      }

      const credentials = new telehealthStaticCredentialProvider(accessKeyId, secretKey, sessionToken, region);

      const config = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
        websocketEndPoint,
        {
          region: region,
          credentialsProvider: credentials,
        }).build();

      const client = new mqtt5.Mqtt5Client(config);

      client.on('connectionSuccess', () => {
        console.log('Connected to AWS IoT');
        setConnectionStatus(ConnectionStatus.Connected);
      });

      client.on('connectionFailure', (error) => {
        console.error('Failed to connect to AWS IoT', error);
        setConnectionStatus(ConnectionStatus.Disconnected);
      });

      client.on('error', () => {
        console.log('Disconnected from AWS IoT');
        setConnectionStatus(ConnectionStatus.Disconnected);
      });

      client.on('disconnection', () => {
        console.log('Reconnecting to AWS IoT');
        setConnectionStatus(ConnectionStatus.Reconnecting);
      });

      client.on('stopped', () => {
        console.log('Stopped connection to AWS IoT');
        setConnectionStatus(ConnectionStatus.Stopped);
        clientConnection?.close();
      });

      client.on("messageReceived", listener);

      client.start();

      const organisationChimeTopic = channel + ResourceType.Chime + "/" + organisationId;

      const userSubPacket = {
        subscriptions: [
          ...subPacket.subscriptions,
          {
            topicFilter: organisationChimeTopic,
            qos: mqtt.QoS.AtMostOnce,
          }
        ]
      }

      client.subscribe(userSubPacket);

      return client;
    }

    const waitToCreateConnection = async () => {
      const identity = await assumeIdentity()

      if (!identity || !currentUser) {
        console.warn('No identity or current user');
        return;
      }

      if (!identity.accessKeyId || !identity.secretKey || !identity.sessionToken) {
        console.warn('No access token or id token');
        return;
      }

      const connection = await waitToConnect(identity?.accessKeyId, identity?.secretKey, identity?.sessionToken, currentUser?.organisationId, onEvent);

      if (connection != clientConnection) {
        closeConnection(clientConnection, lastOrganisationId, 'Disconnecting from AWS IoT due to connection change');
        setClientConnection(connection || null);
      }

      if (lastUserId !== currentUser?.userId) {
        setLastUserId(currentUser?.userId || null);
        setLastOrganisationId(currentUser?.organisationId);
      }
    }

    if (currentUser && !clientConnection) {
      waitToCreateConnection();
      setConnectionStatus(ConnectionStatus.Reconnecting);
    }

  }, [currentUser, clientConnection]);

  useEffect(() => {
    return () => {
      if (clientConnection) {
        closeConnection(clientConnection, lastOrganisationId, 'Disconnecting from AWS IoT due to unmount');
      }
    }
  }, []);

  const subscribeToChannel = async (topicName: string, handleMessage: (topicName: string, payload: string) => void): Promise<ResultPair> => {
    if (!clientConnection) {
      console.warn('No connection to subscribe to');
      return {
        statusCode: 500,
        message: 'No connection to subscribe to'
      }
    }

    const packet: SubscribePacket = {
      subscriptions: [
        {
          topicFilter: topicName,
          qos: mqtt5.QoS.AtLeastOnce,
        }
      ]
    }

    const subscriptionEntry: SubscriptionEntry = {
      packet,
      handleMessage
    }

    setSubDict(prev => {
      const updated = prev;
      updated[topicName] = subscriptionEntry;
      return updated
    })

    // create a timeout to handle the case where the subscription fails
    const response = await clientConnection.subscribe(packet);

    if (!validConnectionReasonCodes.includes(response.reasonCodes[0])) {
      console.error('~~Subscription failed', response);

      return {
        statusCode: 500,
        message: response.reasonString || 'Subscription failed'
      }
    }

    return {
      statusCode: 200,
      message: 'Subscription successful'
    }
  }

  const sendMessage = (topicName: string, payload: string) => {
    if (!clientConnection) {
      console.warn('No connection to send message');
      return;
    }

    const packet: PublishPacket = {
      topicName,
      qos: QoS.AtLeastOnce,
      payload,
    }

    clientConnection.publish(packet);
  }

  const unsubscribeFromChannel = (topicName: string) => {
    if (!clientConnection) {
      console.warn('~~No connection to unsubscribe from');
      return;
    }

    const packet: mqtt5.UnsubscribePacket = {
      topicFilters: [topicName]
    }

    clientConnection.unsubscribe(packet);

    setSubDict(prev => {
      const updated = prev;
      prev[topicName] = undefined;

      return updated;
    })

    console.log('~~Unsubscribed from', topicName);
  }

  const closeConnection = async (clientConnection: mqtt5.Mqtt5Client | null, organisationId?: string, message?: string) => {
    if (!clientConnection) {
      return;
    }

    if (organisationId) {
      const organisationChimeTopic = channel + ResourceType.Chime + "/" + organisationId;

      const userUnSubPacket = {
        topicFilters: [
          ...unSubPacket.topicFilters,
          organisationChimeTopic
        ]
      }

      clientConnection.unsubscribe(userUnSubPacket);
    }
    else {
      clientConnection.unsubscribe(unSubPacket);
    }

    clientConnection.stop();

    if (message) {
      console.log(message);
    }
  }

  return (
    <ConnectIoTContext.Provider value={{
      subscribeToChannel,
      unsubscribeFromChannel,
      sendMessage,
      iotConnected,
      connectionStatus,
    }}>
      {children}
    </ConnectIoTContext.Provider>
  );
};

export default ConnectIoT