import { useContext, useEffect, useState } from 'react';

import { useLocalVideo, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';

import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import ControlButton from '../control-button';

import SelectCameraPopover from './select-camera-popover';
import { Divider } from '@mui/material';
import { ChimeMessageContext } from '../../context/chime-message-context';
import { MeetingInfoContext } from '../../context/meeting-info-context';

interface CameraProps {
    startWithVideo: boolean;
    showText?: boolean;
    onCameraFound?: (found: string | undefined) => void;
    cameraType?: string;
    subCameraType?: string;
    disabled?: boolean;
    showDropDown?: boolean;
    allowRemoteControl?: boolean;
}

const LabelledCameraButton: React.FC<CameraProps> = ({
    startWithVideo,
    onCameraFound,
    showText,
    cameraType,
    subCameraType,
    disabled = false,
    showDropDown = true,
    allowRemoteControl,
}) => {

    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const status = useMeetingStatus();

    const { userId } = useContext(MeetingInfoContext);
    const { subscribeToChannel, sendMessage } = useContext(ChimeMessageContext);
    const [subscribed, setSubscribed] = useState<boolean>(false);

    const [isWaiting, setIsWaiting] = useState<boolean>(false);

    const [triedStart, setTriedStart] = useState<boolean>(false);

    const onCameraButton = (active: boolean) => {
        if (isVideoEnabled === active) {
            console.log("~~Already in desired state", isVideoEnabled, active);
            return;
        }

        if (status != 1) {
            console.log("~~Not in meeting", status);
            return;
        }

        if (isWaiting) {
            return;
        }

        setIsWaiting(true);
    }

    useEffect(() => {

        if (!allowRemoteControl) {
            return;
        }

        if (subscribed) {
            return;
        }

        if (status != 1) {
            return;
        }

        subscribeToChannel("camera", "labelled-camera-button", (message) => {

            if (message.payload == "join") {
                sendMessage("camera", isVideoEnabled ? "true" : "false", userId);
                return;
            }

            const active = message.payload == "true";
            setIsWaiting(true)
        })

        sendMessage("camera", isVideoEnabled ? "false" : "true", userId);

        setSubscribed(true);

    }, [status]);

    // when button is clicked starts wait for toggle video
    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (!isWaiting) {
            return;
        }

        waitThenToggleVideo();

    }, [isWaiting]);

    const waitThenToggleVideo = async () => {

        if (!isWaiting) {
            return;
        }

        // Ensure that a toggle calls is not made if before the button is finished waiting
        await toggleVideo();

        if (subscribed) {
            sendMessage("camera", isVideoEnabled ? "false" : "true", userId);
        }

        setIsWaiting(false);
    }

    // handles starting with video
    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (triedStart) {
            return;
        }

        onCameraButton(startWithVideo);
        setTriedStart(true);

    }, [startWithVideo, status]);

    return (
        <ControlButton
            disabled={disabled}
            activeIcon={<VideocamIcon color='action' />}
            inactiveIcon={<VideocamOffIcon color='action' />}
            active={isVideoEnabled}
            onClick={onCameraButton}
            activeLabel={"Disable Camera"}
            inactiveLabel={"Enable Camera"}
            showText={showText}>
            <Divider orientation="vertical" />
            {
                showDropDown &&
                <SelectCameraPopover
                    onCameraFound={onCameraFound}
                    cameraType={cameraType}
                    subCameraType={subCameraType} />
            }
        </ControlButton >
    );
};

export default LabelledCameraButton;