import { Autocomplete, Chip, FormControl, Stack, TextField, Typography } from "@mui/material";

interface SelectNameDictionaryProps {
    id: string;
    selectName: string;
    selected: string[];
    requiredIds?: string[];
    options: string[];
    names: Record<string, string>;
    onChange: (value: string[]) => void;
    onAdd?: (value: string, selected: string[]) => void;
    onClose?: () => void;
    createSelectIcon?: (option: string) => JSX.Element;
    error?: string | string[] | undefined
    disabled?: boolean;
    multiple?: boolean;
}

const DictionaryAutocomplete: React.FC<SelectNameDictionaryProps> = ({
    id,
    selectName,
    requiredIds,
    selected,
    options,
    names,
    onChange,
    onAdd,
    onClose,
    createSelectIcon,
    error,
    disabled,
    multiple = true,
}: SelectNameDictionaryProps) => {

    const autoCompleteChange = (event: any, value: any) => {
        const selected = value as string[];

        if (!selected) {
            return;
        }

        let toAdd = undefined;
        if (onAdd) {
            for (const option of selected) {
                if (!options.includes(option)) {
                    toAdd = option;
                }
            }
        }

        if (requiredIds) {
            for (const id of requiredIds) {
                if (!selected.includes(id)) {
                    selected.push(id);
                }
            }
        }

        if (toAdd && onAdd) {
            onAdd(toAdd, selected);
        }
        else {
            onChange(selected);
        }
    }

    const autoCompleteChangeSingle = (event: any, value: any) => {
        const selected = value as string;

        if (!selected) {
            return;
        }

        if (onAdd && !options.includes(selected)) {
            onAdd(selected, [selected]);
        }
        else {
            onChange([selected]);
        }
    }

    const isDisabled = (option: string) => {
        if (disabled) {
            return true;
        }

        if (!requiredIds) {
            return false;
        }

        return requiredIds.includes(option);
    }

    const filter = (options: string[], params: any) => {
        const filtered = options.filter((option) => {
            if (names[option]) {
                return names[option].toLowerCase().includes(params.inputValue.toLowerCase());
            }

            return false;
        });

        return filtered;
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
                options={options}
                multiple={multiple}
                id={id}
                value={
                    multiple ?
                        selected :
                        selected?.length > 0 ? selected[0] : null
                }
                disabled={disabled}
                onChange={multiple ?
                    autoCompleteChange :
                    autoCompleteChangeSingle
                }
                onClose={onClose}
                getOptionLabel={(option: any) => {
                    if (names[option]) {
                        return names[option];
                    }

                    return `Add: ${option}`;
                }}
                getOptionDisabled={(option: string) => {
                    return isDisabled(option);
                }}
                isOptionEqualToValue={(option: any, value) => {
                    return option === value
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    if (!onAdd) {
                        return filtered;
                    }

                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option);
                    if (inputValue.length > 1 && !isExisting) {
                        filtered.push(inputValue);
                    }

                    return filtered;
                }}
                renderInput={(params) => {
                    return (<TextField {...params} label={selectName} />)
                }}
                renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => {

                        let label = option

                        if (names[option]) {
                            label = names[option]
                        }

                        return (
                            <Chip
                                label={label}
                                {...getTagProps({ index })}
                                key={option + "chip"}
                                disabled={isDisabled(option)} />)
                    })
                }
                renderOption={(props, option, { inputValue }) => {
                    let label = option

                    if (names[option]) {
                        label = names[option]
                    }

                    return (
                        <li {...props} key={option}>
                            <Stack direction="row" spacing={2}>
                                {createSelectIcon && createSelectIcon(option)}
                                <Typography>
                                    {label}
                                </Typography>
                            </Stack>
                        </li>
                    )
                }}
            />
            {error &&
                <Typography color="error">
                    {error}
                </Typography>}
        </FormControl>
    )
}

export default DictionaryAutocomplete;