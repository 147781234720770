import { useContext } from "react";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { MeetingInfoContext } from "../../context/meeting-info-context";

import ControlButton from "../control-button";

interface ShowSelfButtonProps {
    disabled?: boolean;
    right: boolean;
    zIndex: number;
}

const ShowSelfButton: React.FC<ShowSelfButtonProps> = ({
    disabled = false,
    right,
    zIndex,
}) => {

    const { showSelf, setShowSelf } = useContext(MeetingInfoContext);

    const toggleShowSelf = () => {
        setShowSelf(!showSelf);
    }

    return (
        <div style={{
            position: "absolute",
            backdropFilter: `blur(20px)`,
            backgroundColor: `rgba(46, 47, 52, 0.85)`,
            borderRadius: `0.25rem`,
            color: `white`,
            bottom: "0.5rem",
            left: right ? `` : "0.5rem",
            right: right ? `0.5rem` : ``,
            width: "fit-content",
            zIndex: zIndex,
            justifySelf: "start",
        }}>
            <ControlButton
                onClick={toggleShowSelf}
                disabled={disabled}
                activeIcon={<VisibilityIcon color='action' />}
                inactiveIcon={<VisibilityOffIcon color='action' />}
                showText={false}
                active={showSelf}
                activeLabel={"Hide Self"}
                inactiveLabel={"Show Self"} />
        </div>
    );
}

export default ShowSelfButton;