import { Dispatch, SetStateAction, useContext } from "react"

import { v4 as uuidv4 } from "uuid";

import {
  Dialog,
  Stack,
  Typography,
} from "@mui/material"

import { Meeting, MeetingStatus, Organisation } from "../../types"

import toast from "react-hot-toast";

import { useAddMeeting } from "../../store";
import { AuthContext } from "../../context/auth-context";

import CancelButton from "../cancel-button"
import MeetingForm from "./meeting-form"

interface AddMeetingModalProps {
  open: boolean
  useStartDateInForm: boolean
  startTime?: Date
  patientId?: string
  userOrganisation?: Organisation | null
  handleClose: Dispatch<SetStateAction<void>>
}

const AddMeetingModal: React.FC<AddMeetingModalProps> = ({
  open,
  useStartDateInForm,
  startTime = new Date(),
  patientId = "",
  userOrganisation,
  handleClose,
}: AddMeetingModalProps) => {

  const toastMeetingSuccess = () => {
    toast.success("Meeting successfully added")
  }

  const toastMeetingError = () => {
    toast.error("Error adding meeting")
  }

  const { userId, lastSiteId } = useContext(AuthContext);

  const { mutate: addMeeting } = useAddMeeting(toastMeetingSuccess, toastMeetingError);

  const meetingName = startTime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })

  const siteIds = []

  if (lastSiteId != "all") {
    siteIds.push(lastSiteId)
  }
  else if (userOrganisation && userOrganisation.siteIds.length > 0) {
    siteIds.push(userOrganisation.siteIds[0])
  }

  const meetingToAdd: Meeting = {
    meetingId: uuidv4(),
    creatorId: userId,
    meetingName: meetingName,
    startTime: startTime,
    endTime: new Date(startTime.getTime() + 30 * 60000),
    chimeJoinId: "",
    attendeesId: [],
    confirmedAttendeesId: [],
    joinedIds: [],
    meetingType: MeetingStatus.Scheduled,
    patientId: patientId,
    organisationIds: [],
    siteIds,
  }

  const onClose = () => {
    handleClose()
  }

  const addMeetingAndClose = (meeting: Meeting) => {
    handleClose()
    addMeeting(meeting)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <CancelButton handleClose={onClose} />
      <Stack
        padding={2}>
        <MeetingForm
          useStartDateInForm={useStartDateInForm}
          handleClose={onClose}
          meeting={meetingToAdd}
          addMeeting={addMeetingAndClose} />
      </Stack>
    </Dialog >
  )
}

export default AddMeetingModal
