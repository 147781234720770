import React from "react";

import { Stack, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import { Meeting, Organisation, Site } from "../../types";
import TelehealthIconButton from "../telehealth-icon-button";
import SelectSite from "../site/select-site";
import { useGetCurrentUser, useListOrganisations, useListSites } from "../../store";
import { checkSiteList } from "./meeting-form";

interface EditSitesListProps {
    siteIds: string[],
    organisationIds: string[],
    canEdit: boolean,
    updateMeeting: (siteIds: string[]) => void
}

const EditSitesList: React.FC<EditSitesListProps> = ({
    siteIds,
    organisationIds,
    canEdit,
    updateMeeting,
}: EditSitesListProps) => {

    const { data: sites, isLoading: loadingSites } = useListSites();
    const { data: organisations, isLoading: loadingOrgs } = useListOrganisations();
    const { data: currentUser, isLoading: loadingUser } = useGetCurrentUser();

    const loading = loadingSites || loadingOrgs || loadingUser

    const siteDictionary: Record<string, Site> = {};

    if (sites) {
        for (let site of sites) {
            siteDictionary[site.siteId] = site;
        }
    }

    const organisationDictionary: Record<string, Organisation> = {};

    if (organisations) {
        for (let organisation of organisations) {
            organisationDictionary[organisation.organisationId] = organisation;
        }
    }

    const [editInProgress, setEditInProgress] = React.useState(false)
    const [selectedSites, setSelectedSites] = React.useState<string[]>(siteIds || [])

    const changeEdit = () => {
        setSelectedSites(siteIds)
        setEditInProgress(!editInProgress)
    }

    const siteChangeEvent = (siteIds: string[]) => {
        setSelectedSites(siteIds)
    }

    const saveChanges = () => {
        updateMeeting(selectedSites)
        setEditInProgress(false)
    }

    let disabled = false
    let sameSiteCount = 0
    const currentOrgList: string[] = []

    for (let siteId of selectedSites) {
        const site = siteDictionary[siteId]

        if (siteIds.includes(siteId)) {
            sameSiteCount++
        }

        if (!site) {
            continue
        }

        for (let orgId of site.linkedOrganisations) {
            if (!currentOrgList.includes(orgId)) {
                currentOrgList.push(orgId)
            }
        }
    }


    const siteChange = Boolean(sameSiteCount == siteIds.length && selectedSites.length == siteIds.length)

    disabled = siteChange

    const error = checkSiteList(organisationIds, selectedSites, currentOrgList, currentUser)

    let saveTooltip = siteChange ? "No changes to save" : "Save changes"

    if (error) {
        saveTooltip = "Fix errors before saving"
        disabled = true
    }


    return (
        <Stack
            direction={"column"}
            spacing={1}
            alignItems={"center"}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                width={"100%"}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                    sx={{
                        paddingX: "0.5rem",
                    }}>
                    <Typography variant={"h5"}>
                        Attendees:
                    </Typography>
                    {canEdit &&
                        <TelehealthIconButton
                            icon={
                                editInProgress ?
                                    <CancelIcon /> :
                                    <EditIcon />}
                            onClick={changeEdit}
                            disabled={loading}
                            tooltip={
                                editInProgress ?
                                    "Cancel edit" :
                                    "Edit meeting time"} />}
                </Stack>
            </Stack>
            {editInProgress &&
                <Stack direction={"row"}
                    spacing={2}
                    width={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}>
                    <SelectSite
                        id={"siteIds"}
                        sites={sites}
                        organisationDictionary={organisationDictionary}
                        isLoading={loading}
                        selectedSiteId={selectedSites}
                        onChange={siteChangeEvent}
                        error={error}
                    />
                    <TelehealthIconButton
                        onClick={saveChanges}
                        disabled={disabled}
                        color="primary"
                        icon={<SaveIcon />}
                        tooltip={saveTooltip}
                    />
                </Stack>}
        </Stack>
    )
}

export default EditSitesList
