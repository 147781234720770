import React, { PropsWithChildren, useContext, useEffect } from "react";
import { MeetingInfoContext } from "./meeting-info-context";
import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { DataMessage } from "amazon-chime-sdk-js";
import { MeetingJoinType } from "../types";
import toast from "react-hot-toast";

export interface ChimeMessage {
    userId: string;
    targetId?: string;
    meetingType: MeetingJoinType;
    topic: string;
    payload: string;
}

export type ChimeMessageContextType = {
    subscribeToChannel: (topic: string, subId: string, handleMessage: (topicName: ChimeMessage) => void) => boolean;
    subscribeToJoinMessage: (subId: string, handleMessage: (topicName: ChimeMessage) => void) => boolean;
    unsubscribeFromChannel: (topic: string, subId: string) => void;
    unsubscribeFromJoinMessage: (subId: string) => void;
    sendMessage: (topic: string, payload: string, target: string) => void;
}

export const ChimeMessageContext = React.createContext<ChimeMessageContextType>({
    subscribeToChannel: (topic: string, subId: string, handleMessage: (topicName: ChimeMessage) => void) => false,
    subscribeToJoinMessage: (subId: string, handleMessage: (topicName: ChimeMessage) => void) => false,
    unsubscribeFromChannel: (topic: string, subId: string) => { },
    unsubscribeFromJoinMessage: (subId: string) => { },
    sendMessage: (topic: string, payload: string, target?: string) => { }
});

interface SubscriptionEntry {
    [key: string]: (topicName: ChimeMessage) => void;
}


const ChimeMessageProvider: React.FC<PropsWithChildren> = ({
    children
}) => {

    const { userId, meetingType, userName } = useContext(MeetingInfoContext);


    const subscriptions = React.useRef<{ [key: string]: SubscriptionEntry | undefined }>({});
    const joinSubscription = React.useRef<SubscriptionEntry>({});

    const [listening, setListening] = React.useState(false);

    const meetingManager = useMeetingManager();

    const messageName = "remoteButton";
    const joinName = "buttonJoin";

    const subscribeToChannel = (topic: string, subId: string, handleMessage: (topicName: ChimeMessage) => void): boolean => {

        if (!subscriptions.current) {
            subscriptions.current = {};
        }

        if (!subscriptions.current[topic]) {
            subscriptions.current[topic] = { [subId]: handleMessage };
            return true;
        }

        subscriptions.current[topic]![subId] = handleMessage;

        console.log("~~Subscribed to channel", topic);

        return true;
    }

    const unsubscribeFromChannel = (topic: string, subId: string): void => {
        if (!subscriptions.current) {
            return;
        }

        if (!subscriptions.current[topic]) {
            return;
        }

        delete subscriptions.current[topic]![subId];
    }

    const subscribeToJoinMessage = (subId: string, handleMessage: (topicName: ChimeMessage) => void): boolean => {

        if (!joinSubscription.current) {
            joinSubscription.current = {};
        }

        joinSubscription.current[subId] = handleMessage;

        return true;
    }

    const unsubscribeFromJoinMessage = (subId: string): void => {
        if (!joinSubscription.current) {
            return;
        }

        delete joinSubscription.current[subId];
    }

    const sendMessage = (topic: string, payload: string, targetId?: string): void => {

        if (!meetingManager.audioVideo) {
            console.warn("~~ tried to send message without audioVideo");
            return;
        }

        if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
            console.warn("~~ tried to send message before joined meeting");
            return;
        }

        const message: ChimeMessage = {
            userId,
            targetId,
            meetingType,
            topic,
            payload
        }

        meetingManager.audioVideo.realtimeSendDataMessage(messageName, JSON.stringify(message));
    }

    useEffect(() => {
        const receiveMessage = (parsedMessage: ChimeMessage, subscriptionSet: SubscriptionEntry | undefined) => {
            if (!subscriptionSet) {
                console.log("~~No subscriptions for topic", parsedMessage.topic);
                return;
            }

            if (parsedMessage.targetId && parsedMessage.targetId.length > 0 && parsedMessage.targetId !== userId) {
                console.log("~~Message not for me", parsedMessage.topic, userId);
                return;
            }

            Object.values(subscriptionSet).forEach((entry) => {
                entry(parsedMessage);
            });
        }

        if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
            return;
        }

        if (!meetingManager.audioVideo) {
            return;
        }

        console.log("~~Subscribing to message", userId);

        meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage(messageName, (message: DataMessage) => {
            const parsedMessage: ChimeMessage = JSON.parse(message.text());

            if (!parsedMessage.topic) {
                console.warn("~~Received message without topic", parsedMessage);
                return;
            }

            if (!subscriptions.current) {
                console.warn("~~Received message without subscriptions", parsedMessage);
                return;
            }

            const subscriptionSet = subscriptions.current[parsedMessage.topic];

            receiveMessage(parsedMessage, subscriptionSet);
        });
        meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage(joinName, (message: DataMessage) => {
            const parsedMessage = JSON.parse(message.text());

            let sentUserName = parsedMessage.payload;
            if (parsedMessage.meetingType != MeetingJoinType.Metro && parsedMessage.meetingType != MeetingJoinType.Remote) {
                sentUserName = `${parsedMessage.payload} (${parsedMessage.meetingType})`;
            }

            toast.success(`${sentUserName} joined meeting`, { duration: 5000 });

            if (!joinSubscription.current) {
                return;
            }

            receiveMessage(parsedMessage, joinSubscription.current);
        });

        if (listening) {
            return;
        }

        //send join message
        const message: ChimeMessage = {
            userId,
            meetingType,
            topic: "join",
            payload: userName,
        }

        meetingManager.audioVideo.realtimeSendDataMessage(joinName, JSON.stringify(message));

        setListening(true);

        return () => {
            if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
                return;
            }

            if (!meetingManager.audioVideo) {
                return;
            }

            console.log("~~Unsubscribing from message", userId);
            meetingManager.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(messageName);
            meetingManager.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(joinName);
        }

    }, [meetingManager.meetingStatus, meetingManager.audioVideo]);

    return (
        <ChimeMessageContext.Provider value={{
            subscribeToChannel,
            subscribeToJoinMessage,
            unsubscribeFromChannel,
            unsubscribeFromJoinMessage,
            sendMessage,
        }}>
            {children}
        </ChimeMessageContext.Provider>
    );
}

export default ChimeMessageProvider;
