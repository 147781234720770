import { Card, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { Meeting } from "../../types";

import MeetingTableRow from "./meeting-table-row";
import TelehealthStyledButton from "../telehealth-styled-button";

interface MeetingTableProps {
    isLoading: boolean;
    meetings: Meeting[];
    showPatientId?: boolean;
    addButtonDisabled?: boolean;
    addMeetingClick?: () => void;
    canEdit?: boolean;
    canDelete?: boolean;
    updateClick?: (Meeting: Meeting) => void;
    deleteClick?: (Meeting: Meeting) => void;
}

const MeetingTable: React.FC<MeetingTableProps> = ({
    isLoading,
    meetings,
    showPatientId,
    addButtonDisabled,
    addMeetingClick,
    canEdit,
    canDelete,
    updateClick,
    deleteClick,
}) => {

    meetings.sort((a, b) => {

        if (a.startTime === b.startTime) {
            return 0;
        }

        if (a.startTime === null) {
            return -1;
        }

        if (b.startTime === null) {
            return 1;
        }

        if (a.startTime === b.startTime) {
            return 0;
        }

        if (a.startTime > b.startTime) {
            return -1;
        }

        return 1;
    });

    return (
        <Card sx={{
            padding: 1,
        }}>
            <TableContainer component={Stack}>
                <Stack
                    direction="row"
                    padding={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Typography variant="h4">
                        Meetings
                    </Typography>
                    {addMeetingClick &&
                        <TelehealthStyledButton
                            onClick={addMeetingClick}
                            variant="contained"
                            label="Add Meeting"
                            showText={true}
                            disabled={addButtonDisabled}
                            icon={
                                <AddIcon />
                            }
                        />}
                </Stack>
                <Table
                    sx={{ width: "100%" }}
                    aria-label="Meeting table">
                    <TableHead>
                        <TableRow>
                            {showPatientId &&
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Patient
                                    </Typography>
                                </TableCell>
                            }
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Status
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Scheduled Time
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Actual Time
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Attendees
                                </Typography>
                            </TableCell>
                            {(deleteClick || updateClick) &&
                                <TableCell align="right">
                                    <Typography variant="body1" fontSize={16}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Skeleton variant="rectangular" height={50} />
                                </TableCell>
                            </TableRow>}
                        {!isLoading && meetings.map((meeting) => {
                            return (
                                <TableRow key={meeting.meetingId}>
                                    <MeetingTableRow
                                        meeting={meeting}
                                        showPatientId={showPatientId}
                                        canEdit={canEdit}
                                        canDelete={canDelete}
                                        deleteClick={deleteClick}
                                        updateClick={updateClick}
                                    />
                                </TableRow>
                            );
                        })}
                        {!isLoading && meetings.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={6} sx={{
                                    paddingY: 2,
                                }}>
                                    <Typography variant="body1">
                                        No meetings found
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}

export default MeetingTable;