import React from "react";

import { Stack } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import { Meeting, Organisation, User } from "../../types"

import { useGetSiteByIds } from "../../store";

import TelehealthIconButton from "../telehealth-icon-button";
import EditAttendees from "./edit-attendees";
import OrganisationTag from "./organisation-tag";
import UserTagList from "./user-tag-list";

interface EditOrganisationTagProps {
    organisation?: Organisation
    meeting: Meeting
    invitedUsers: User[]
    canJoin: boolean
    canEdit: boolean
    compact?: boolean
}

const EditOrganisationTag: React.FC<EditOrganisationTagProps> = ({
    organisation,
    meeting,
    invitedUsers,
    canEdit,
    canJoin,
    compact = false
}) => {

    const { data: sites } = useGetSiteByIds(meeting.siteIds)

    const [editInProgress, setEditInProgress] = React.useState(false)

    const onClick = () => {
        setEditInProgress(!editInProgress)
    }

    const closeEdit = () => {
        setEditInProgress(false)
    }

    return (
        <OrganisationTag
            organisation={organisation}
            canJoin={canJoin}
            attendees={invitedUsers?.length || 0}
            sites={sites || []}>
            {!compact &&
                <Stack
                    spacing={2}
                    padding={1}
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignContent={"center"}>
                    {!editInProgress &&
                        <UserTagList
                            meeting={meeting}
                            organisation={organisation}
                            invitedUsers={invitedUsers}
                            canJoin={canJoin}
                        />}
                    {editInProgress &&
                        <EditAttendees
                            organisationIds={[organisation?.organisationId || ""]}
                            meeting={meeting}
                            onFinish={closeEdit}
                        />}
                    {canEdit &&
                        <TelehealthIconButton
                            icon={
                                editInProgress ?
                                    <CancelIcon /> :
                                    <EditIcon />}
                            onClick={onClick}
                            tooltip={
                                editInProgress ?
                                    "Cancel edit" :
                                    "Edit attendees from your organisation"} />}
                </Stack>}
        </OrganisationTag>
    )
}

export default EditOrganisationTag