import { useEffect, useState } from 'react';

import { isOptionActive, useLocalVideo, useMeetingManager, useMeetingStatus, useVideoInputs } from 'amazon-chime-sdk-component-library-react';

import { CustomPopOverItemProps } from '../telehealth-popover';
import TelehealthPopover from '../telehealth-popover';

interface CameraProps {
    cameraType?: string;
    subCameraType?: string;
    onCameraFound?: (found: string | undefined) => void;
}

const SelectCameraPopover: React.FC<CameraProps> = ({
    cameraType,
    subCameraType,
    onCameraFound,
}) => {

    const meetingManager = useMeetingManager();
    const { devices, selectedDevice } = useVideoInputs();
    const { isVideoEnabled } = useLocalVideo();

    const status = useMeetingStatus();

    const [videoOptions, setVideoOptions] = useState<CustomPopOverItemProps[]>([]);

    const [enabledBefore, setEnabledBefore] = useState<boolean>(false);

    // handles starting with video
    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (!isVideoEnabled) {
            return;
        }

        if (enabledBefore || !cameraType) {
            return;
        }

        cameraType = cameraType.toLowerCase();

        if (subCameraType) {
            subCameraType = subCameraType.toLowerCase();
        }

        let foundCamera: string | undefined = undefined;
        let foundCameraName: string | undefined = undefined;
        for (let i = 0; i < devices.length; i++) {
            const lowerCaseName = devices[i].label.toLowerCase();
            if (!lowerCaseName.includes(cameraType)) {
                continue;
            }

            foundCamera = devices[i].deviceId;
            foundCameraName = devices[i].label;

            if (!subCameraType) {
                break;
            }

            if (lowerCaseName.includes(subCameraType)) {
                break;
            }
        }

        if (foundCamera) {
            changeSelectedOption(foundCamera);
        }

        if (onCameraFound) {
            onCameraFound(foundCameraName);
        }

        setEnabledBefore(true);

    }, [isVideoEnabled]);

    const onSelectChanged = (selectedOption: CustomPopOverItemProps) => {
        changeSelectedOption(selectedOption.id);
        console.log("~~Selected Option: " + selectedOption);
    }

    const changeSelectedOption = async (deviceId: string) => {
        try {
            if (isVideoEnabled) {
                await meetingManager.startVideoInputDevice(deviceId);
            } else {
                meetingManager.selectVideoInputDevice(deviceId);
            }
        } catch (error) {
            console.log('VideoInputControl failed to select video input device');
        }
    }

    // Handles drop down menu options
    useEffect(() => {
        const alternateGetDropDown = async () => {
            const deviceList: CustomPopOverItemProps[] = [];

            devices.map(async (device) => {

                const popoverItem: CustomPopOverItemProps = {
                    label: device.label,
                    id: device.deviceId,
                    active: await isOptionActive(device, device.deviceId)
                };

                deviceList.push(popoverItem);
            });

            setVideoOptions(deviceList);
        }

        alternateGetDropDown();
    }, [
        devices,
        selectedDevice,
        isVideoEnabled,
        meetingManager,
        meetingManager.startVideoInputDevice,
    ]);

    return (
        <TelehealthPopover
            options={videoOptions}
            selectedOption={selectedDevice?.toString() || ""}
            setSelectedOption={onSelectChanged}
            openPopOverLabel='Select Camera'
        />
    );
};

export default SelectCameraPopover;