import React from "react";

import { Stack, TableCell, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import EventIcon from '@mui/icons-material/Event';

import { Meeting, getEventStatus, canMeetingBeEdited, canJoin } from "../../types";

import TelehealthIconButton from "../telehealth-icon-button";
import CustomTag from "../custom-tag";
import AttendeeOrganisationGrid from "../calendar/attendee-organisation-grid";
import { useGetCurrentUser } from "../../store";

interface MeetingTableRowProps {
    meeting: Meeting;
    showPatientId?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    deleteClick?: (Meeting: Meeting) => void;
    updateClick?: (Meeting: Meeting) => void;
}

const MeetingTableRow: React.FC<MeetingTableRowProps> = ({
    meeting,
    showPatientId,
    canEdit = false,
    canDelete = false,
    deleteClick,
    updateClick,
}) => {

    const { data: currentUser } = useGetCurrentUser();

    const canEditMeeting = canMeetingBeEdited(meeting, currentUser?.userId || "", currentUser?.organisationId || "");

    if (!canEditMeeting.canEdit) {
        canEdit = false;
        canDelete = false;
    }

    const handleDeleteMeeting = () => {

        if (!deleteClick) {
            return;
        }

        deleteClick(meeting);
    }

    const handleUpdateMeeting = () => {

        if (!updateClick) {
            return;
        }

        updateClick(meeting);
    }

    const dateTimeString = (date: Date) => {

        if (!date) {
            return "???";
        }

        return date.toLocaleDateString() + " " + date.toLocaleTimeString("en-AU", { hour: '2-digit', minute: '2-digit' });
    }

    const getActualTimeStrings = (meeting: Meeting) => {

        if (meeting.realStartTime) {

            if (meeting.realEndTime) {
                return {
                    start: dateTimeString(meeting.realStartTime),
                    end: dateTimeString(meeting.realEndTime),
                }
            }

            const end: string = canJoin(meeting) ? "Meeting in progress." : "NA";

            return {
                start: dateTimeString(meeting.realStartTime),
                end: end,
            }
        }

        if (meeting.startTime > new Date()) {
            return {
                start: "Meeting has not started.",
                end: "",
            }
        }

        return {
            start: "NA",
            end: "NA",
        }
    }

    const { start, end } = getActualTimeStrings(meeting);

    return (
        <>
            {showPatientId &&
                <TableCell align="left">
                    <CustomTag
                        text={meeting.patientId || ""}
                        color="#e6e6e6"
                        sx={{
                            height: "fit-content",
                            width: "fit-content",
                        }} />
                </TableCell>
            }
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {getEventStatus(meeting, [])}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Stack
                    direction="column"
                    spacing={1}
                    width={"fit-content"}
                    minWidth={200}>
                    <Typography variant="body1" fontSize={16}>
                        {dateTimeString(meeting.startTime)}
                    </Typography>
                    <Typography variant="body1" fontSize={16}>
                        {dateTimeString(meeting.endTime)}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">
                <Stack
                    direction="column"
                    spacing={1}
                    width={"fit-content"}
                    minWidth={200}>
                    {start.length > 0 &&
                        <Typography variant="body1" fontSize={16}>
                            {start}
                        </Typography>}
                    {end.length > 0 &&
                        <Typography variant="body1" fontSize={16}>
                            {end}
                        </Typography>}
                </Stack>
            </TableCell>
            <TableCell align="left">
                <AttendeeOrganisationGrid
                    compact
                    meeting={meeting}
                    direction="row" />
            </TableCell>
            <TableCell align="right">
                <Stack direction="row" spacing={2} justifyContent={"right"}>
                    {updateClick &&
                        <TelehealthIconButton
                            icon={<EventIcon />}
                            color="info"
                            tooltip="View meeting details"
                            inactiveTooltip="Cannot view/edit meeting details"
                            onClick={handleUpdateMeeting} />
                    }
                    {deleteClick &&
                        <TelehealthIconButton
                            icon={<CancelIcon />}
                            color="error"
                            tooltip="Cancel Meeting"
                            inactiveTooltip="Cannot cancel this Meeting"
                            disabled={!canDelete}
                            onClick={handleDeleteMeeting} />
                    }
                </Stack>
            </TableCell>
        </>
    );
}

export default MeetingTableRow;