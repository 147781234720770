import React, { PropsWithChildren, useEffect } from "react";

import UploadFileIcon from '@mui/icons-material/UploadFile';

import toast from 'react-hot-toast';

import ControlButton, { ControlButtonProps } from "../control-button";


interface UploadFileProps extends ControlButtonProps {
    capture: boolean;
    accept?: string;
    onFile: (file: File | null) => void;
    reportCancel?: () => void;
}

const UploadFileButton: React.FC<PropsWithChildren<UploadFileProps>> = ({
    capture,
    accept = "image/*",
    onFile,
    onClick,
    active,
    reportCancel,
    children,
    ...rest
}) => {

    const imageUploader = React.useRef<HTMLInputElement | null>(null);

    const handleImageUpload = (e: any) => {

        const [file] = e.target.files;
        if (!file) {
            console.log("No file uploaded");
            return;
        }

        if (accept === "image/*") {
            if (!checkIfValidImage(file)) {
                toast.error("File is not an image");
                return;
            }
        }
        else {
            const extension = file.name.split('.').pop();

            if (`.${extension}` !== accept) {
                toast.error(`File is not a ${accept} file`);
                return;
            }
        }

        onFile(file);
    };

    const checkIfValidImage = (file: File) => {
        if (!file.type.match("image.*")) {
            console.log("File is not an image");

            const extension = file.name.split('.').pop();

            if (extension !== "ply") {
                return false;
            }
            else {
                console.log("File is a ply file");
            }
        }

        return true;
    }

    const onUploaderClick = () => {

        if (!imageUploader.current) {
            return;
        }

        if (onClick) {
            onClick(!active);
        }

        //set selected input item null
        imageUploader.current.value = "";
        imageUploader.current.click();
    }

    useEffect(() => {
        if (!imageUploader.current) {
            return;
        }

        imageUploader.current.addEventListener("cancel", onCancel);

        // add event listener for file input change
        imageUploader.current.addEventListener("change", onInputChanged);

        // remove event listener when component unmounts
        return () => {
            console.log("~~~ Image uploader unmounted ~~~");
            if (!imageUploader.current) {
                return;
            }

            imageUploader.current.removeEventListener("cancel", onCancel);
            imageUploader.current.removeEventListener("change", onInputChanged);
        };

    }, [imageUploader]);

    const onInputChanged = (e: any) => {
        console.log("~~~ Image uploader changed ~~~", e);
    }

    const onCancel = (event: Event) => {
        console.log("~~~ Image uploader cancelled ~~~", event);
        if (reportCancel) {
            reportCancel();
        }

        toast.error("Upload cancelled");
    }

    return (
        <ControlButton
            {...rest}
            active={active}
            activeIcon={<UploadFileIcon color="action" />}
            inactiveIcon={<UploadFileIcon color="action" />}
            onClick={onUploaderClick}
        >
            <input
                title="Upload Photo"
                type="file"
                accept={accept}
                capture={capture}
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                    display: "none"
                }} />
            {children}
        </ControlButton>
    );
}

export default UploadFileButton;