import React from "react"

import { Dialog, Stack } from "@mui/material"

import toast from "react-hot-toast"

import { canJoin, canMeetingBeEdited, canUserEditMeetings, Meeting, Organisation, User } from "../../types"
import { useUpdateMeeting } from "../../store"

import CancelButton from "../cancel-button"
import MeetingInfo from "./meeting-info"
import MeetingForm from "./meeting-form"

interface MeetingInfoProps {
  open: boolean
  handleClose: () => void
  meeting: Meeting
  currentUser: User | null | undefined
  userOrganisation?: Organisation | null
}

const MeetingInfoModal: React.FC<MeetingInfoProps> = ({
  open,
  handleClose,
  meeting,
  currentUser,
  userOrganisation,
}: MeetingInfoProps) => {

  const onClose = () => {
    setEditInProgress(false)
    handleClose()
  }

  const toastMeetingUpdateSuccess = () => {
    toast.success("Meeting updated successfully")
  }

  const toastMeetingUpdateFailure = () => {
    toast.error("Meeting update failed")
  }

  const { mutate: updateMeeting } = useUpdateMeeting(toastMeetingUpdateSuccess, toastMeetingUpdateFailure)

  const [editInProgress, setEditInProgress] = React.useState(false)

  const canUserEdit = canUserEditMeetings(currentUser, userOrganisation);
  const meetingCanBeEdited = canMeetingBeEdited(meeting, currentUser?.userId || "", userOrganisation?.organisationId || "");
  const canJoinMeeting = canJoin(meeting);

  const showEdit = canUserEdit.canEdit && meetingCanBeEdited.canEdit && !canJoinMeeting;

  const handleEditClose = () => {
    setEditInProgress(false)
  }

  const openEdit = () => {
    setEditInProgress(true)
  }

  const updateAndClose = (meeting: Meeting) => {
    updateMeeting(meeting);
    setEditInProgress(false);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <Stack
        width={"30vw"}
        padding={2}>
        <CancelButton handleClose={onClose} />
        {editInProgress &&
          <MeetingForm
            useStartDateInForm={true}
            handleClose={handleEditClose}
            meeting={meeting}
            addMeeting={updateAndClose}
            update={true} />}
        {!editInProgress &&
          <MeetingInfo
            meeting={meeting}
            canEdit={false}
            currentUser={currentUser}
            userOrganisation={userOrganisation}
            showEditButton={showEdit}
            editButtonClick={openEdit} />}
      </Stack>
    </Dialog >
  )
}

export default MeetingInfoModal
