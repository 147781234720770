import { Button, ButtonGroup, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

import format from "date-fns/format"

import { View } from "react-big-calendar"

import TelehealthStyledButton from "../telehealth-styled-button"
import TrainingMeetingButton from "./training-meeting-button";
import { Organisation, OrganisationType } from "../../types";
import SiteDropDown from "./site-drop-down";
import TelehealthButtonBar from "../telehealth-button-bar";

interface CalendarButtonBarProps {
    view: View
    setView: (view: View) => void
    date: Date
    setDate: (date: Date) => void
    addButtonClick: () => void
    organisation?: Organisation | null | undefined
}

const CalendarButtonBar: React.FC<CalendarButtonBarProps> = ({
    view,
    setView,
    date,
    setDate,
    addButtonClick,
    organisation,
}) => {

    const organisationType = organisation ? organisation.type : OrganisationType.None

    const getDateLabel = (date: Date, view: View) => {
        if (view == "month") {
            return format(date, "MMMM yyyy")
        }

        if (view == "week") {
            return format(date, "dd MMMM yyyy")
        }

        if (view == "agenda") {

            const thirtyDaysLaterDate = new Date(date)
            thirtyDaysLaterDate.setDate(thirtyDaysLaterDate.getDate() + 30)
            const dateString = `${format(date, "dd MMMM yyyy")} - ${format(thirtyDaysLaterDate, "dd MMMM yyyy")}`
            return dateString
        }

        return ""
    }

    const dateLabel = getDateLabel(date, view)

    const setDateToNow = () => {
        setDate(new Date())
    }

    const setDateNext = () => {

        if (view == "month") {
            setDate(new Date(date.setMonth(date.getMonth() + 1)))
        }

        if (view == "week") {
            setDate(new Date(date.setDate(date.getDate() + 7)))
        }

        if (view == "agenda") {
            setDate(new Date(date.setDate(date.getDate() + 30)))
        }
    }

    const setDatePrev = () => {

        if (view == "month") {
            setDate(new Date(date.setMonth(date.getMonth() - 1)))
        }

        if (view == "week") {
            setDate(new Date(date.setDate(date.getDate() - 7)))
        }

        if (view == "agenda") {
            setDate(new Date(date.setDate(date.getDate() - 30)))
        }

    }

    const setDateById = (id: string) => {
        if (id == "next") {
            setDateNext()
        }

        if (id == "prev") {
            setDatePrev()
        }

        if (id == "today") {
            setDateToNow()
        }
    }

    const getNavigationTooltip = (id: string) => {

        if (id == "next") {
            return view === "week" ? "Go to next week" : "Go to next month"
        }

        if (id == "prev") {
            return view === "week" ? "Go to previous week" : "Go to previous month"
        }

        if (id == "today") {
            return "Go to today"
        }

        return ""
    }

    const getTooltipForView = (tooltipView: View) => {

        if (tooltipView === view) {
            return "Current view"
        }

        if (tooltipView == "month") {
            return "Switch to month view"
        }

        if (tooltipView == "week") {
            return "Switch to week view"
        }

        if (tooltipView == "agenda") {
            return "Switch to agenda view"
        }

        return ""
    }

    const dateIsToday = date.toDateString() === new Date().toDateString()

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            spacing={1}>
            <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"55%"}>
                <TelehealthButtonBar
                    labels={[
                        {
                            label: "Prev",
                            id: "prev",
                            tooltip: getNavigationTooltip("prev"),
                        },
                        {
                            label: "Today",
                            id: "today",
                            tooltip: getNavigationTooltip("today"),
                            disabled: dateIsToday
                        },
                        {
                            label: "Next",
                            id: "next",
                            tooltip: getNavigationTooltip("next"),
                        }
                    ]}
                    onClick={setDateById}
                    buttonSx={{
                        height: "50px",
                    }}
                    variant={"contained"} />
                <Typography variant={"h6"}>{dateLabel}</Typography>
            </Stack>
            <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                justifyItems={"center"}
                width={"fit-content"}>
                {organisationType === OrganisationType.Metro &&
                    <TelehealthStyledButton
                        onClick={addButtonClick}
                        label="Add Meeting"
                        showText={true}
                        icon={<AddIcon />}
                        variant="contained"
                        spacing={0.5}
                        sx={{
                            paddingY: "0",
                        }}
                        childStackSx={{
                            minWidth: "140px",
                            height: "50px",
                            justifyContent: "space-evenly",
                        }}
                    />}
                {organisationType === OrganisationType.Remote &&
                    <TrainingMeetingButton
                        disabled={false} />}
                <TelehealthButtonBar
                    labels={[
                        {
                            label: "Month",
                            id: "month",
                            tooltip: getTooltipForView("month"),
                            disabled: Boolean(view === "month")
                        },
                        {
                            label: "Week",
                            id: "week",
                            tooltip: getTooltipForView("week"),
                            disabled: Boolean(view === "week")
                        },
                        {
                            label: "Agenda",
                            id: "agenda",
                            tooltip: getTooltipForView("agenda"),
                            disabled: Boolean(view === "agenda")
                        }
                    ]}
                    onClick={(id) => setView(id as View)}
                    buttonSx={{
                        height: "50px",
                    }}
                    variant={"contained"} />
                <SiteDropDown
                    organisation={organisation}
                    showLabel={false} />
            </Stack>
        </Stack>
    )
}

export default CalendarButtonBar