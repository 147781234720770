import React, { useEffect } from "react";

import { Stack } from "@mui/material";

import { ConnectIoTContext } from "../websocket/connect-iot";
import { MeetingInfoContext } from "../context/meeting-info-context";

import AnnotationGrid from "./annotation-grid";
import AnnotationControlBar from "./annotation-control-bar";

export enum MessageType {
    Annotation = "annotation",
    Clear = "clear",
    Undo = "undo",
    RequestPoints = "request-points",
    SendPoints = "send-points",
    Color = "color",
    Active = "active",
    MouseUpdate = "mouse-update",
}

export interface Point {
    x: number;
    y: number;
}

export interface Drawing {
    points: Point[][];
    color: string;
    userMouse: Point | null;
}

interface PointBuffer {
    points: Point[];
    startIndex: number;
    lineIndex: number;
}

interface SendAnnotationMessageProps {
    userId: string;
    startColor: string;
}

const SendAnnotationMessage: React.FC<SendAnnotationMessageProps> = ({
    userId,
    startColor,
}) => {

    const { subscribeToChannel, sendMessage, iotConnected } = React.useContext(ConnectIoTContext);

    const { connected, meetingId, canAnnotate, setCanAnnotate, showAnnotation, setShowAnnotation, annotationUrlIndex, setAnnotationUrlIndex } = React.useContext(MeetingInfoContext);

    const [pState, setPState] = React.useState<{ [id: string]: Drawing }>({});
    const [lineIndex, setLineIndex] = React.useState<number>(-1);

    const [requestPoints, setRequestPoints] = React.useState<string[]>([]);
    const [pointBuffer, setPointBuffer] = React.useState<PointBuffer[]>([]);
    const [mouseBuffer, setMouseBuffer] = React.useState<Point | null>(null);
    const [lastMouseSend, setLastMouseSend] = React.useState<number>(0);
    const [lastPointSend, setLastPointSend] = React.useState<number>(0);

    const channelName = `liveMeeting/${meetingId}/annotation`;

    let canReset = false;
    let color = startColor;
    if (pState[userId]) {
        canReset = pState[userId].points.length > 0
        color = pState[userId].color;
    }

    const setColor = (color: string, targetId: string) => {

        setPState(prevPState => {

            const updated = { ...prevPState };

            if (!updated[targetId]) {
                updated[targetId] = { points: [], color, userMouse: null };
            } else {
                updated[targetId].color = color;
            }

            return updated;
        });
    }

    const respondToMessage = (topicName: string, payload: string) => {

        if (topicName !== channelName) {
            console.warn("~~Received message from unexpected channel", topicName);
            return;
        }

        try {
            const { type, sender, target } = JSON.parse(payload);

            if (sender === userId) {
                console.log("~~Received message from self", userId);
                return;
            }

            if (target && target !== userId) {
                console.log("~~Received message not for me", target, userId);
                return;
            }

            console.log("~~Received message", payload);

            switch (type) {
                case MessageType.Annotation: {
                    receiveAnnotationMessage(payload, sender);
                    break
                }
                case MessageType.Clear: {
                    receiveClearMessage(sender);
                    break
                }
                case MessageType.Undo: {
                    receiveUndoMessage(sender);
                    break
                }
                case MessageType.RequestPoints: {
                    receiveRequestPointsMessage(sender, payload);
                    break
                }
                case MessageType.SendPoints: {
                    receiveSendPointsMessage(sender, payload);
                    break
                }
                case MessageType.Color: {
                    receiveColorMessage(sender, payload);
                    break;
                }
                case MessageType.Active: {
                    receiveActiveMessage(sender, payload);
                    break;
                }
                case MessageType.MouseUpdate: {
                    receiveMouseUpdateMessage(sender, payload);
                    break;
                }
                default: {
                    console.warn("~~Received unexpected message type", type);
                }
            }
        }
        catch (e: any) {
            console.error("~~Error parsing message", e);
        }
    }

    const receiveActiveMessage = (sender: string, payload: string) => {

        const { active, url, color } = JSON.parse(payload);

        setActiveState(active, url);

        if (color) {
            setColor(color, sender);
        }
    }

    const setActiveState = (active: any, url: any) => {

        const isActive = active === true || active === "true";

        if (isActive) {
            if (url === annotationUrlIndex) {
                console.log("~~Received active message with same url", url);
                return;
            }

            if (url === null || url === undefined) {
                console.warn("~~Received active message with no url");
                return;
            }
        }

        if (!isActive) {
            setAnnotationUrlIndex(null);
            setShowAnnotation(isActive);
            setPState({});
            return;
        }

        setAnnotationUrlIndex(url);
        setShowAnnotation(isActive);
    }

    const receiveColorMessage = (sender: string, payload: string) => {
        const { color } = JSON.parse(payload);

        setColor(color, sender);
    }

    const receiveAnnotationMessage = (payload: string, sender: string) => {
        const { points, color, lineIndex, startIndex } = JSON.parse(payload);

        if (!points) {
            console.error("~~Received annotation message with no points", payload);
            return;
        }

        const typedPoints = points as Point[];

        setPState(prevPState => {
            const updated = { ...prevPState };

            // ensure the user state exists
            if (!updated[sender]) {
                updated[sender] = { points: [typedPoints], color, userMouse: null };
            }
            else {
                updated[sender].color = color;
            }

            // ensure the line exists
            for (let i = updated[sender].points.length - 1; i < lineIndex; i++) {
                updated[sender].points.push([]);
            }

            // update points if points already exist
            if (startIndex < updated[sender].points[lineIndex].length) {
                console.log("~~!! Received annotation message with start index", startIndex, "to", updated[sender].points[lineIndex].length);

                let currentIndex = 0;

                for (let i = startIndex; i < updated[sender].points[lineIndex].length; i++) {
                    updated[sender].points[lineIndex][i] = typedPoints[currentIndex];
                    currentIndex++;
                }

                if (currentIndex < typedPoints.length) {
                    updated[sender].points[lineIndex].push(...typedPoints.slice(currentIndex));
                }

                return updated;
            }

            // add placeholder points if points are missing
            if (startIndex > updated[sender].points[lineIndex].length) {
                console.log("~~!! Received annotation message with start index", startIndex, "to", updated[sender].points[lineIndex].length);

                let previousPoint: Point | null = null;
                if (updated[sender].points[lineIndex].length > 0) {
                    // previous point is the last point in the line
                    previousPoint = updated[sender].points[lineIndex][updated[sender].points[lineIndex].length - 1];
                }
                else if (updated[sender].userMouse) {
                    // previous point is the user's mouse position
                    previousPoint = updated[sender].userMouse;
                }

                // we can't add points if we don't have a previous point
                if (previousPoint) {
                    for (let i = updated[sender].points[lineIndex].length; i < startIndex; i++) {
                        updated[sender].points[lineIndex].push(previousPoint);
                    }
                }
            }

            // add the new points
            updated[sender].points[lineIndex].push(...typedPoints);

            return updated;
        });
    }

    const receiveClearMessage = (sender: string, color?: string) => {
        setPState(prevState => {
            const newState = { ...prevState };

            if (newState[sender]) {
                newState[sender].points = [];

                if (color) {
                    newState[sender].color = color;
                }
            }
            else if (color) {
                newState[sender] = { points: [], color, userMouse: null };
            }

            return newState;
        });
    }

    const receiveUndoMessage = (sender: string) => {
        setPState(prevState => {
            const newState = { ...prevState };

            if (newState[sender] && newState[sender].points.length > 0) {
                newState[sender].points.pop();
            }

            return newState;
        });
    }

    const receiveRequestPointsMessage = (sender: string, payload: string) => {
        const { color: otherUserColor } = JSON.parse(payload);

        setRequestPoints(prevRequestPoints => {
            if (prevRequestPoints.includes(sender)) {
                return prevRequestPoints;
            } else {
                return [...prevRequestPoints, sender];
            }
        });

        receiveClearMessage(sender, otherUserColor);

        // send color back to requester
        const message = {
            sender: userId,
            type: MessageType.Color,
            color,
        }

        sendMessage(channelName, JSON.stringify(message));
    }

    const receiveSendPointsMessage = (sender: string, payload: string) => {
        const { points, url, active } = JSON.parse(payload);

        setPState(prevState => {
            const updated = { ...prevState };

            updated[sender] = points;

            return updated;
        });

        setActiveState(active, url);
    }

    const receiveMouseUpdateMessage = (sender: string, payload: string) => {
        const { x, y } = JSON.parse(payload);

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[sender]) {
                console.log("~~No user state found for", sender);
                updated[sender] = { points: [], color, userMouse: { x, y } };
            } else {
                updated[sender].userMouse = { x, y };
            }

            return updated;
        });
    }

    useEffect(() => {

        const onFinished = (error?: string) => {

            if (error) {
                console.error("~~Error subscribing to annotation channel", error);
                return;
            }

            const message = {
                sender: userId,
                type: MessageType.RequestPoints,
                color: startColor,
            }

            sendMessage(channelName, JSON.stringify(message));

            setColor(startColor, userId);
        }

        const waitForConnection = async () => {
            const response = await subscribeToChannel(channelName, respondToMessage);

            if (response.statusCode !== 200) {
                console.error("~~Error subscribing to annotation channel", response);
                return;
            }

            setCanAnnotate(true);

            onFinished();
        }

        if (!connected) {
            return;
        }

        if (!iotConnected) {
            return;
        }

        if (canAnnotate) {
            return;
        }

        waitForConnection();

        // Clean up
        return () => {
            console.log("Unsubscribing from annotation channel");
            // unsubscribeFromChannel(channelName);
            // setListenerSet(false);
        }

    }, [connected, iotConnected]);

    useEffect(() => {
        if (!connected) {
            return;
        }

        if (requestPoints.length === 0) {
            return;
        }

        requestPoints.forEach(target => {

            const message = {
                sender: userId,
                type: MessageType.SendPoints,
                points: pState[userId],
                target,
                active: showAnnotation,
                url: annotationUrlIndex,
                color,
            }

            sendMessage(channelName, JSON.stringify(message));
        });

        setRequestPoints([]);
    }, [requestPoints]);

    useEffect(() => {

        if (!connected) {
            return;
        }

        if (!mouseBuffer) {
            return;
        }

        if (Date.now() - lastMouseSend < 50) {
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.MouseUpdate,
            x: mouseBuffer.x,
            y: mouseBuffer.y,
        }

        sendMessage(channelName, JSON.stringify(message));
        setMouseBuffer(null);
        setLastMouseSend(Date.now());

    }, [mouseBuffer]);

    // Timer to send buffered points every half second
    useEffect(() => {

        if (pointBuffer.length == 0) {
            return
        }

        const sendBuffer = () => {
            if (pointBuffer.length == 0) {
                return
            }

            const message = {
                sender: userId,
                type: MessageType.Annotation,
                points: pointBuffer[0].points,
                color,
                lineIndex: pointBuffer[0].lineIndex,
                startIndex: pointBuffer[0].startIndex,
            }

            sendMessage(channelName, JSON.stringify(message));
            setPointBuffer(pointBuffer.slice(1));
            setLastPointSend(Date.now());
        }

        let interval: NodeJS.Timeout | null = null;
        if (Date.now() - lastPointSend < 50) {
            interval = setInterval(() => {
                sendBuffer();
            }, 50);
        }
        else {
            sendBuffer();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [pointBuffer, userId, color, lineIndex]);

    const sendPointAddedMessage = (x: number, y: number, newLine: boolean) => {

        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        let index = lineIndex;

        if (newLine || index === -1) {
            index++;
            setLineIndex(index);
        }

        let startIndex = 0;
        if (pState[userId] && index < pState[userId].points.length) {
            startIndex = pState[userId].points[index].length;
        }

        setPointBuffer(prevBuffer => {
            const updated = [...prevBuffer];

            const update = updated.length === 0 || updated[updated.length - 1].lineIndex !== index
            if (update || updated[updated.length - 1].points.length > 10) {
                updated.push({ points: [{ x, y }], startIndex: startIndex, lineIndex: index });
                return updated
            }

            updated[updated.length - 1].points.push({ x, y });

            return updated;
        }); // Add point to buffer

        // update the state immediately
        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[userId]) {
                updated[userId] = { points: [], color, userMouse: null };
            }

            for (let i = updated[userId].points.length - 1; i < index; i++) {
                updated[userId].points.push([]);
            }

            updated[userId].points[index].push({ x, y });

            return updated;
        });
    }

    const handleColorChange = (color: string) => {

        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.Color,
            color,
        }

        sendMessage(channelName, JSON.stringify(message));

        setColor(color, userId);
    }

    const handleClearOnClick = () => {

        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.Clear,
        }

        sendMessage(channelName, JSON.stringify(message));

        receiveClearMessage(userId);
        setPointBuffer([]);
        setLineIndex(-1);
    }

    const handleUndoOnClick = () => {
        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.Undo,
        }

        sendMessage(channelName, JSON.stringify(message));

        receiveUndoMessage(userId);
        setPointBuffer([]);
        setLineIndex(prevState => prevState - 1);
    }

    const onMouseMove = (x: number, y: number) => {
        if (!canAnnotate) {
            return;
        }

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[userId]) {
                console.log("~~No user state found for", userId);
                updated[userId] = { points: [], color, userMouse: { x, y } };
            } else {
                updated[userId].userMouse = { x, y };
            }

            return updated;
        });

        setMouseBuffer({ x, y });
    }

    return (
        <Stack spacing={1}>
            <AnnotationGrid
                userId={userId}
                points={pState}
                onClick={sendPointAddedMessage}
                onMouseMove={onMouseMove} />
            {showAnnotation &&
                <div style={{
                    position: 'fixed',
                    left: "10px",
                    top: "40%",
                    zIndex: 1000,
                }}>
                    <AnnotationControlBar
                        color={color}
                        handleColorChange={handleColorChange}
                        onReset={handleClearOnClick}
                        onUndo={handleUndoOnClick}
                        canReset={canReset}
                    />
                </div>}
        </Stack>
    )
}

export default SendAnnotationMessage;