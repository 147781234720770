import React, { useContext } from "react";

import { Stack } from "@mui/material";

import { WindowSizeContext } from "../../context/window-size-context";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import { MeetingJoinType } from "../../types";

import LabelledMuteButton from "./labelled-mute-button";
import LabelledAudioButton from "./labelled-audio-button";
import LabelledCameraButton from "./labelled-camera-button";
import LabelledShowSelfButton from "./labelled-show-self-button";
import LeaveCallButton from "./leave-call-button";
import RemoteUploadImageButton from "../upload/remote-upload-image-button";

interface DeviceChimeControlBarProps {
    showText?: boolean
    column?: boolean
}

const DeviceChimeControlBar: React.FC<DeviceChimeControlBarProps> = ({
    showText,
    column = true
}) => {

    const { isPortrait, } = useContext(WindowSizeContext);

    const direction = column ? "column" : "row";

    const { errorMessage, connected } = useContext(MeetingInfoContext);

    const disabled = errorMessage != null || !connected;

    return (
        <Stack
            spacing={1}
            direction={direction}
            justifyContent="space-around"
            sx={{
                borderRadius: "10px",
                height: "fit-content",
                width: "fit-content",
                maxWidth: isPortrait ? "auto" : "20vw",
            }} >
            <LabelledMuteButton
                disabled={disabled}
                startMuted={true}
                showText={showText}
                showDropDown={false}
                allowRemoteControl={true} />
            <LabelledAudioButton
                disabled={disabled}
                startWithAudio={false}
                showText={showText}
                showDropDown={false}
                allowRemoteControl={true} />
            <LabelledCameraButton
                disabled={disabled}
                startWithVideo={true}
                showText={showText}
                showDropDown={false}
                allowRemoteControl={true} />
            <LabelledShowSelfButton
                disabled={disabled}
                showText={showText} />
            <RemoteUploadImageButton
                showText={showText}
                allowRemoteControl={true} />
            <LeaveCallButton
                showText={showText}
                meetingType={MeetingJoinType.Headset}
                allowRemoteControl={true} />
        </Stack>
    );
}

export default DeviceChimeControlBar;