import React, { useContext } from "react";

import { Stack } from "@mui/material";

import DeviceChimeControlBar from "../components/meeting/device-chime-control-bar";
import FilteredVideoTileGrid from "../components/meeting/filtered-video-tile-grid";
import { MeetingInfoContext } from "../context/meeting-info-context";
import ErrorOrChildren from "../components/meeting/error-or-children";
import LocalViewTile from "../components/meeting/local-view-tile";


const DeviceMeeting: React.FC = () => {

    const { showSelf } = useContext(MeetingInfoContext);

    const viewHeight = 75;
    const viewWidth = 75;

    return (
        <Stack
            sx={{
                minHeight: "100vh",
                minWidth: "100vw",
                backgroundColor: "#2E3033",
            }}
        >
            <Stack
                spacing={1}
                direction={"row"}
                sx={{
                    alignItems: "left",
                    justifyContent: "centre",
                    padding: 1,
                }}>
                <DeviceChimeControlBar showText={true} />
                <Stack
                    spacing={2}
                    sx={{
                        width: "fit-content",
                        height: "fit-content",
                        padding: 0.5,
                    }}
                    aria-hidden={true}>
                    <ErrorOrChildren>
                        {showSelf ?
                            <LocalViewTile
                                showErrorMessage={true}
                                viewHeight={viewHeight}
                                viewWidth={viewWidth}
                            /> :
                            <FilteredVideoTileGrid
                                showNamePlate
                                viewHeight={viewHeight}
                                viewWidth={viewWidth}
                                excludeSelf={true} />}
                    </ErrorOrChildren>
                </Stack >
            </Stack>
        </Stack >
    );

};

export default DeviceMeeting;