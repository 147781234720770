import React from "react"

import {
  Button,
  Typography,
  Grid,
  Stack,
} from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"

import { useFormik } from "formik"

import { Meeting, Organisation, Site, User } from "../../types"
import { useGetCurrentUser, useListOrganisations, useListPatients, useListSites } from "../../store"

import PatientSelect from "../patients/patient-select"
import SelectOrAddAttendee from "./select-or-add-attendee"
import SelectSite from "../site/select-site"

interface MeetingFormProps {
  useStartDateInForm: boolean
  handleClose: () => void
  meeting: Meeting
  addMeeting: (meeting: Meeting) => void
  update?: boolean
}

export const checkSiteList = (
  originalOrganisationIds: string[],
  siteIds: string[],
  organisationIds: string[],
  currentUser: User | null | undefined
): string | undefined => {

  if (siteIds.length < 2) {
    return `You must select at least two sites to invite.`;
  }

  if (organisationIds.length < 2) {
    return `You must select sites from at least two organisations to invite.`;
  }

  if (currentUser && !organisationIds.includes(currentUser?.organisationId)) {
    return `You must include your own organisation in the meeting.`;
  }

  let sameOrgCount = 0;
  for (let orgId of organisationIds) {
    if (originalOrganisationIds.includes(orgId)) {
      sameOrgCount++;
    }
  }

  if (sameOrgCount < originalOrganisationIds.length) {
    return `Meeting must include all previous organisations.`;
  }

  for (let orgId of originalOrganisationIds) {
    if (!organisationIds.includes(orgId)) {
      return `You must include all organisations from the original meeting.`;
    }
  }

  return undefined;
}

const MeetingForm: React.FC<MeetingFormProps> = ({
  useStartDateInForm,
  handleClose,
  meeting,
  addMeeting,
  update = false,
}: MeetingFormProps) => {

  const [waitingOnAdd, setWaitingOnAdd] = React.useState<boolean>(false);

  const { data: currentUser } = useGetCurrentUser();
  const { data: patientList } = useListPatients();
  const { data: organisations, isLoading: loadingOrgs } = useListOrganisations();
  const { data: sites, isLoading: loadingSites } = useListSites();

  const organisationDictionary: Record<string, Organisation> = {};

  if (organisations) {
    for (let organisation of organisations) {
      organisationDictionary[organisation.organisationId] = organisation;
    }
  }

  const onClose = () => {
    formik.resetForm();
    handleClose()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startTime: meeting?.startTime || new Date(),
      organisationIds: meeting?.organisationIds || [],
      patientId: meeting?.patientId || "",
      attendeesId: meeting?.attendeesId || [],
      siteIds: meeting?.siteIds || [],
    },
    validate: values => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (values.startTime < new Date()) {
        errors.startTime = "Start time must be in the future";
      }

      if (values.startTime.getHours() < 6 || values.startTime.getHours() >= 18) {
        errors.startTime = "Start time must be between 6am and 6pm";
      }

      const siteError = checkSiteList(meeting?.organisationIds, values.siteIds, values.organisationIds, currentUser)

      if (siteError) {
        errors.siteIds = siteError;
      }

      if (values.patientId === "") {
        errors.patientId = "You must select a patient to invite.";
      }

      return errors;
    },
    onSubmit: values => {

      let duration = meeting.endTime.getTime() - meeting.startTime.getTime();

      const startTime = values.startTime;

      const endTime = new Date(startTime.getTime() + duration);

      const meetingName = startTime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })

      const updatedMeeting = {
        ...meeting,
        meetingName,
        startTime,
        endTime,
        organisationIds: values.organisationIds,
        patientId: values.patientId,
        attendeesId: values.attendeesId,
        siteIds: values.siteIds,
      }

      addMeeting(updatedMeeting);
      onClose();
    },
  });

  const changeWithDatePicker = (value: any) => {
    formik.setFieldValue("startTime", value);
  }

  const changePatient = (selected: string[]) => {
    const selectedId = selected.length > 0 ? selected[0] : ""

    formik.setFieldValue("patientId", selectedId);
  }

  const submitDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

  const uncontrolledPicker = useStartDateInForm && !formik.touched.startTime

  const siteChangeEvent = async (siteIds: string[], sites: Site[]) => {

    const organisationIds: string[] = []

    for (let site of sites) {
      for (let orgId of site.linkedOrganisations) {
        if (!organisationIds.includes(orgId)) {
          organisationIds.push(orgId)
        }
      }
    }

    await formik.setFieldValue("organisationIds", organisationIds, false)
    await formik.setFieldValue("siteIds", siteIds, true);

    formik.setTouched({ ...formik.touched, organisationIds: true });
    formik.setTouched({ ...formik.touched, siteIds: true });
  }

  const attendeeChangeEvent = (attendeesId: string[]) => {
    formik.setTouched({ ...formik.touched, attendeesId: true });
    formik.setFieldValue("attendeesId", attendeesId);
  }

  const createPatientList = () => {
    if (!patientList) {
      return []
    }

    const patientIdList: string[] = []

    for (let patient of patientList) {
      patientIdList.push(patient.patientId)
    }

    return patientIdList
  }

  const patientIds = createPatientList()

  const reportAddTemporaryUserStart = () => {
    console.log("Adding user started");
    setWaitingOnAdd(true);
  }

  const reportSuccess = (user?: User) => {

    if (user) {
      const newAttendeesId = [...formik.values.attendeesId, user.userId]
      formik.setFieldValue("attendeesId", newAttendeesId)
    }

    console.log("User added successfully");
    setWaitingOnAdd(false);
  }

  const reportError = () => {
    console.log("Failed to add user");
    setWaitingOnAdd(false);
  }


  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant={"h4"}>
          {update ? "Edit Meeting" : "Schedule Meeting"}
        </Typography>
        <Typography variant={"body1"}>
          {update ? "Update the details of the meeting below." : "Fill in the details of the meeting below."}
        </Typography>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {uncontrolledPicker &&
              <DateTimePicker
                label="Start Time"
                value={formik.values.startTime}
                onChange={changeWithDatePicker}
                disabled={waitingOnAdd}
                disablePast
                sx={{
                  id: "startTime",
                  name: "startTime",
                  width: "100%",
                }} />}
            {!uncontrolledPicker &&
              <DateTimePicker
                label="Start Time"
                onChange={changeWithDatePicker}
                disablePast
                disabled={waitingOnAdd}
                sx={{
                  id: "startTime",
                  name: "startTime",
                  width: "100%",
                }} />}
            {formik.errors.startTime &&
              <Typography color="error">{formik.errors.startTime as string}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <SelectSite
              id={"siteIds"}
              sites={sites || []}
              organisationDictionary={organisationDictionary}
              isLoading={loadingOrgs || loadingSites}
              disabled={waitingOnAdd}
              selectedSiteId={formik.values.siteIds}
              onChange={siteChangeEvent}
              error={formik.touched.siteIds ? formik.errors.siteIds : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOrAddAttendee
              requiredIds={[]}
              disabled={waitingOnAdd}
              organisationIds={currentUser?.organisationId ? [currentUser.organisationId] : []}
              selected={formik.values.attendeesId}
              onSelect={attendeeChangeEvent}
              onSubmit={reportAddTemporaryUserStart}
              onSuccess={reportSuccess}
              onFail={reportError}
            />
          </Grid>
          {!update &&
            <Grid item xs={12}>
              <PatientSelect
                disabled={waitingOnAdd}
                selectedPatientId={formik.values.patientId}
                onChange={changePatient}
                patientIdList={patientIds} />
              {formik.touched.patientId && formik.errors.patientId &&
                <Typography color="error">{formik.errors.patientId}</Typography>}
            </Grid>}
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color={update ? "error" : "info"}
              onClick={handleClose}
              fullWidth>
              {update ? "Discard Changes" : "Cancel"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={submitDisabled}
              color="success"
              type="submit"
              variant="outlined"
              fullWidth>
              {update ? "Update" : "Schedule"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  )
}

export default MeetingForm
