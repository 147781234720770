import { useContext, useEffect, useState } from 'react';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import { isOptionActive, useAudioInputs, useMeetingManager, useMeetingStatus, useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';

import ControlButton from '../control-button';
import TelehealthPopover, { CustomPopOverItemProps } from '../telehealth-popover';
import { Divider } from '@mui/material';
import { MeetingInfoContext } from '../../context/meeting-info-context';
import { ChimeMessageContext } from '../../context/chime-message-context';

interface MuteProps {
    startMuted: boolean;
    disabled?: boolean;
    showText?: boolean;
    showDropDown?: boolean;
    allowRemoteControl?: boolean;
}

const LabelledMuteButton: React.FC<MuteProps> = ({
    startMuted,
    disabled = false,
    showText,
    showDropDown = true,
    allowRemoteControl = false
}) => {

    const meetingManager = useMeetingManager();
    const { muted, toggleMute } = useToggleLocalMute();
    const { devices, selectedDevice } = useAudioInputs();
    const [dropdownOptions, setDropdownOptions] = useState<CustomPopOverItemProps[]>([]);

    const { userId } = useContext(MeetingInfoContext);
    const { subscribeToChannel, sendMessage } = useContext(ChimeMessageContext);
    const [subscribed, setSubscribed] = useState<boolean>(false);

    const [isWaiting, setIsWaiting] = useState<boolean>(false);

    const status = useMeetingStatus();

    const sendMuteMessage = (muted: boolean) => {
        sendMessage("microphone", muted ? "false" : "true", userId);
    }

    useEffect(() => {
        if (!allowRemoteControl) {
            return;
        }

        if (subscribed) {
            return;
        }

        if (status != 1) {
            return;
        }

        subscribeToChannel("microphone", "labelled-microphone-button", (message) => {

            if (message.payload == "join") {
                sendMuteMessage(!muted);
                return;
            }

            setIsWaiting(true);
        })

        sendMuteMessage(!muted);
        console.log("~~&&& Sent mic message", muted);

        setSubscribed(true);

    }, [status]);

    useEffect(() => {
        if (!isWaiting) {
            return;
        }

        toggleMute();
        setIsWaiting(false);

    }, [isWaiting]);

    useEffect(() => {
        if (status != 1) {
            console.log("Not connected");
            return;
        }

        if (muted !== startMuted) {
            toggleMute();
        }

    }, [startMuted, status]);

    const selectOption = (option: CustomPopOverItemProps) => {
        handleClick(option.id);
    }

    const handleClick = async (deviceId: string): Promise<void> => {
        try {
            await meetingManager.startAudioInputDevice(deviceId);
        } catch (error) {
            console.error('AudioInputControl failed to select audio input device');
        }
    };

    useEffect(() => {
        const getDropdownOptions = async (): Promise<void> => {
            const deviceList: CustomPopOverItemProps[] = [];

            devices.map(async (device) => {
                const popoverItem: CustomPopOverItemProps = {
                    label: device.label,
                    id: device.deviceId,
                    active: await isOptionActive(device, device.deviceId)
                };

                deviceList.push(popoverItem);
            });

            setDropdownOptions(deviceList);
        };

        getDropdownOptions();
    }, [
        devices,
        selectedDevice,
        meetingManager,
        meetingManager.startAudioInputDevice,
    ]);

    const toggleAudioAndSentMessage = () => {

        toggleMute();

        if (!subscribed) {
            console.log("~~Not subscribed yet");
            return;
        }

        sendMuteMessage(!muted);
    }

    return (
        <ControlButton
            activeIcon={<MicIcon color='action' />}
            inactiveIcon={<MicOffIcon color='action' />}
            active={!muted}
            onClick={toggleAudioAndSentMessage}
            showText={showText}
            disabled={disabled}
            activeLabel={'Mute Myself'}
            inactiveLabel={'Unmute Myself'} >
            <Divider orientation="vertical" />
            {
                showDropDown &&
                <TelehealthPopover
                    options={dropdownOptions}
                    openPopOverLabel='Select Microphone'
                    selectedOption={selectedDevice?.toString() || ""}
                    setSelectedOption={selectOption} />
            }
        </ControlButton >
    );
};

export default LabelledMuteButton;