import { useContext, useState } from "react"

import { Stack } from "@mui/material"

import { Calendar, dateFnsLocalizer, SlotInfo, View } from "react-big-calendar"
import "../../styles/event-calendar.css"

import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import { enAU } from "date-fns/locale"

import AddMeetingModal from "./add-meeting-modal"

import { Meeting, Organisation, User, OrganisationType, MeetingStatus, Site } from "../../types"

import MeetingInfoCompact from "./meeting-info-compact"
import MeetingInfoAgenda from "./meeting-info-agenda";
import MeetingInfoModal from "./meeting-info-modal";

import React from "react";
import { grey } from "@mui/material/colors";
import CalendarButtonBar from "./calendar-button-bar"
import { AuthContext } from "../../context/auth-context"

const locales = {
  "en-AU": enAU
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

interface EventCalendarProps {
  meetingList: Meeting[]
  siteList: Site[]
  userId: string,
  currentUser: User | null | undefined
  currentOrganisation?: Organisation | null
}

const EventCalendar: React.FC<EventCalendarProps> = ({
  meetingList: unfilteredList,
  siteList,
  currentUser,
  currentOrganisation,
}: EventCalendarProps) => {

  const { lastSiteId } = useContext(AuthContext);

  const meetingList = unfilteredList.filter((meeting) => {

    if (lastSiteId == "" || lastSiteId == "all") {
      return true
    }

    if (meeting.siteIds.includes(lastSiteId)) {
      return true
    }
  })

  const validViews: View[] = ["month", "week", "work_week", "day", "agenda"];

  let storedView = localStorage.getItem("lastView")
  if (storedView && !validViews.includes(storedView as View)) {
    storedView = null
  }

  const lastView: View = storedView != null
    ? storedView as View :
    currentOrganisation?.type == OrganisationType.Metro ?
      "week" as View :
      "month" as View

  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0)

  const [currentSlot, setCurrentSlot] = useState<SlotInfo | null>(null)

  const [eventInfoModal, setEventInfoModal] = useState(false)

  const [view, setView] = useState<View>(lastView)

  const [date, setDate] = useState(new Date())

  const siteDictionary = siteList.reduce((acc, site) => {
    acc[site.siteId] = site
    return acc
  }, {} as { [key: string]: Site })

  const getColor = (meeting: Meeting | undefined) => {

    if (!meeting) {
      return "white"
    }

    if (meeting.meetingType == MeetingStatus.Cancelled) {
      return grey[400];
    }

    let bestSiteId = ""
    for (const site of meeting.siteIds) {
      const siteObj = siteDictionary[site]

      let siteBelongsToUserOrganisation = false
      for (const orgId of siteObj.linkedOrganisations) {
        if (orgId == currentUser?.organisationId) {
          siteBelongsToUserOrganisation = true
          break
        }
      }

      if (!siteBelongsToUserOrganisation) {
        bestSiteId = site
        break
      } else if (bestSiteId == "") {
        bestSiteId = site
      }
    }

    if (bestSiteId == "") {
      return "white"
    }

    return siteDictionary[bestSiteId]?.color || "white"
  }

  const handleSelectSlot = (event: SlotInfo) => {

    if (!currentOrganisation) {
      return;
    }

    if (currentOrganisation.type != OrganisationType.Metro) {
      return;
    }

    setCurrentSlot(event)
  }

  const handleSelectEvent = (event: Meeting) => {

    if (view == "agenda") {
      return
    }

    const index = meetingList.findIndex((meeting) => meeting.meetingId === event.meetingId);

    setCurrentEventIndex(index)
    setEventInfoModal(true)
  }

  const handleClose = () => {
    setCurrentSlot(null)
  }

  const setViewAndLastView = (view: View) => {
    if (!validViews.includes(view)) {
      return
    }

    setView(view)
    localStorage.setItem("lastView", view)
  }

  const addButtonClick = () => {

    const startTimeToNearestFiveMin = new Date()
    startTimeToNearestFiveMin.setMinutes(Math.ceil(startTimeToNearestFiveMin.getMinutes() / 5) * 5 + 5)
    startTimeToNearestFiveMin.setSeconds(0)

    const thirtyMinLater = new Date(startTimeToNearestFiveMin)
    thirtyMinLater.setMinutes(thirtyMinLater.getMinutes() + 30)

    const slot: SlotInfo = {
      start: startTimeToNearestFiveMin,
      end: thirtyMinLater,
      slots: [],
      action: "select"
    }

    handleSelectSlot(slot)
  }

  const minTime = new Date();
  minTime.setHours(6, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(19, 0, 0);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}>
      <CalendarButtonBar
        date={date}
        setDate={setDate}
        addButtonClick={addButtonClick}
        setView={setViewAndLastView}
        view={view}
        organisation={currentOrganisation} />
      {currentSlot &&
        <AddMeetingModal
          useStartDateInForm={true}
          userOrganisation={currentOrganisation}
          open={currentSlot != null}
          handleClose={handleClose}
          startTime={currentSlot.start}
          patientId={""}
        />
      }
      <MeetingInfoModal
        open={eventInfoModal && meetingList[currentEventIndex] !== undefined}
        handleClose={() => setEventInfoModal(false)}
        meeting={meetingList[currentEventIndex]}
        currentUser={currentUser}
        userOrganisation={currentOrganisation}
      />
      <Calendar
        min={minTime}
        max={maxTime}
        date={date}
        onNavigate={(newDate) => setDate(newDate)}
        localizer={localizer}
        toolbar={false}
        events={meetingList}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onView={() => { }}
        view={view}
        eventPropGetter={(event, start, end, isSelected) => {

          const organisationColor = getColor(event)

          return {
            style: {
              backgroundColor: view == "agenda" ? "white" : organisationColor,
              borderColor: "black",
              color: "black",
            },
          }
        }}
        selectable
        startAccessor="startTime"
        components={{
          event: view == "agenda" ? MeetingInfoAgenda : MeetingInfoCompact,
        }}
        endAccessor="endTime"
        defaultView="week"
        views={["month", "week", "agenda"]}
        onShowMore={(events, date) => {
          setViewAndLastView("week")
        }}
        style={{
          width: "98vw",
          height: "82vh",
          overflow: "auto",
        }}
      />
    </Stack >
  )
}

export default EventCalendar
