import React from "react";

import { Meeting, Organisation, User } from "../../types"

import AttendeeBox from "./attendee-box"
import Grid from '@mui/material/Unstable_Grid2';
import CustomTag from "../custom-tag";

interface UserTagListProps {
    organisation?: Organisation
    invitedUsers: User[]
    meeting: Meeting
    canJoin: boolean
}

const UserTagList: React.FC<UserTagListProps> = ({
    organisation,
    invitedUsers,
    meeting,
    canJoin,
}) => {

    const fillUsers = () => {
        return (
            invitedUsers.map((user) => (
                <Grid
                    xs={6}
                    width={"fit-content"}
                    key={user.userId + "tag grid" + organisation?.organisationId}>
                    <AttendeeBox
                        key={user.userId + "tag grid" + organisation?.organisationId}
                        attendee={user}
                        joined={meeting.joinedIds.includes(user.userId)}
                        canJoin={canJoin}
                    />
                </Grid>
            ))
        )
    }

    const getMessage = () => {

        if (canJoin) {
            return "No attendees in meeting"
        }

        const now = new Date()
        if (now < meeting.startTime) {
            return "No attendees selected"
        }

        if (now > meeting.endTime) {
            return "No attendees joined"
        }

        return "No attendees in meeting"
    }

    const emptyMessage = getMessage()

    return (
        <Grid
            width={"fit-content"}
            container
            spacing={1}>
            {invitedUsers.length > 0 ?
                fillUsers() :
                <Grid
                    xs={6}
                    width={"fit-content"}
                    key={"empty tag grid" + organisation?.organisationId}>
                    <CustomTag
                        text={emptyMessage}
                        color={"#e6e6e6"}
                        sx={{
                            minWidth: "150px"
                        }} />
                </Grid>
            }
        </Grid>
    )
}

export default UserTagList