import React, { useContext, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { ResourceType } from "../../types";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import { useMeetingStatus } from "amazon-chime-sdk-component-library-react";
import { ChimeMessageContext } from "../../context/chime-message-context";

const BackupMeetingNotification: React.FC<React.PropsWithChildren> = ({
    children,
}) => {

    const queryClient = useQueryClient();

    const { userId, meetingId } = useContext(MeetingInfoContext);
    const { subscribeToChannel, sendMessage } = useContext(ChimeMessageContext);
    const [subscribed, setSubscribed] = useState<boolean>(false);

    const status = useMeetingStatus();

    const onUploaded = (meetingId: string) => {
        queryClient.invalidateQueries({
            queryKey: [ResourceType.Uploads, meetingId],
            refetchType: "all",
        });
    }

    useEffect(() => {
        if (subscribed) {
            return;
        }

        if (status != 1) {
            return;
        }

        console.log("~~## Subscribing to upload-image", subscribed);

        subscribeToChannel("upload-image", "labelled-upload-image-button", (message) => {

            if (message.payload == "join") {
                console.log("~~## Sent upload-image message");
                return;
            }

            if (message.payload == "complete") {
                console.log("~~## Received upload-image message", message.payload);
                onUploaded(meetingId);
            }
        })

        setSubscribed(true);

    }, [status]);


    return (
        <>
            {children}
        </>
    )

}

export default BackupMeetingNotification;