import React, { PropsWithChildren, useEffect, useRef } from 'react';

import { useAudioVideo, useLocalVideo } from 'amazon-chime-sdk-component-library-react';

import { createCss } from '../../styles/video-styles';
import NoRemoteView from './no-remote-view';
import { MeetingInfoContext } from '../../context/meeting-info-context';

interface LocalViewTileProps {
    viewHeight: number;
    viewWidth: number;
    overrideCSS?: React.CSSProperties;
    showErrorMessage?: boolean;
}

const LocalViewTile: React.FC<PropsWithChildren<LocalViewTileProps>> = ({
    viewHeight,
    viewWidth,
    showErrorMessage = false,
    overrideCSS,
    children,
}) => {

    const { tileId, isVideoEnabled } = useLocalVideo();
    const audioVideo = useAudioVideo();
    const videoEl = useRef<HTMLVideoElement>(null);

    const { errorMessage } = React.useContext(MeetingInfoContext);

    const getErrorMessage = () => {

        if (!showErrorMessage) {
            return null;
        }

        if (errorMessage) {
            return errorMessage;
        }

        if (!isVideoEnabled) {
            return "You are not sharing your video";
        }

        return null;
    }

    const finalErrorMessage = getErrorMessage();

    const [css, setCss] = React.useState(createCss(9, 16, viewHeight, viewWidth, 1));

    useEffect(() => {
        if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
            return;
        }

        audioVideo.bindVideoElement(tileId, videoEl.current);

        setCss(
            createCss(
                videoEl.current.height,
                videoEl.current.width,
                viewHeight,
                viewWidth,
                1));

        return () => {
            const tile = audioVideo.getVideoTile(tileId);
            if (tile) {
                audioVideo.unbindVideoElement(tileId);
            }
        };
    }, [audioVideo, tileId, isVideoEnabled]);

    return (
        <>
            {!finalErrorMessage &&
                <video
                    ref={videoEl}
                    style={
                        overrideCSS ?
                            overrideCSS :
                            css
                    }
                />}
            {finalErrorMessage &&
                <NoRemoteView message={finalErrorMessage} backgroundColor='lightGrey' />}
            {children}
        </>
    );
};

export default LocalViewTile;