import React from "react";

import { Stack, TableCell, Typography } from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';

import { MeetingStatus, Patient } from "../../types";

import CustomTag from "../custom-tag";
import { useGetMeetingsWithIds } from "../../store";
import TelehealthStyledButton from "../telehealth-styled-button";
import AttendeeOrganisationGrid from "../calendar/attendee-organisation-grid";

interface PatientTableRowProps {
    patient: Patient;
}

const PatientTableRow: React.FC<PatientTableRowProps> = ({
    patient,
}) => {

    const { data: meetings, isLoading } = useGetMeetingsWithIds(patient.meetingIds);

    let mostRecentMeeting = null;
    let upcomingMeeting = null;

    if (!isLoading && meetings && meetings.length > 0) {

        const now = new Date();

        for (let i = 0; i < meetings.length; i++) {
            const meeting = meetings[i];

            if (!meeting) {
                continue;
            }

            if (meeting.meetingType === MeetingStatus.Cancelled) {
                continue;
            }

            if (meeting.meetingType === MeetingStatus.Confirmed) {
                if (!mostRecentMeeting || meeting.startTime > mostRecentMeeting.startTime) {
                    mostRecentMeeting = meeting;
                }
            }

            if (meeting.meetingType === MeetingStatus.Scheduled) {
                if (meeting.startTime < now) {
                    continue;
                }

                if (!upcomingMeeting || meeting.startTime < upcomingMeeting.startTime) {
                    upcomingMeeting = meeting;
                }
            }
        }
    }

    const dateTimeString = (date: Date) => {

        if (!date) {
            return "???";
        }

        return date.toLocaleDateString() + " " + date.toLocaleTimeString("en-AU", { hour: '2-digit', minute: '2-digit' });
    }

    const creationDateString = patient.created ? patient.created.toLocaleDateString() : "???";

    return (
        <>
            <TableCell align="left">
                <CustomTag
                    text={patient.patientId || ""}
                    color="#e6e6e6" />
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16} minWidth={"160px"}>
                    {creationDateString}
                </Typography>
            </TableCell>
            <TableCell align="left" sx={{ width: "30%" }}>
                {!isLoading && mostRecentMeeting &&
                    <AttendeeOrganisationGrid
                        meeting={mostRecentMeeting}
                        direction="row"
                        compact={true}
                        title={mostRecentMeeting.startTime.toLocaleDateString()} />}
                {!mostRecentMeeting &&
                    <Typography variant="body1" fontSize={16}>
                        None
                    </Typography>}
                {isLoading &&
                    <Typography variant="body1" fontSize={16}>
                        Loading...
                    </Typography>}
            </TableCell>
            <TableCell align="left" sx={{ width: "30%" }}>
                {!isLoading && upcomingMeeting &&
                    <AttendeeOrganisationGrid
                        meeting={upcomingMeeting}
                        compact={true}
                        direction="row"
                        title={upcomingMeeting.startTime.toLocaleDateString()} />}
                {!isLoading && !upcomingMeeting &&
                    <Typography variant="body1" fontSize={16}>
                        None
                    </Typography>}
                {isLoading &&
                    <Typography variant="body1" fontSize={16}>
                        Loading...
                    </Typography>}
            </TableCell>
            <TableCell align="right">
                <Stack width={"100%"} direction="row" justifyContent={"flex-end"}>
                    <Stack width={"fit-content"} direction="row">
                        <TelehealthStyledButton
                            navigationPath={`/patient?patientId=${patient.patientId}`}
                            label="View Meetings"
                            showText
                            variant="contained"
                            tooltip={"View all meetings for this patient"}
                            onClick={() => { }}
                            icon={<ViewListIcon />} />
                    </Stack>
                </Stack>
            </TableCell>
        </>
    );
}

export default PatientTableRow;