import React, { useContext, useEffect } from "react";

import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react";

import { MeetingInfoContext } from "../../context/meeting-info-context";
import { ChimeMessage, ChimeMessageContext } from "../../context/chime-message-context";

import ControlButton, { ControlButtonProps } from "../control-button";

interface SendMessageButtonProps extends ControlButtonProps {
    topic: string
    id: string
    isMaster: boolean
}

const SendMessageButton: React.FC<SendMessageButtonProps> = ({
    topic,
    id,
    isMaster,
    onClick,
    active,
    ...rest
}) => {

    const { userId, meetingType } = useContext(MeetingInfoContext);

    const meetingManager = useMeetingManager();

    const { subscribeToJoinMessage, subscribeToChannel, sendMessage } = useContext(ChimeMessageContext);

    const [listening, setListening] = React.useState(false);

    useEffect(() => {

        if (listening) {
            return;
        }

        if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
            return;
        }

        if (!meetingManager.audioVideo) {
            return;
        }

        subscribeToChannel(topic, id, (message: ChimeMessage) => {
            if (message.payload != "true" && message.payload != "false") {
                return;
            }

            onClick(message.payload == "true");
        });

        if (isMaster) {
            console.log("~~Sending active", active);
            send(active);
        }
        else {
            sendMessage(topic, "join", userId);
        }

        setListening(true);

    }, [meetingManager.meetingStatus, meetingManager.audioVideo, onClick, isMaster, userId, meetingType]);

    const clickAndSend = (newActive: boolean) => {
        onClick(newActive);
        send(newActive);
    }

    const send = (newActive: boolean) => {
        if (!listening) {
            return;
        }

        sendMessage(topic, newActive ? "true" : "false", userId);
    }

    return (
        <ControlButton
            {...rest}
            onClick={clickAndSend}
            active={active} />
    );
}

export default SendMessageButton;