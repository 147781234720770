import React from "react";

import { Stack, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import { Meeting } from "../../types";
import { DateTimePicker } from "@mui/x-date-pickers";
import TelehealthIconButton from "../telehealth-icon-button";

interface EditMeetingTimeProps {
    meeting: Meeting | undefined | null,
    canEdit: boolean,
    updateMeeting: (meeting: Meeting) => void
}

const EditMeetingTime: React.FC<EditMeetingTimeProps> = ({
    meeting,
    canEdit,
    updateMeeting,
}: EditMeetingTimeProps) => {

    const [editInProgress, setEditInProgress] = React.useState(false)
    const [updatedTime, setUpdatedTime] = React.useState<Date | null>(meeting?.startTime || null)

    const createDateLabel = (date: Date) => {

        const timeZone = date.toLocaleTimeString("en-AU", { timeZoneName: "short" }).split(" ")[2]
        const timeZoneString = ` (${timeZone})`

        const timeLocalString = date.toLocaleTimeString("en-AU", { hour: "numeric", minute: "numeric", hour12: true })

        // if today
        if (date.toDateString() === new Date().toDateString()) {
            return timeLocalString + timeZoneString
        }

        const atTimeString = " at " + timeLocalString + timeZoneString

        const dateNumber = date.getDate()

        let dayString = dateNumber.toString()

        if (dateNumber === 1) {
            dayString += "st"
        }
        else if (dateNumber === 2) {
            dayString += "nd"
        }
        else if (dateNumber === 3) {
            dayString += "rd"
        }
        else {
            dayString += "th"
        }

        const dayMonthString = dayString + " " + date.toLocaleDateString("en-AU", { month: "long" })

        // if this month
        if (date.getMonth() === new Date().getMonth()) {
            return dayMonthString + atTimeString
        }

        // if this year
        if (date.getFullYear() === new Date().getFullYear()) {
            return dayMonthString + atTimeString
        }

        return dayMonthString + ` ${date.getFullYear()} ` + atTimeString
    }

    const dateLabel = createDateLabel(meeting?.startTime || new Date())

    const onSubmitButton = () => {
        if (!updatedTime || !meeting) {
            return
        }

        const duration = meeting.endTime.getTime() - meeting.startTime.getTime()
        const updatedEndTime = new Date(updatedTime.getTime() + duration)

        const updatedMeeting: Meeting = {
            ...meeting,
            startTime: updatedTime,
            endTime: updatedEndTime
        }

        updateMeeting(updatedMeeting)
        setEditInProgress(false)
    }

    const disabled = !meeting || !updatedTime || updatedTime.getTime() === meeting.startTime.getTime() || updatedTime < new Date()

    const changeEdit = () => {

        if (!meeting) {
            return
        }

        setEditInProgress(!editInProgress)
        setUpdatedTime(meeting.startTime)
    }

    return (
        <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}>
            {editInProgress && <>
                <DateTimePicker
                    label="Start Time"
                    value={updatedTime}
                    onChange={(value) => setUpdatedTime(value)}
                    disablePast
                    sx={{
                        id: "startTime",
                        name: "startTime",
                        width: "100%",
                    }} />
                <TelehealthIconButton
                    onClick={onSubmitButton}
                    disabled={disabled}
                    color="primary"
                    icon={<SaveIcon />}
                    tooltip={disabled ? "Save changes" : "No changes to save"}
                />
            </>}
            {!editInProgress &&
                <Typography variant={"h5"}>
                    Start Time: {dateLabel}
                </Typography>}
            {canEdit &&
                <TelehealthIconButton
                    icon={
                        editInProgress ?
                            <CancelIcon /> :
                            <EditIcon />}
                    onClick={changeEdit}
                    tooltip={
                        editInProgress ?
                            "Cancel edit" :
                            "Edit meeting time"} />}
        </Stack>
    )
}

export default EditMeetingTime