import React from "react";

import { Card, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { v4 as uuidv4 } from "uuid";

import { Organisation, Site } from "../../types";
import { useAddSite, useDeleteSite, useGetSiteByIds, useUpdateSite } from "../../store";

import SiteTableRow from "./site-table-row";
import TelehealthStyledButton from "../telehealth-styled-button";

import EditSiteModal from "./add-site-modal";
import ConfirmationModal from "../confirmation-modal";
import toast from "react-hot-toast";


interface SiteTableProps {
    organisation: Organisation;
}

const SiteTable: React.FC<SiteTableProps> = ({
    organisation,
}) => {

    const toastAdd = () => {
        toast.success("Site added successfully");
    }

    const toastAddError = () => {
        toast.error("Error adding site");
    }

    const toastUpdate = () => {
        toast.success("Site updated successfully");
    }

    const toastUpdateError = () => {
        toast.error("Error updating site");
    }

    const toastDelete = () => {
        toast.success("Site deleted successfully");
    }

    const toastDeleteError = () => {
        toast.error("Error deleting site");
    }

    const { data: sites, isLoading } = useGetSiteByIds(organisation.siteIds);
    const { mutate: addSite } = useAddSite(toastAdd, toastAddError);
    const { mutate: updateSite } = useUpdateSite(toastUpdate, toastUpdateError);
    const { mutate: deleteSite } = useDeleteSite(toastDelete, toastDeleteError);

    const finalSiteList: Site[] = []

    if (sites) {
        sites.forEach((site) => {
            if (site) {
                finalSiteList.push(site);
            }
        });
    }

    const [addMode, setAddMode] = React.useState<boolean>(true);
    const [selectedSite, setSelectedSite] = React.useState<Site | null>(null);
    const [isEditSiteOpen, setIsEditSiteOpen] = React.useState<boolean>(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState<boolean>(false);

    const deleteMessage = [`Are you sure you want to delete the following Site?`, `${selectedSite?.siteName || ""}`];

    const addButtonClick = () => {
        const newSite: Site = {
            siteId: uuidv4(),
            siteName: "",
            siteType: organisation.type,
            color: organisation.color,
            linkedOrganisations: [organisation.organisationId],
        }

        setSelectedSite(newSite);
        setAddMode(true);
        setIsEditSiteOpen(true);
    }

    const deleteButtonClick = (site: Site) => {
        setSelectedSite(site);
        setConfirmationOpen(true);
    }

    const closeAndUpdateSite = (site: Site) => {
        if (addMode) {
            addSite(site);
        } else {
            updateSite(site);
        }

        closeEditModal();
    }

    const closeEditModal = () => {
        setIsEditSiteOpen(false);
    }

    const deleteSiteClick = () => {
        if (selectedSite) {
            deleteSite(selectedSite);
        }

        closeDeleteConfirmation();
    }

    const closeDeleteConfirmation = () => {
        setConfirmationOpen(false);
    }

    return (
        <Card sx={{
            padding: 2
        }}>
            <Stack
                direction={"column"}
                spacing={2}>
                <Stack
                    direction="row"
                    padding={0}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Typography variant="h4">
                        Sites
                    </Typography>
                    <TelehealthStyledButton
                        onClick={addButtonClick}
                        variant="contained"
                        label="Add Site"
                        showText={true}
                        icon={
                            <AddIcon />
                        }
                    />
                </Stack>
                {selectedSite &&
                    <EditSiteModal
                        open={isEditSiteOpen}
                        site={selectedSite}
                        create={addMode}
                        updateSite={closeAndUpdateSite}
                        onClose={closeEditModal} />
                }
                <ConfirmationModal
                    open={confirmationOpen}
                    accept={deleteSiteClick}
                    onClose={closeDeleteConfirmation}
                    title={"Delete Site"}
                    messages={deleteMessage}
                    warningLine={"This action cannot be undone."} />
                <TableContainer component={Stack}>
                    <Table
                        aria-label="organisation table"
                        padding="none"
                        sx={{
                            width: "100%",
                        }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Name
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Type
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Color
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1" fontSize={16}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading &&
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Skeleton variant="rectangular" height={50} />
                                    </TableCell>
                                </TableRow>}
                            {!isLoading && finalSiteList.map((site) => (
                                <TableRow key={site.siteId + "row"}>
                                    <SiteTableRow
                                        key={site.siteId}
                                        site={site}
                                        onDelete={deleteButtonClick}
                                        onUpdate={updateSite}
                                        canDelete={true}
                                        canUpdate={true} />
                                </TableRow>
                            ))}
                            {!isLoading && finalSiteList.length == 0 &&
                                <TableRow>
                                    <TableCell colSpan={4} sx={{
                                        paddingY: 2
                                    }}>
                                        <Typography variant="body1">
                                            No sites found
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Card >
    );
}

export default SiteTable;