import { User, UserType } from "../types";

const URL = import.meta.env.VITE_API_URL as string + "/users";
const adminURL = URL + "/admin";

const getUser = async (
    authToken: string,
    userId: string
): Promise<User | null> => {

    if (userId.length == 0) {
        return null;
    }

    if (authToken.length == 0) {
        console.error("No auth token provided");
        return null;
    }

    let response = null;
    try {
        response = await fetch(`${URL}/${userId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });
    } catch (error: any) {
        console.error("Error" + error);
        return null;
    }

    if (response.status === 404) {
        console.warn("User not found", userId);
        return null;
    }

    if (!response?.ok) {
        console.error("Network response was not ok", response.status);
        return null;
    }

    const data = await response.json();

    const user: User = {
        userId: data.userId,
        fullName: data.fullName,
        jobTitle: data?.jobTitle || "",
        organisationId: data.organisationId || "",
        userType: data.userType as UserType,
        email: data.email,
        lastLogin: data.lastLogin ? new Date(data.lastLogin) : undefined,
        permissionSet: data.permissionSet || false,
    }

    return user
};

const getUsersWithIds = async (authToken: string, userIds: string[], showDeleted: boolean): Promise<(User | null)[]> => {

    const users: (User | null)[] = [];
    for (let i = 0; i < userIds.length; i++) {
        const user = await getUser(authToken, userIds[i]);

        if (!showDeleted) {

            if (!user) {
                continue
            }

            if (user.userType === UserType.Inactive) {
                continue;
            }
        }

        users.push(user);
    }
    return users;
}

const listUsers = async (authToken: string): Promise<User[]> => {
    try {
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(response.status + " Network response was not ok");
        }

        const data = await response.json();

        const userList: User[] = data.map((user: any) => {
            const finalUser: User = {
                userId: user.userId,
                fullName: user.fullName,
                jobTitle: user?.jobTitle || "",
                organisationId: user.organisationId || "",
                userType: user.userType as UserType,
                email: user.email,
                lastLogin: user.lastLogin ? new Date(user.lastLogin) : undefined,
                permissionSet: data.permissionSet || false,
            }

            return finalUser;
        });

        return userList;

    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
};

const updateUser = async (
    authToken: string,
    user: User,
): Promise<string | null> => {
    try {
        const response = await fetch(`${URL}/${user.userId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            if (response.status === 409) {
                // Handle the conflict error specifically
                return response.json().then((data) => {
                    throw new Error(data.message);
                });
            } else {
                throw new Error(response.status + " Network response was not ok");
            }
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
};

const adminAddUser = async (
    authToken: string,
    user: User,
): Promise<string | null> => {

    try {
        const response = await fetch(`${adminURL}/add`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            if (response.status === 409) {
                // Handle the conflict error specifically
                return response.json().then((data) => {
                    throw new Error(data.message);
                });
            } else {
                throw new Error(response.status + " Network response was not ok");
            }
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
};

const adminDeleteUser = async (
    authToken: string,
    userId: string,
): Promise<string | null> => {
    try {
        const response = await fetch(`${adminURL}/${userId}/delete`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(response.status + " Network response was not ok");
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
}

const adminUpdateUserType = async (
    authToken: string,
    user: User,
): Promise<string | null> => {
    try {
        const response = await fetch(`${adminURL}/${user.userId}/update`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userType: user.userType }),
        });

        if (!response.ok) {
            throw new Error(response.status + " Network response was not ok");
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
}

const addTemporaryUser = async (
    authToken: string,
    user: User,
): Promise<string | null> => {
    try {
        const response = await fetch(`${adminURL}/temporary`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            throw new Error(response.status + " Network response was not ok");
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
}

const transferUser = async (authToken: string,
    userId: string,
    organisationId: string): Promise<string | null> => {

    try {
        const response = await fetch(`${adminURL}/${userId}/transfer`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ organisationId: organisationId }),
        });

        if (!response.ok) {
            throw new Error(response.status + " Network response was not ok");
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;

    }
}

export const assignIOTPermissions = async (
    authToken: string,
    identityId: string,
): Promise<string | null> => {
    try {
        const response = await fetch(`${adminURL}/addPermissions`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ identityId }),
        });

        if (!response.ok) {
            throw new Error(response.status + " Network response was not ok");
        }
        return response.json()
    } catch (error) {
        console.log("Error: " + error);
        throw error as Error;
    }
}

const UsersAPIService = {
    getUser,
    listUsers,
    updateUser,
    getUsersWithIds,
    addTemporaryUser,
    transferUser,
    adminAddUser,
    adminDeleteUser,
    adminUpdateUserType,
};

export default UsersAPIService;