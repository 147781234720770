import { useSearchParams } from "react-router-dom";

import { Skeleton, Stack, Typography } from "@mui/material";

import { useGetMeeting } from "../store";
import { MeetingJoinType } from "../types";

import AttendeeOrganisationGrid from "../components/calendar/attendee-organisation-grid";


const CallEnded: React.FC = () => {

    const [searchParams] = useSearchParams();
    const meetingId = searchParams.get("meetingId") || "";
    const meetingType = searchParams.get("meetingType") || "";

    let meetingJoinType = MeetingJoinType.Metro

    if (meetingType.length > 0) {
        meetingJoinType = meetingType as MeetingJoinType;
    }

    const getMessageBasedOnMeetingType = (meetingType: MeetingJoinType): {
        message: string,
        instructions: string
    } => {
        switch (meetingType) {
            case MeetingJoinType.Training:
                return {
                    message: "You have left the training session.",
                    instructions: "You can close this tab now."
                }
            case MeetingJoinType.Headset:
                return {
                    message: "You have left the call.",
                    instructions: "Say 'Navigate Home' to return to the home menu."
                }
            case MeetingJoinType.Phone:
                return {
                    message: "Your photo has been uploaded.",
                    instructions: "You can close this tab now."
                }
            default:
                return {
                    message: "You have left the call.",
                    instructions: "You can close this tab now."
                }
        }
    }

    const { message, instructions } = getMessageBasedOnMeetingType(meetingJoinType);

    const isRealWareDevice = window.screen.width === 1003 && window.screen.height === 564;

    const { data: meeting } = useGetMeeting(meetingId);

    const simpleMeeting = meetingId.length === 0 || meetingJoinType === MeetingJoinType.Training || meetingJoinType === MeetingJoinType.Headset;

    return (
        < Stack justifyContent="center"
            alignItems="center"
            aria-hidden={isRealWareDevice}
            padding={2}
            sx={{
                minHeight: "95vh",
                minWidth: "95vw",
                height: "fit-content",
                width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                {!simpleMeeting &&
                    <>
                        <Typography variant="h2">
                            Please check if anyone is missing from the attendee list.
                        </Typography>
                        <Typography variant="h3">
                            {"(You only need to add users from your organisation.)"}
                        </Typography>
                        {meeting &&
                            <AttendeeOrganisationGrid
                                direction={"row"}
                                meeting={meeting} />}
                        {!meeting &&
                            <Skeleton
                                variant="rounded"
                                width="280px"
                                height="150px"
                            />}
                        <Typography variant="h2">
                            Once finished you can close this tab.
                        </Typography>
                    </>
                }
                {simpleMeeting &&
                    <>
                        <Typography variant="h2">
                            {message}
                        </Typography>
                        <Typography variant="h2">
                            {instructions}
                        </Typography>
                    </>}
            </Stack >
        </Stack >
    );
}

export default CallEnded;