import React from "react"

import { Button, Card, Container, Stack, Typography } from "@mui/material"

import { useGetCurrentUser } from "../store"

import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar"

import ARTelehealthManual from "../assets/ARTelehealthManual.pdf"

import KeyBoard from "../assets/keyboard.png"
import WithPopUp from "../assets/WithPopUp.png"
import GoodQRScan from "../assets/GoodQRScan.png"
import TooCloseQR from "../assets/TooCloseQR.png"
import BlackScreen from "../assets/BlackScreen.png"
import RealWearActionButton from "../assets/RealWearActionButton.jpg"
import StyledAccordion from "../components/styled-accordion"

const HelpPage: React.FC = () => {

    const { data: currentUser } = useGetCurrentUser()

    const supportEmail = "clearcall@ivai.com.au"

    const downloadRef = React.useRef<HTMLAnchorElement>(null)

    const downloadFile = () => {
        if (downloadRef.current) {
            downloadRef.current.click()
        }
    }

    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={5}
            sx={{
                minHeight: "100vh",
                backgroundColor: "white",
            }}>
            <NavigationTelehealthToolbar currentUser={currentUser} />
            <Container>
                <Stack padding={2} spacing={4}>
                    <Typography variant="h2">Help</Typography>
                    <Card sx={{
                        backgroundColor: "#F5F5F5",
                    }}>
                        <Stack spacing={2} padding={2}>
                            <Typography variant="h2">Manual</Typography>
                            <Typography variant="body1">
                                {`You can download the manual here: `}
                            </Typography>
                            <Button
                                variant={"contained"}
                                onClick={downloadFile}
                                sx={{
                                    width: "fit-content",
                                }}>
                                Download Manual
                            </Button>
                            <a
                                href={ARTelehealthManual}
                                ref={downloadRef} download style={{
                                    display: "none",
                                }} />
                            <Typography variant="h2">Support Email</Typography>
                            <Typography variant="body1">
                                {`Contact support at: ${supportEmail}`}
                            </Typography>
                        </Stack>
                    </Card>
                    <Card sx={{
                        backgroundColor: "#F5F5F5",
                    }}>
                        <Stack spacing={0}>
                            <StyledAccordion title="How can I find what the voice commands are?">
                                <Typography variant="body1">
                                    {`The website commands are shown on the labelled buttons on screen. Reading them out loud will activate the commands. Otherwise you can say “select item” and the number next to the label. Global commands can be found here:`}
                                </Typography>
                                <Button variant={"contained"} sx={{
                                    width: "fit-content",
                                }} onClick={() => { window.open("https://support.realwear.com/knowledge/voice-commands") }}>
                                    RealWear Voice Commands
                                </Button>
                            </StyledAccordion>
                            <StyledAccordion title="How can I find eye dominance?">
                                <Typography variant="body1">
                                    {`The following article on the RealWear website explains how to find your eye dominance: `}
                                </Typography>
                                <Button variant={"contained"} sx={{
                                    width: "fit-content",
                                }} onClick={() => { window.open("https://support.realwear.com/knowledge/determining-eye-dominance") }}>
                                    RealWear Eye Dominance
                                </Button>
                            </StyledAccordion>
                            <StyledAccordion title="What do I do if I can't verify the headset's WIFI connection?">
                                <Typography variant="body1">
                                    {`If you cannot verify your connection, this means that the RealWear headset is not connecting to the SAH-C Wi-Fi. Contact IVAI for support ${supportEmail}.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="I have trouble scanning the QR code.">
                                <Typography variant="body1">
                                    {`There is a box with a white outline in the QR code reader. The QR code must be entirely within this box to be scanned. Below is an example of a good scan.`}
                                </Typography>
                                <img src={GoodQRScan} alt="Good QR Scan" />
                                <Typography variant="body1">
                                    {`If the QR code is not fully within the box it will not scan. This is shown below:`}
                                </Typography>
                                <img src={TooCloseQR} alt="Too Close QR" />
                                <Typography variant="body1">
                                    {`If the QR code appears too small in the box it will also not scan. Try moving the QR code closer to the headset.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="Can I wear glasses with the headset?">
                                <Typography variant="body1">
                                    {`It is recommended that if you have prescription glasses to wear them with the headset. `}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="The power light is blinking.">
                                <Typography variant="body1">
                                    {`The power light may blink either green, orange or red to indicate how full the battery is.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="There is a black screen and I can't tell if the device is on.">
                                <Typography variant="body1">
                                    {`Try the following: `}
                                </Typography>
                                <Typography variant="body1">
                                    {`1) Check that the screen is properly aligned with your eyes. It can appear black if the only part of the screen you can see is black.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`2) Try the voice command “navigate home” to return to the home screen. Ensure that voice commands are active by pressing the action button.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`3) Check that the device is not asleep. If the device is asleep tap the power button to wake it.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`4) The device may be off. Hold the power button for 5 seconds to turn it on.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`5) The device may be out of battery. Place on charge and then try turning it on.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="What do I do if I can see the keyboard on the Headset?">
                                <img src={KeyBoard} alt="Key Board" />
                                <Typography variant="body1">
                                    {`If the headset is not muted, it is easy to accidentally select the navigation bar of the website and open the keyboard. Say “navigate back” to go to the previous page. If that does not bring you to the call page you can say “navigate home.” to return the home menu, if you do see the next section “I navigated home without ending meeting.”`}
                                </Typography>
                                <Typography variant="body1">
                                    {`To prevent accidental commands, use the voice command “mute microphone” which will stop voice commands until the action button (large square button on the camera side of the headset).`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="I am on a weird page.">
                                <Typography variant="body1">
                                    {`If the command “navigate back” does not bring you to the call, you can say “navigate home” to return to the home menu. See the section “I navigated home without ending meeting” to prevent issues with the camera being enabled.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="The video for the headset is black or not appearing.">
                                <img src={BlackScreen} alt="Black Screen" />
                                <Typography variant="body1">
                                    {`There are a few things that can cause this issue: `}
                                </Typography>
                                <Typography variant="body1">
                                    {`1) Sometimes when granting permission to the camera it will not activate. Say “disable camera” and then “enable camera” to try and fix this issue.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`2) The camera may be disabled. Say “enable camera” to reactivate the camera.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`3) If you have navigated back or home while video is active the website may not be able to get permission to use the camera. Say “disable camera” and then “enable camera” to try and fix this issue. If this does not work say “leave call” and then follow the instructions to rejoin the call.`}
                                </Typography>
                                <Typography variant="body1">
                                    {`4) If you exited when taking a photo the camera will not automatically be turned back on. Say “enable camera” to reactivate the video.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="I said “upload image” but the camera application is not opening.">
                                <Typography variant="body1">
                                    {`The headset will bring up a very small dialogue box asking if you want to get a local image or take a photo. Say “My camera” to open the camera application. It is important to always use the camera as it will not store the image locally.`}
                                </Typography>
                                <img src={WithPopUp} alt="With Pop Up" />
                            </StyledAccordion>
                            <StyledAccordion title="The RealWear is not responding to voice commands.">
                                <Typography variant="body1">
                                    {`There are two main reasons why the headset may not be responding to voice commands: `}
                                </Typography>
                                <Typography variant="body1">
                                    {`1) The command was not said clearly enough. Try speaking the command slower and louder.`}
                                </Typography>
                                <Typography variant="body1">
                                    {'2) The headset may be have its microphone muted. Press the action button on the side of the headset to unmute the microphone.'}
                                </Typography>
                                <img src={RealWearActionButton} alt="RealWear Action Button" />
                            </StyledAccordion>
                            <StyledAccordion title="What is the difference between “mute myself” and “mute microphone”?">
                                <Typography variant="body1">
                                    {`“Mute microphone” stops the headset listening to commands, it has no effect on whether the call sends your voice to the other user.
                                    “Mute myself” mutes your voice in the call and prevents other users from hearing you. By default your voice is muted as it is intended that the conferencing microphone to be used for you and the patient.
                                    It is recommended that you say “mute microphone” when you start a call to prevent accidental voice commands. The “mute myself” command will be rarely used since the microphone is muted by default.`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="Why can't I add meetings?">
                                <Typography variant="body1">
                                    {`Only users from Metro sites can add meetings. If your account is linked to a Metro site and you can't add meetings contact IVAI for support ${supportEmail}`}
                                </Typography>
                            </StyledAccordion>
                            <StyledAccordion title="What do I do if the Barcode Reader appears black?">
                                <Typography variant="body1">
                                    {`First check that nothing is blocking the camera and that there is decent lighting in the room. If that doesn't work it is likely the website is using the camera (only one program can use the camera at a time), navigate home. To fix this navigate to My Programs and then to Firefox. If this is the wrong call say 'Leave Call' to properly leave the call.`}
                                </Typography>
                            </StyledAccordion>
                        </Stack>
                    </Card >
                </Stack>
            </Container >
        </Stack >
    )
}

export default HelpPage