import React, { useContext, useEffect, useState } from "react";
import { Drawing, Point } from "./send-annotation-message";
import DrawingLineGroup from "./drawing-line-group";
import TelehealthImage from "../components/upload/telehealth-image";
import { useGetPhotoUrls } from "../store";
import { MeetingInfoContext } from "../context/meeting-info-context";

interface AnnotationGridProps {
    points: { [id: string]: Drawing };
    userId: string;
    onClick: (x: number, y: number, newLine: boolean) => void;
    onMouseMove: (x: number, y: number) => void;
}

const AnnotationGrid: React.FC<AnnotationGridProps> = ({
    points,
    userId,
    onClick,
    onMouseMove,
}) => {

    // Unused height/width forces a re-render when the window is resized
    // This is necessary to update the DrawingLine components
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [scale, setScale] = useState(1);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);

    const divRef = React.useRef<HTMLDivElement>(null);

    const lastPoint = React.useRef<{ x: number, y: number } | null>(null);

    const [isDrawing, setIsDrawing] = React.useState(false);

    const { meetingId, annotationUrlIndex, showAnnotation } = useContext(MeetingInfoContext);

    const { data: urlSet } = useGetPhotoUrls(userId, meetingId);

    let annotationUrl = null;

    if (urlSet && annotationUrlIndex !== null) {
        if (annotationUrlIndex > -1 && annotationUrlIndex < urlSet.photoUrls.length) {
            annotationUrl = urlSet.photoUrls[annotationUrlIndex];
        }
    }

    useEffect(() => {
        if (!divRef.current) return;

        const handleResize = (entries: ResizeObserverEntry[]) => {
            for (let entry of entries) {
                if (entry.target === divRef.current) {
                    setWidth(entry.contentRect.width);
                    setHeight(entry.contentRect.height);
                }
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(divRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!divRef.current) {
            return;
        }

        if (isDragging) {
            setTranslateX(e.clientX - startX);
            setTranslateY(e.clientY - startY);
        }

        const rect = e.currentTarget.getBoundingClientRect();
        // Get position of mouse relative to the image
        const x = e.clientX - rect.left - translateX;
        const y = e.clientY - rect.top - translateY;

        // Convert to a percentage of the image size
        // We also change from 0,0 being the top left to the center
        // That way we can deal with scale offset properly
        let adjustedX = x / rect.width - 0.5;
        let adjustedY = y / rect.height - 0.5;

        // Calculate how much offset is required to keep the mouse in the same position
        const scaleChange = -(1 - (1 / scale));
        const offsetX = adjustedX * scaleChange;
        const offsetY = adjustedY * scaleChange;

        // Take into account the scale change for the translate
        const translateOffsetX = translateX / rect.width * scaleChange;
        const translateOffsetY = translateY / rect.height * scaleChange;

        adjustedX += offsetX + translateOffsetX;
        adjustedY += offsetY + translateOffsetY;

        if (isTooClose(lastPoint.current, adjustedX, adjustedY, 0.01)) {
            return;
        }

        onMouseMove(adjustedX, adjustedY);

        if (isDrawing) {
            onClick(adjustedX, adjustedY, lastPoint.current == null);
            lastPoint.current = { x: adjustedX, y: adjustedY };
        }
    };

    const isTooClose = (lastPoint: Point | null, newX: number, newY: number, tolerance: number) => {

        if (lastPoint == null) {
            return false;
        }

        const manhattanDistance = Math.abs(lastPoint.x - newX) + Math.abs(lastPoint.y - newY);

        return manhattanDistance < tolerance;
    }

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {

        console.log("~~~! Mouse Down", isDrawing, e.button);

        if (!isDrawing && e.button === 0) {
            setIsDrawing(true);
        }

        if (e.button === 2) {
            setIsDragging(true);
            setStartX(e.clientX - translateX);
            setStartY(e.clientY - translateY);
        }
    }

    const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDrawing && e.button === 0) {
            stopDrawing();
        }

        if (e.button === 2) {
            setIsDragging(false);
        }
    }

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleWheel = (e: React.WheelEvent) => {
        const scaleAmount = e.deltaY > 0 ? 0.9 : 1.1;
        setScale(prevScale => Math.min(Math.max(prevScale * scaleAmount, 1), 5));
    };

    const onSelected = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(false);
        stopDrawing();
    }

    const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(false);
        stopDrawing();
    }

    const stopDrawing = () => {
        setIsDrawing(false);
        lastPoint.current = null;
    }

    return (
        <div
            onWheel={handleWheel}
            onMouseMove={handleMouseMove}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
            onContextMenu={handleContextMenu}
            onDragStart={onDrag}
            onDragEnd={onDrag}
            onSelect={onSelected}
            ref={divRef}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                maxHeight: '75vh',
                maxWidth: '75vw',
                minHeight: '1px',
                minWidth: '1px',
                cursor: isDragging ? 'grabbing' : undefined,
            }}
        >
            {showAnnotation && annotationUrl &&
                <TelehealthImage
                    currentImage={annotationUrl}
                    scale={scale}
                    translateX={translateX}
                    translateY={translateY}
                    isDragging={isDragging}
                />}
            {Object.keys(points).map((key) => (
                <DrawingLineGroup
                    height={divRef.current?.clientHeight || height}
                    width={divRef.current?.clientWidth || width}
                    userId={key}
                    showMouse={key !== userId}
                    key={key}
                    keyProp={key}
                    drawing={points[key]}
                    scale={scale}
                    translateX={translateX}
                    translateY={translateY}
                    isDragging={isDragging}
                />))}
        </div>
    );
};

export default AnnotationGrid;