import React, { useContext, useEffect } from 'react';

import { Stack } from '@mui/material';

import { useRemoteVideoTileState, useRosterState } from "amazon-chime-sdk-component-library-react";

import NoRemoteView from './no-remote-view';
import { WindowSizeContext } from '../../context/window-size-context';
import { MeetingInfoContext } from '../../context/meeting-info-context';
import TileGrid from './tile-grid';

interface SelectedVideoGridProps {
    tilesToShow: string[];
    viewHeight: number;
    viewWidth: number;
    showNamePlate: boolean;
}

export const SelectedVideoTileGrid: React.FC<SelectedVideoGridProps> = ({
    tilesToShow,
    viewHeight,
    viewWidth,
    showNamePlate,
}) => {

    const { errorMessage } = React.useContext(MeetingInfoContext);
    const { tileIdToAttendeeId, attendeeIdToTileId, tiles } = useRemoteVideoTileState();
    const { roster } = useRosterState();

    const { isPortrait } = useContext(WindowSizeContext);

    const [tilesToRender, setTilesToRender] = React.useState<number[]>([]);

    useEffect(() => {
        const tilesToRender =
            tiles.filter((tileId) => {
                const attendeeId = tileIdToAttendeeId[tileId] || "";

                return tilesToShow.includes(attendeeId);
            });

        setTilesToRender(tilesToRender);

    }, [tilesToShow, tiles, tileIdToAttendeeId, attendeeIdToTileId, roster])

    const getErrorMessage = () => {

        if (errorMessage) {
            return errorMessage;
        }

        if (tiles.length === 0) {
            return "No one is sharing their video";
        }

        if (tilesToRender.length == 0) {

            if (tilesToShow.length > 0) {
                return "None of the selected attendees are sharing video";
            }

            return "There are no videos to show.";
        }

        return "";
    }

    const finalErrorMessage = getErrorMessage();

    const columns = Math.ceil(Math.sqrt(tilesToRender.length));

    return (
        <>
            {finalErrorMessage.length > 0 ?
                <NoRemoteView message={finalErrorMessage} backgroundColor='lightGrey' />
                :
                <Stack
                    id="selected-video-grid"
                    display="grid"
                    gridTemplateColumns={isPortrait ? undefined : `repeat(${columns}, 1fr)`}
                    gridTemplateRows={isPortrait ? `repeat(${columns}, 1fr)` : undefined}
                    justifyContent={"center"}
                    justifyItems={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                    height={`${viewHeight}vh`}
                    width={`${viewWidth}vw`}>
                    <TileGrid
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        showNamePlate={showNamePlate}
                        tilesToRender={tilesToRender}
                        tileIdToAttendeeId={tileIdToAttendeeId}
                        roster={roster} />
                </Stack>}
        </>
    );
}

export default SelectedVideoTileGrid;