import { useContext } from "react";

import { useLocalVideo } from "amazon-chime-sdk-component-library-react";

import { MeetingInfoContext } from "../../context/meeting-info-context";

import LocalViewTile from "./local-view-tile";
import ShowSelfButton from "./show-self-button";
import VideoLabel from "./video-label";

interface LocalViewTileProps {
    viewHeight: number;
    viewWidth: number;
}

const CornerLocalView: React.FC<LocalViewTileProps> = ({
    viewHeight,
    viewWidth,
}: LocalViewTileProps) => {

    const { showSelf } = useContext(MeetingInfoContext);
    const { isVideoEnabled } = useLocalVideo();

    return (
        <>
            {showSelf && <LocalViewTile
                viewHeight={viewHeight}
                viewWidth={viewWidth}
                overrideCSS={{
                    position: "absolute",
                    display: "flex",
                    bottom: "1rem",
                    right: "1rem",
                    width: "20vw",
                    maxHeight: "30vh",
                    height: "auto",
                }}>
                {isVideoEnabled &&
                    <VideoLabel
                        name="You"
                        zIndex={100}
                        right={true}
                        distanceFromSide="18vw" />}
            </LocalViewTile>}
            <div style={{
                position: "absolute",
                bottom: "1rem",
                right: "1rem",
                zIndex: 100,
                display: isVideoEnabled ? "flex" : "none",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <ShowSelfButton right={true} zIndex={100} />
            </div>
        </>
    )
}

export default CornerLocalView;