import React from "react";

import { Card, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { User, UserTypeMapping } from "../../types";

import TelehealthStyledButton from "../telehealth-styled-button";
import TemporaryUserTableRow from "./temporary-user-table-row";

interface TemporaryUserTableProps {
    users: (User | null)[];
    isLoading: boolean;
    addButtonClick: () => void;
    convertTemporaryUser: (user: User) => void;
    transferUser: (user: User) => void;
    deleteUser: (user: User) => void;
}

const TemporaryUserTable: React.FC<TemporaryUserTableProps> = ({
    users,
    isLoading,
    deleteUser,
    addButtonClick,
    convertTemporaryUser,
    transferUser,
}) => {

    users.sort((first, second) => {

        if (!first || !second) {
            return 0;
        }

        const firstTypeNumber = UserTypeMapping[first.userType].number;
        const secondTypeNumber = UserTypeMapping[second.userType].number;
        return secondTypeNumber - firstTypeNumber;
    });

    return (
        <Card sx={{
            padding: 2
        }}>
            <Stack
                direction={"column"}
                spacing={2}>
                <Stack
                    direction="row"
                    padding={0}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Stack direction={"column"}>
                        <Typography variant="h4">
                            Guest Users
                        </Typography>
                        <Typography variant="body1">
                            Guest Users allow staff that do not have logins to be added to meetings (for record keeping).
                        </Typography>
                    </Stack>
                    <TelehealthStyledButton
                        onClick={addButtonClick}
                        variant="contained"
                        label="Add Guest User"
                        showText={true}
                        icon={
                            <AddIcon />
                        }
                    />
                </Stack>
                <TableContainer component={Stack}>
                    <Table
                        aria-label="organisation table"
                        padding="none"
                        sx={{
                            width: "100%",
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Name
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Job Title
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="body1" fontSize={16}>
                                        Invite as User
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1" fontSize={16}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading &&
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Skeleton variant="rectangular" height={50} />
                                    </TableCell>
                                </TableRow>
                            }
                            {!isLoading && users.map((user) => {

                                if (!user) {
                                    return null;
                                }

                                return (
                                    <TableRow
                                        key={user.userId}>
                                        <TemporaryUserTableRow
                                            user={user}
                                            deleteUser={deleteUser}
                                            convertTemporaryUser={convertTemporaryUser}
                                            transferUser={transferUser}
                                        />
                                    </TableRow>
                                )
                            })}
                            {!isLoading && users.length == 0 &&
                                <TableRow>
                                    <TableCell colSpan={4} sx={{
                                        paddingY: 2
                                    }}>
                                        <Typography variant="body1">
                                            No guest users found
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Card>
    )
}

export default TemporaryUserTable;