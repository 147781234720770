import CallEndIcon from '@mui/icons-material/CallEnd';

import ControlButton from "../control-button";
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingJoinType } from '../../types';
import { useContext, useEffect } from 'react';
import { ChimeMessageContext } from '../../context/chime-message-context';

const baseURL = import.meta.env.VITE_WEBSITE_URL as string;

interface LeaveCallButtonProps {
    meetingType: MeetingJoinType;
    returnToHome?: boolean;
    showText?: boolean;
    meetingId?: string;
    allowRemoteControl?: boolean;
}

const LeaveCallButton: React.FC<LeaveCallButtonProps> = ({
    showText,
    returnToHome,
    meetingType,
    meetingId,
    allowRemoteControl = false
}: LeaveCallButtonProps) => {

    const meetingManager = useMeetingManager();

    const { subscribeToChannel } = useContext(ChimeMessageContext);

    useEffect(() => {

        if (!allowRemoteControl) {
            return;
        }

        subscribeToChannel("leaveCall", "labelled-leaveCall-button", (message) => {
            if (message.payload == "join") {
                return;
            }

            console.log("~~Received leave call message", message);
            LeaveCall();
        })

    }, [allowRemoteControl]);

    const createUrl = () => {

        const url = new URL(`${baseURL}/callended`);

        url.searchParams.append("meetingType", `${meetingType}`);

        if (meetingId && meetingId.length > 0) {
            url.searchParams.append("meetingId", meetingId);
        }

        return url.toString();
    }

    const url = createUrl();

    const homeUrl = "/dashboard";

    const LeaveCall = () => {
        if (meetingManager) {
            meetingManager.leave();
        }

        window.location.href = returnToHome ? homeUrl : url
    }

    return (
        <ControlButton
            active={true}
            activeIcon={<CallEndIcon color="action" />}
            activeLabel={"Leave Call"}
            inactiveLabel={"Leave Call"}
            activeColor="#c62828"
            showText={showText}
            onClick={LeaveCall}
        />
    )
}

export default LeaveCallButton;