import React, { useContext, useEffect } from "react";

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { MeetingStatus, useLocalVideo, useMeetingManager, useMeetingStatus } from "amazon-chime-sdk-component-library-react";

import toast from 'react-hot-toast';

import { MeetingInfoContext } from "../../context/meeting-info-context";

import { useUploadFile } from "../../store";
import { ChimeMessageContext } from "../../context/chime-message-context";
import ControlButton from "../control-button";


interface UploadImageButtonProps {
    showText?: boolean;
    allowRemoteControl?: boolean;
}

const RemoteUploadImageButton: React.FC<React.PropsWithChildren<UploadImageButtonProps>> = ({
    showText = true,
    allowRemoteControl = false,
    children
}) => {

    const isTesting = true;

    const [uploading, setUploading] = React.useState(false);

    const { userId, meetingId, patientId } = useContext(MeetingInfoContext);

    const meetingManager = useMeetingManager();
    const status = useMeetingStatus();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();

    const { subscribeToChannel, sendMessage } = useContext(ChimeMessageContext);
    const [subscribed, setSubscribed] = React.useState<boolean>(false);

    const [wasVideoEnabled, setWasVideoEnabled] = React.useState(false);
    const [isWaiting, setIsWaiting] = React.useState(false);

    const canUpload = !uploading && meetingManager.meetingStatus === MeetingStatus.Succeeded;

    const sendCanUploadMessage = (canUpload: boolean) => {

        if (!subscribed) {
            return;
        }

        sendMessage("upload-image", canUpload ? "true" : "false", userId);
    }

    const onFileUploaded = () => {
        setUploading(false);

        sendCanUploadMessage(true);

        sendMessage("upload-image", "complete", userId);

        toast.success('File uploaded successfully!');
    }

    const onFileFailedUpload = () => {
        setUploading(false);

        sendCanUploadMessage(true);

        toast.error('Error uploading file');
    }

    const { mutate: createURL } = useUploadFile(meetingId, userId, patientId, onFileUploaded, onFileFailedUpload);

    const onFile = (file: File | null) => {

        if (!file) {
            return;
        }

        if (wasVideoEnabled) {
            waitToRestartVideo();
        }

        toast('Starting Upload');

        createURL(file)

        setUploading(false);

        sendCanUploadMessage(true);
    }

    const onCancel = () => {
        console.log("~~~ Image uploader cancelled ~~~");
        waitToRestartVideo();
        sendCanUploadMessage(true);

        toast.error("Upload cancelled");
    }

    const onUploaderClick = () => {

        if (!imageUploader.current) {
            return;
        }

        sendCanUploadMessage(false);

        //set selected input item null
        imageUploader.current.value = "";
        imageUploader.current.click();

        setWasVideoEnabled(isVideoEnabled);

        if (isVideoEnabled) {
            toggleVideo();
        }
    }

    const waitToRestartVideo = () => {

        if (!wasVideoEnabled || isVideoEnabled) {
            return;
        }

        console.log("~~~ waitToRestartVideo " + wasVideoEnabled);

        setWasVideoEnabled(false);

        setTimeout(() => {
            toggleVideo();
        }, 1000);
    }

    useEffect(() => {

        if (!allowRemoteControl) {
            return;
        }

        if (subscribed) {
            return;
        }

        if (status != 1) {
            return;
        }

        subscribeToChannel("upload-image", "labelled-upload-image-button", (message) => {

            if (message.payload == "join") {
                sendMessage("upload-image", canUpload ? "true" : "false", userId);
                return;
            }

            if (message.payload != "true" && message.payload != "false") {
                return;
            }

            setIsWaiting(true)
        })

        sendMessage("upload-image", isVideoEnabled ? "false" : "true", userId);

        setSubscribed(true);

    }, [status]);

    useEffect(() => {

        const waitThreeSeconds = () => {
            setTimeout(() => {
                console.log("~~~ &&&&& waitThreeSeconds ~~~");
                onUploaderClick()
                setIsWaiting(false);
            }, 5000);
        }

        if (!isWaiting) {
            return;
        }

        if (isTesting) {
            waitThreeSeconds()
        }
        else {
            console.log("~~~ &&&&& onUploaderClick ~~~");
            onUploaderClick()
            setIsWaiting(false);
        }

    }, [isWaiting]);

    const imageUploader = React.useRef<HTMLInputElement | null>(null);

    const handleImageUpload = (e: any) => {

        const [file] = e.target.files;
        if (!file) {
            console.log("No file uploaded");
            return;
        }

        if (!checkIfValidImage(file)) {
            toast.error("File is not an image");
            return;
        }

        onFile(file);
    };

    const checkIfValidImage = (file: File) => {
        if (!file.type.match("image.*")) {
            console.log("File is not an image");

            const extension = file.name.split('.').pop();

            if (extension !== "ply") {
                return false;
            }
            else {
                console.log("File is a ply file");
            }
        }

        return true;
    }

    useEffect(() => {
        if (!imageUploader.current) {
            return;
        }

        imageUploader.current.addEventListener("cancel", onCancel);

        // add event listener for file input change
        imageUploader.current.addEventListener("change", onInputChanged);

        // remove event listener when component unmounts
        return () => {
            console.log("~~~ Image uploader unmounted ~~~");
            if (!imageUploader.current) {
                return;
            }

            imageUploader.current.removeEventListener("cancel", onCancel);
            imageUploader.current.removeEventListener("change", onInputChanged);
        };

    }, [imageUploader]);

    const onInputChanged = (e: any) => {
        console.log("~~~ Image uploader changed ~~~", e);
    }

    return (
        <ControlButton
            showText={showText}
            activeLabel="Upload Photo"
            inactiveLabel={uploading ? "Upload in progress" : "Cannot upload photo"}
            onClick={onUploaderClick}
            active={canUpload}
            disabled={!canUpload}
            activeIcon={<UploadFileIcon color="action" />}
            inactiveIcon={<UploadFileIcon color="action" />}>
            <input
                title="Upload Photo"
                type="file"
                accept={"image/*"}
                capture={true}
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                    display: "none"
                }} />
            {children}
        </ControlButton>
    );
}

export default RemoteUploadImageButton;