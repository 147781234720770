import React from "react";
import { Drawing } from "./send-annotation-message";

interface DrawingLineProps {
    keyProp: string
    drawing: Drawing
    userId: string
    width: number
    height: number
    showMouse: boolean
    scale: number;
    translateX: number;
    translateY: number;
    isDragging: boolean;
    lineIndex: number
}

const DrawingLine: React.FC<DrawingLineProps> = ({
    drawing,
    userId,
    width,
    height,
    keyProp,
    showMouse,
    translateX,
    translateY,
    scale,
    isDragging,
    lineIndex,
}) => {

    const getPoints = (drawing: Drawing) => {

        if (!drawing) {
            return [];
        }

        if (!drawing.points) {
            return [];
        }

        if (lineIndex < 0 || drawing.points.length >= lineIndex) {
            return drawing.points[lineIndex];
        }
    }

    const pointsToUse = getPoints(drawing);



    // The drawing points are stored as a percentage of the image size
    // We need to convert them to their actual size
    const convertPoint = (x: number, y: number) => {

        // Translate needs to be added before scaling
        const adjustedTranslateX = translateX / width;
        const adjustedTranslateY = translateY / height;

        // Scaling is from the centre of the image so we work in the range -0.5 to 0.5
        // Now we are drawing the points we have to adjust for this
        return {
            x: ((x + adjustedTranslateX + 0.5) * width),
            y: ((y + adjustedTranslateY + 0.5) * height),
        }
    }

    const line = []

    if (pointsToUse) {
        for (let i = 0; i < pointsToUse.length; i++) {
            const p = pointsToUse[i];
            if (p) {
                line.push(convertPoint(p.x, p.y));
            }
        }
    }

    // Convert points to SVG format
    const points = line.map((p) => `${p.x},${p.y}`).join(' ');

    const scaledMouse = convertPoint(drawing?.userMouse?.x || 0, drawing?.userMouse?.y || 0);

    return (
        <div
            key={`drawingDiv${keyProp}`}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}
        >
            <svg
                width="100%"
                height="100%"
                key={`svg${userId}`}
                style={{
                    zIndex: 100,
                    transform: `scale(${scale})`,
                    transition: isDragging ? 'none' : 'transform 0.1s ease-out',
                }}>
                {drawing?.userMouse && showMouse &&
                    <circle key={`${userId}$mouse`} cx={scaledMouse.x} cy={scaledMouse.y} r="10" fill={drawing?.color} />}
                <polyline points={points} stroke={drawing?.color} fill="none" strokeWidth="2" />
                {line.map((p, index) => (
                    <circle key={`${userId}${index}`} cx={p.x} cy={p.y} r="1" fill={drawing?.color} />
                ))}
            </svg>
        </div>
    );
};

export default DrawingLine;