import React, { PropsWithChildren } from "react";

import { Stack, Tooltip, Typography } from "@mui/material"

import { Organisation, Site } from "../../types"


interface OrganisationTagProps {
    organisation?: Organisation
    sites: (Site | null)[]
    attendees: number
    buttons?: JSX.Element
    canJoin: boolean
}

export const getOrganisationTagTooltip = (name: string, attendees: number, secondaryPart: string | undefined) => {

    if (secondaryPart) {
        name += secondaryPart
    }

    if (attendees === 0) {
        return `${name} (No attendees)`
    }

    if (attendees === 1) {
        return `${name} (1 attendee)`
    }

    return `${name} (${attendees} attendees)`
}

const OrganisationTag: React.FC<PropsWithChildren<OrganisationTagProps>> = ({
    organisation,
    sites,
    attendees,
    buttons,
    children,
}) => {

    const orgName = organisation?.name || "No organisation"

    let siteNames = ""
    let siteColor = undefined
    for (const site of sites) {
        if (!site) {
            continue
        }

        if (site.linkedOrganisations.includes(organisation?.organisationId || "")) {

            if (siteNames.length > 0) {
                siteNames += ", "
            }

            siteNames += site.siteName
            siteColor = site.color
        }
    }

    const primaryName = siteNames.length > 0 ? siteNames : orgName
    const secondaryName = siteNames.length > 0 ? ` (${orgName})` : undefined

    const tooltip = getOrganisationTagTooltip(primaryName, attendees, undefined)

    return (
        <Stack
            id={organisation?.organisationId + "organisation tag"}
            borderRadius={"0.5rem"}
            alignItems={"left"}
            justifyContent={"top"}
            spacing={1}
            minWidth={'100px'}
            width={"100%"}
            sx={{
                backgroundColor: "#e6e6e6",
            }}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                spacing={1}
                sx={{
                    borderTopRightRadius: children ? "0.5rem" : undefined,
                    borderTopLeftRadius: children ? "0.5rem" : undefined,
                    borderRadius: children ? undefined : "0.5rem",
                    backgroundColor: siteColor || "lightgray",
                }}>
                <Tooltip title={tooltip}>
                    <Stack
                        height={"100%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        padding={1}>
                        <Typography variant="body1">
                            <b>{primaryName}</b>
                            {secondaryName}
                            <b>{` (${attendees})`}</b>
                        </Typography>
                    </Stack>
                </Tooltip>
                {buttons}
            </Stack>
            {children &&
                <Stack paddingX={"0.6rem"} paddingBottom={"0.4rem"}>
                    {children}
                </Stack>}
        </Stack>
    )
}

export default OrganisationTag