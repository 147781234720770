import React from "react"

import { SelectChangeEvent } from "@mui/material"
import DictionarySelect from "../dictionary-select"

interface ColorSelectProps {
    name: string
    colorDictionary: { [key: string]: string }
    selectedColor: string
    onSelectColor: (event: SelectChangeEvent<string>) => void
    showLabel?: boolean
    showNames?: boolean
}

const ColorSelect: React.FC<ColorSelectProps> = ({
    name,
    colorDictionary,
    selectedColor,
    onSelectColor,
    showLabel,
    showNames,
}) => {

    const createSelectIcon = (key: string, value: string) => {
        return (
            <div key={key} style={{
                backgroundColor: key,
                width: "20px",
                height: "20px",
                borderRadius: "0.1rem"
            }}></div>
        )
    }

    return (
        <DictionarySelect
            name={name}
            label="Color"
            dictionary={colorDictionary}
            selected={selectedColor}
            onSelect={onSelectColor}
            createSelectIcon={createSelectIcon}
            showLabel={showLabel}
            showNames={showNames}
        />
    )
}

export default ColorSelect;