import { useContext, useState } from "react";
import { useAddTrainingMeeting } from "../../store";
import { canJoin, Meeting, MeetingJoinType } from "../../types";
import TelehealthStyledButton from "../telehealth-styled-button";
import { AuthContext } from "../../context/auth-context";
import toast from "react-hot-toast";
import { createURL } from "../upload/create-meeting-url";

interface TrainingMeetingButtonProps {
    disabled?: boolean;
}

const TrainingMeetingButton: React.FC<TrainingMeetingButtonProps> = ({
    disabled
}) => {

    const { userId } = useContext(AuthContext)

    const meetingString = localStorage.getItem("trainingMeetingId");

    let meeting: Meeting | null = null;

    if (meetingString) {
        try {
            const parsedItem = JSON.parse(meetingString);
            meeting = {
                meetingId: parsedItem.meetingId,
                creatorId: parsedItem.creatorId,
                meetingType: parsedItem.meetingType,
                startTime: new Date(parsedItem.startTime),
                endTime: new Date(parsedItem.endTime),
                meetingName: parsedItem.meetingName,
                chimeJoinId: parsedItem.chimeJoinId,
                attendeesId: parsedItem.attendeesId,
                organisationIds: parsedItem.organisationIds,
                confirmedAttendeesId: parsedItem.confirmedAttendeesId,
                joinedIds: parsedItem.joinedIds,
                patientId: parsedItem.patientId,
                siteIds: parsedItem.siteIds,
            }
        }
        catch (e: any) {
            console.warn("Error parsing training meeting id", e);
            meeting = null;
        }
    }

    const [trainingMeetingId, setTrainingMeetingId] = useState<Meeting | null>(meeting);
    const [creationInProgress, setCreationInProgress] = useState(false);

    const meetingAddSuccess = (meeting: Meeting) => {
        setTrainingMeetingId(meeting);
        setCreationInProgress(false);

        localStorage.setItem("trainingMeetingId", JSON.stringify(meeting));

        joinAndOpenMeeting(meeting?.meetingId);
    }

    const joinAndOpenMeeting = (meetingId?: string) => {
        if (!meetingId) {
            console.warn("No meeting id to join");
            return;
        }

        const url = createURL(MeetingJoinType.Training, meetingId, userId);
        window.open(url, "_blank");
    }


    const meetingAddError = (error: any) => {
        toast.error("Could not create training meeting");

        setCreationInProgress(false);
    }

    const { mutate: addTrainingMeeting } = useAddTrainingMeeting(meetingAddSuccess, meetingAddError);

    const createTrainingMeeting = () => {

        if (creationInProgress) {
            return;
        }

        if (meeting && trainingMeetingId && canJoin(trainingMeetingId)) {
            joinAndOpenMeeting(meeting?.meetingId);
        }
        else {
            addTrainingMeeting(userId);
            setCreationInProgress(true);
        }
    };

    const getLabel = () => {
        if (creationInProgress) {
            return "Creating Training Meeting...";
        }

        if (trainingMeetingId) {
            return "Join Training Meeting";
        }

        return "Start Training Meeting";
    }

    const label = getLabel();

    return (
        <TelehealthStyledButton
            label={label}
            onClick={createTrainingMeeting}
            disabled={creationInProgress || disabled}
            showText
            variant={"contained"}
            sx={{
                minWidth: "200px",
                minHeight: "40px",
                height: "100%",
            }}
        />
    );
}

export default TrainingMeetingButton;